import React, { Fragment, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Divider, Input, Collapse, Typography, Checkbox, Col, Row } from 'antd';
import { STUDENT_DESC_CAMPAIGN_AD_GROUPS, STUDENT_COPY } from '../FormConstants';

const { Panel } = Collapse;
const { Text } = Typography;

function DescriptiveStudentAdGroups(props) {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const parsedAddress = props.parsedAddress;

    const [disabledSchoolState, setDisabledSchoolState] = useState([]);
    const [disabledSchoolNatl, setDisabledSchoolNatl] = useState([]);

    const generateDefaultCopy = field => {
        const name = field.replace('state_', '').replace('natl_', '');

        let copy = STUDENT_COPY[name] || 'Student Apartments for Rent';

        let city = parsedAddress.placeName;
        let schoolAb = getFieldValue('school_ab');
        let school = getFieldValue('school');

        if (school && schoolAb) {
            copy = copy.replace('{{school_upper}}', school).replace('{{school_ab}}', schoolAb);
        }

        if (parsedAddress && parsedAddress.placeName) {
            copy = copy.replace('{{city_upper}}', city);
        }

        if (copy.length > 30) {
            copy = copy.replace('Apartments', 'Apts');
        }

        if (copy.length > 30) {
            copy = copy.replace('University', 'Univ');
        }

        return copy;
    };

    const generateLabel = value => {
        const labels = [];

        const area = value.includes('NonBrand State') ? 'State' : 'Natl';

        labels.push(area + '-Apts School' + '-HL12');
        labels.push(area + '-Apts School' + '-HL13');
        labels.push(area + '-Apts School' + '-HL14');

        labels.push(area + '-Off Campus City' + '-HL12');
        labels.push(area + '-Off Campus City' + '-HL13');
        labels.push(area + '-Off Campus City' + '-HL14');

        labels.push(area + '-Off Campus School' + '-HL12');
        labels.push(area + '-Off Campus School' + '-HL13');
        labels.push(area + '-Off Campus School' + '-HL14');

        labels.push(area + '-Student Apts City' + '-HL12');
        labels.push(area + '-Student Apts City' + '-HL13');
        labels.push(area + '-Student Apts City' + '-HL14');

        labels.push(area + '-Student Apts School' + '-HL12');
        labels.push(area + '-Student Apts School' + '-HL13');
        labels.push(area + '-Student Apts School' + '-HL14');

        labels.push(area + '-Student Housing City' + '-HL12');
        labels.push(area + '-Student Housing City' + '-HL13');
        labels.push(area + '-Student Housing City' + '-HL14');

        labels.push(area + '-Student Housing School' + '-HL12');
        labels.push(area + '-Student Housing School' + '-HL13');
        labels.push(area + '-Student Housing School' + '-HL14');

        return labels;
    };

    const handleSchoolGroupChanged = checkbox => {
        // NonBrand State
        if (checkbox.value === 'NonBrand State' && checkbox.checked) {
            setDisabledSchoolState(['NonBrand State Sch City', 'NonBrand State Sch State']);
        }
        if (checkbox.value === 'NonBrand State' && !checkbox.checked) {
            setDisabledSchoolState([]);
        }
        if (checkbox.value === 'NonBrand State Sch City' && checkbox.checked) {
            setDisabledSchoolState(['NonBrand State', 'NonBrand State Sch State']);
        }
        if (checkbox.value === 'NonBrand State Sch City' && !checkbox.checked) {
            setDisabledSchoolState([]);
        }
        if (checkbox.value === 'NonBrand State Sch State' && checkbox.checked) {
            setDisabledSchoolState(['NonBrand State', 'NonBrand State Sch City']);
        }
        if (checkbox.value === 'NonBrand State Sch State' && !checkbox.checked) {
            setDisabledSchoolState([]);
        }

        // NonBrand National
        if (checkbox.value === 'NonBrand Natl' && checkbox.checked) {
            setDisabledSchoolNatl(['NonBrand Natl Sch City', 'NonBrand Natl Sch State']);
        }
        if (checkbox.value === 'NonBrand Natl' && !checkbox.checked) {
            setDisabledSchoolNatl([]);
        }
        if (checkbox.value === 'NonBrand Natl Sch City' && checkbox.checked) {
            setDisabledSchoolNatl(['NonBrand Natl', 'NonBrand Natl Sch State']);
        }
        if (checkbox.value === 'NonBrand Natl Sch City' && !checkbox.checked) {
            setDisabledSchoolNatl([]);
        }
        if (checkbox.value === 'NonBrand Natl Sch State' && checkbox.checked) {
            setDisabledSchoolNatl(['NonBrand Natl', 'NonBrand Natl Sch City']);
        }
        if (checkbox.value === 'NonBrand Natl Sch State' && !checkbox.checked) {
            setDisabledSchoolNatl([]);
        }
    };

    const isCheckBoxDisabled = checkbox => {
        return checkbox.includes('NonBrand State')
            ? disabledSchoolState.includes(checkbox)
            : disabledSchoolNatl.includes(checkbox);
    };

    const checkBoxGroup = () => {
        return (
            <Fragment>
                {STUDENT_DESC_CAMPAIGN_AD_GROUPS.map(item => (
                    <Col key={item} span={8}>
                        <Checkbox
                            disabled={isCheckBoxDisabled(item)}
                            value={item}
                            onChange={e => {
                                handleSchoolGroupChanged(e.target);
                            }}
                        >
                            {item}
                        </Checkbox>
                    </Col>
                ))}
            </Fragment>
        );
    };

    const inputGroup = () => {
        return STUDENT_DESC_CAMPAIGN_AD_GROUPS.filter(item => {
            return getFieldValue('descriptive_ad_groups').includes(item);
        })
            .reduce((acc, cur) => {
                const labels = generateLabel(cur);
                acc.push(...labels);
                return acc;
            }, [])
            .map(item => {
                return {
                    label: item.split('|')[0],
                    name: item.toLowerCase().replace(/[\W_]/g, '_')
                };
            })
            .map(item => {
                const fieldName = item.name.replace('hl', 'headline_');
                return (
                    <Form.Item label={item.label} key={fieldName} extra={item.extra}>
                        {getFieldDecorator(`${fieldName}`, {
                            initialValue: generateDefaultCopy(fieldName),
                            rules: [
                                {
                                    max: 30
                                }
                            ]
                        })(
                            <Input
                                placeholder={item.placeholder}
                                suffix={`${getFieldValue(fieldName) ? getFieldValue(fieldName).length : '0'}/30`}
                            />
                        )}
                    </Form.Item>
                );
            });
    };

    return (
        <Fragment>
            <Form.Item>
                <Text>Select a Campaign/Ad Group...</Text>
                {getFieldDecorator('descriptive_ad_groups')(
                    <Checkbox.Group>
                        <Row>{checkBoxGroup()}</Row>
                    </Checkbox.Group>
                )}
            </Form.Item>

            {getFieldValue('descriptive_ad_groups') && getFieldValue('descriptive_ad_groups').length > 0 && (
                <React.Fragment>{inputGroup()}</React.Fragment>
            )}
        </Fragment>
    );
}

export default DescriptiveStudentAdGroups;
