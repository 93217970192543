import React, { Component } from 'react';
import axios from 'axios';

import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';

import { Select, Spin } from 'antd';

const { Option } = Select;

const ANX_INVENTORIES_ENDPOINT = 'anx/inventorylist';

export default class BacklistSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blacklists: [],
            blacklistsLoading: false
        };
    }

    componentDidMount() {
        this.getBlacklists();
    }

    getBlacklists() {
        this.setState({ blacklistsLoading: true });
        axios
            .get(`${API_BASE_URL}${ANX_INVENTORIES_ENDPOINT}`)
            .then(res => {
                if (!res.data.length) {
                    throw 'No Blacklists found';
                }
                this.setState(
                    {
                        blacklistsLoading: false,
                        blacklists: res.data
                    },
                    this.props.onBlacklistChange('0')
                );
            })
            .catch(error => {
                this.setState({
                    blacklistsLoading: false
                });
                console.log(error);
            });
    }

    render() {
        const { blacklists, blacklistsLoading } = this.state;
        const { blacklist } = this.props;
        return (
            <Spin size="large" spinning={blacklistsLoading}>
                {blacklist
                    ? <Select className="full-width" defaultValue={blacklist} onChange={this.props.onBlacklistChange}>
                          <Option value={'0'}>None</Option>
                          {blacklists.map(bl => {
                              return (
                                  <Option value={bl.inventory_url_list_id}>
                                      {bl.name}
                                  </Option>
                              );
                          })}
                      </Select>
                    : ''}
            </Spin>
        );
    }
}
