import React from 'react';
import { Button, ControlLabel, FormControl } from 'react-bootstrap';
import TableConstants from '../constants/table.constants';

class DataTablePagingServiceInternal {
    static getLink(pagingDirection, onPageClick) {
        const arrowDirection = pagingDirection === TableConstants.PAGING_DIRECTION.Previous ? 'left' : 'right';
        return (
            <td className={`${pagingDirection}-link`}>
                <Button
                    variant="link"
                    onClick={e => {
                        onPageClick(e, pagingDirection);
                    }}
                >
                    <i className={`pe-7s-angle-${arrowDirection}-circle`} />
                </Button>
            </td>
        );
    }
}

class DataTablePagingService {
    static getPreviousLink(pageIndex, onPageClick) {
        if (pageIndex === TableConstants.INITIAL_PAGE_INDEX) {
            return;
        }

        return DataTablePagingServiceInternal.getLink(TableConstants.PAGING_DIRECTION.Previous, onPageClick);
    }

    static getNextLink(numRowsOnThisPage, pageSize, onPageClick) {
        if (numRowsOnThisPage < pageSize) {
            return;
        }

        return DataTablePagingServiceInternal.getLink(TableConstants.PAGING_DIRECTION.Next, onPageClick);
    }

    static getPageSizeSelector(onPageSizeSelect) {
        return (
            <td className="page-size-selector">
                <ControlLabel>Rows per page</ControlLabel>
                <FormControl componentClass="select" className="holder-select-page" onChange={onPageSizeSelect}>
                    {[25, 50, 75, 100].map(num => {
                        return (
                            <option key={'pageSize' + num} value={num}>
                                {num}
                            </option>
                        );
                    })}
                </FormControl>
            </td>
        );
    }
}

export default DataTablePagingService;
