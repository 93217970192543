import { notification } from 'antd';

export const onError = (error, duration = 5) => {
    notification.error({
        message: 'Error',
        description: String(error),
        placement: 'topRight',
        duration
    });
};

export const onSuccess = text => {
    notification.success({
        message: 'Success',
        description: String(text),
        placement: 'topRight'
    });
};

export const responceProcess = res => {
    if (res.data.status === 'OK') {
        return res.data.data;
    }
    throw Error(res.data.error);
};
