import { UserService } from '../services/user.service';
import axios from 'axios';

export function authHeader() {
    // return authorization header with jwt token
    let user = UserService.getInfoFromStorage();

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token };
    }

    return {};
}

export function axiosGetWithAuth(urlString) {
    return axios.get(urlString, {
        headers: authHeader()
    });
}

export function axiosPostWithAuth(urlString, payload) {
    return axios.post(urlString, payload, {
        headers: authHeader()
    });
}
