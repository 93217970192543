import React, { Component } from 'react';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { UserService } from '../../services/user.service';
import imagine from '../../assets/img/sidebar-3.jpg';
import logo from '../../assets/img/CL-logo.png';
import { Modal, Spin, notification } from 'antd';
import { Helmet } from 'react-helmet';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            submitted: false,
            showPassResetModal: false,
            passResetIp: false
        };
    }

    set passResetIp(ip) {
        this.setState({
            passResetIp: ip
        });
    }

    set showPassResetModal(val) {
        this.setState({
            showPassResetModal: val
        });
    }

    async sendPassResetEmail() {
        const email = this.state.username;
        if (/.+@.+/.test(email) === false) {
            // Kickback
            notification.error({
                message: 'Error',
                description: 'Please enter a valid email'
            });
            return false;
        }
        // Process
        const success = await UserService.sendPassResetEmail(email);
        if (success) {
            notification.success({
                message: 'Sent!',
                description: 'Check your email for the reset link!'
            });
            return true;
        }
        notification.warn({
            message: 'Something went wrong...',
            description: 'Was an email already sent for this emai? Check spam.'
        });
        return false;
    }

    getErrorClassName(field) {
        return this.state.submitted && !this.state[field] ? ' has-error' : '';
    }

    getHelpBlockWithConditionAndMessage(condition, message) {
        if (!condition) {
            return;
        }

        return <div className="help-block">{message}</div>;
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = e => {
        e.preventDefault();

        this.setState({ submitted: true, error: '' });
        const { username, password } = this.state;

        if (username && password) {
            UserService.login(username, password)
                .then(user => {
                    this.setState({
                        user
                    });
                })
                .catch(error => {
                    console.log('Auth error!', error);
                    this.setState({
                        error: 'Invalid username or password!'
                    });
                });
        }
    };

    render() {
        if (this.state.user) {
            return <Redirect to={{ pathname: '/dashboard' }} />;
        }
        const sidebarBackground = {
            backgroundImage: 'url(' + imagine + ')'
        };

        return (
            <div className={'login-form'}>
                <Helmet>
                    <title>Log In - Conversion Logix</title>
                </Helmet>
                <Form horizontal onSubmit={this.handleSubmit} className="container">
                    <div className={'holder-form-background'}>
                        <div className="sidebar-background" style={sidebarBackground} />
                    </div>
                    <img src={logo} className={'login-logo'} alt="Conversion Logix Logo" />
                    <FormGroup controlId="formHorizontalEmail" className={this.getErrorClassName('username')}>
                        <Col componentClass={ControlLabel} sm={2}>
                            Email
                        </Col>
                        <Col sm={10}>
                            <FormControl
                                type="email"
                                placeholder="Email"
                                name="username"
                                onChange={this.handleChange}
                            />
                            {this.getHelpBlockWithConditionAndMessage(
                                this.state.submitted && !this.state.username,
                                'Email is required'
                            )}
                        </Col>
                    </FormGroup>

                    <FormGroup controlId="formHorizontalPassword" className={this.getErrorClassName('password')}>
                        <Col componentClass={ControlLabel} sm={2}>
                            Password
                        </Col>
                        <Col sm={10}>
                            <FormControl
                                type="password"
                                placeholder="Password"
                                name="password"
                                onChange={this.handleChange}
                            />
                            {this.getHelpBlockWithConditionAndMessage(
                                this.state.submitted && !this.state.password,
                                'Password is required'
                            )}
                        </Col>
                    </FormGroup>

                    <FormGroup className={this.state.error ? ' has-error' : ''}>
                        <Col smOffset={2} sm={10}>
                            {this.getHelpBlockWithConditionAndMessage(!!this.state.error, this.state.error)}
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col smOffset={2} sm={10}>
                            <Button className={'sub-btn'} type="submit">
                                Sign in
                            </Button>
                        </Col>
                    </FormGroup>

                    <span
                        style={{
                            fontStyle: 'italic',
                            textDecoration: 'underline',
                            color: 'white',
                            cursor: 'pointer',
                            marginBottom: '10px',
                            display: 'inline-block'
                        }}
                        onClick={() => {
                            this.setState({
                                showPassResetModal: true
                            });
                        }}
                    >
                        Forgot password?
                    </span>
                </Form>
                <Modal
                    open={this.state.showPassResetModal}
                    onCancel={() => {
                        this.showPassResetModal = false;
                    }}
                    okButtonProps={{
                        style: {
                            display: 'none'
                        }
                    }}
                >
                    <Spin tip="Sending email..." spinning={this.state.passResetIp}>
                        <Button
                            onClick={async () => {
                                this.passResetIp = true;
                                await this.sendPassResetEmail();
                                this.passResetIp = false;
                                this.showPassResetModal = false;
                            }}
                            style={{
                                backgroundColor: '#B60612',
                                color: 'white'
                            }}
                        >
                            Send Reset Email
                        </Button>
                    </Spin>
                </Modal>
            </div>
        );
    }
}

export default Login;
