import React from 'react';
import { Select } from 'antd';

export class UserRoleSelector extends React.Component {
    generateOptions(roleSchema, maxRoleId) {
        return Object.keys(roleSchema).map((roleId) => {
            const role = roleSchema[roleId];
            return (
                <Select.Option
                    value={role.id}
                    disabled={role.id > maxRoleId}
                    key={`role-${role.id}`}
                >
                    {role.displayName}
                </Select.Option>
            );
        });
    }

    render() {
        const { roleSchema, currRoleId, maxRoleId, changeCb, disabled } = this.props;
        return (
            <Select
                defaultValue={currRoleId}
                onChange={changeCb}
                disabled={disabled}
                value={currRoleId}
            >
                {this.generateOptions(roleSchema, maxRoleId)}
            </Select>
        )
    }
}
