import React, { useState } from 'react';
import { Input, Button, message } from 'antd';
import { Grid, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import BuildAccount from './BuildAccount';
import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';

const XndrGtagGeneration = () => {
    const [accountId, setAccountId] = useState('');
    const [pixelDetails, setPixelDetails] = useState(null);
    const history = useHistory();

    const handleInputChange = (event) => {
        setAccountId(event.target.value);
    }

    const handleFormResponse = () => {
        const requestObject ={
            method: 'GET',
            headers: authHeader(),
        }
        // Call to backend to get Xandr pixel details
        fetch(`${API_BASE_URL}anx/getpixelsandsegments/${accountId}`, requestObject)
            .then(async response => response.json())
            .then(data =>{
                if (data.status!==200 && data.message) {
                    throw new Error(data.message); 
                }
                const fakeCreationItem={created_elements:{}, existing_flag:true,errors:[]};
                if (data.segments) {
                    const segmentRT = data.segments.filter((segment) => {
                        return segment.short_name.indexOf('RT')>= -1 
                    });
                    if (segmentRT.length > 0) {
                        fakeCreationItem.created_elements['segment'] = {segment:segmentRT[0]};
                    }else{
                        fakeCreationItem.created_elements['segment'] = {segment:{}};
                    
                    }
                }
                fakeCreationItem.created_elements['pixels'] = data.pixels;
                if (data.advertiser) {
                    fakeCreationItem.created_elements['advertiser'] = {advertiser:data.advertiser};
                }
                if (data.pixels) {
                    fakeCreationItem.created_elements['pixels'] = data.pixels.map(pixel=>{ return {pixel}});
                }
                return history.push('/build-account',fakeCreationItem)
            }).catch(error => {
                message.error(`${error}`);
                console.error('Error:', error)
            });
    }

    return (                
        <Grid fluid>
            <Row>
                <Col offset={3} md={4}>
                    <Input placeholder="Enter Xandr Account Id" onChange={handleInputChange} />
                </Col><Col md={1}>
                    <Button disabled={!setAccountId} type="primary" onClick={handleFormResponse}>Select</Button>
                    <Router>
                        <Route path="/build-account" component={BuildAccount} />
                    </Router>
                </Col>
            </Row>
        </Grid>
    );
}

export default XndrGtagGeneration;
