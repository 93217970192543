import * as moment from 'moment-timezone';

export const timeZoneList = {
    'Africa/Algiers': 'Algeria - Algiers',
    'Africa/Johannesburg': 'South Africa - Johannesburg',

    'America/Adak': 'USA - Alaska - Adak',
    'America/Anchorage': 'USA - Alaska - Anchorage',
    'America/Argentina/Buenos_Aires': 'Argentina - Buenos Aires',
    'America/Argentina/Rio_Gallegos': 'Brazil - Rio de Janeiro',
    'America/Asuncion': 'Paraguay - Asuncion',
    'America/Belize': 'Belize - Belize City',
    'America/Boise': 'USA - Idaho - Boise',
    'America/Bogota': 'Colombia - Bogota',
    'America/Chicago': 'USA - Illinois - Chicago',
    'America/Detroit': 'USA - Michigan - Detroit',
    'America/Edmonton': 'Canada - Alberta - Edmonton',
    'America/Indiana/Indianapolis': 'USA - Indiana - Indianapolis',
    'America/Halifax': 'Canada - Nova Scotia - Halifax',
    'America/Havana': 'Cuba - Havana',
    'America/La_Paz': 'Bolivia - La Paz',
    'America/Lima': 'Peru - Lima',
    'America/Los_Angeles': 'USA - California - Hollywood',
    'America/Nassau': 'Bahamas - Nassau',
    'America/Phoenix': 'USA - Arizona - Phoenix',
    'America/Santiago': 'Chile - Santiago',
    'America/Sao_Paulo': 'Brazil - San Paulo',
    'America/St_Johns': 'Canada - New Brunswick - Saint John',
    'America/Toronto': 'Canada - Ontario - Toronto',
    'America/Vancouver': 'Canada - British Columbia - Vancouver',
    'America/Winnipeg': 'Canada - Manitoba - Winnipeg',

    'Asia/Anadyr': 'Russia - Anadyr',
    'Asia/Dhaka': 'Bangladesh - Dhaka',
    'Asia/Kabul': 'Afghanistan - Kabul',
    'Asia/Karachi': 'Pakistan - Karachi',
    'Asia/Manila': 'Philippines - Manila',
    'Asia/Seoul': 'South Korea - Seoul',
    'Asia/Shanghai': 'China - Shanghai Municipality - Shanghai',
    'Asia/Singapore': 'Singapore - Singapore',
    'Asia/Tomsk': 'Russia - Tomsk',

    'Atlantic/Cape_Verde': 'Atlantic - Cape Verde',

    'Australia/Adelaide': 'Australia - South Australia - Adelaide',
    'Australia/Brisbane': 'Australia - Queensland - Brisbane',
    'Australia/Darwin': 'Australia - Northern Territory - Darwin',
    'Australia/Melbourne': 'Australia - Victoria - Melbourne',
    'Australia/Perth': 'Australia - Western Australia - Perth',
    'Australia/Sydney': 'Australia - New South Wales - Sydney',

    'Brazil/DeNoronha': 'Brazil - DeNoronha',

    'Europe/Amsterdam': 'Netherlands - Amsterdam',
    'Europe/Brussels': 'Belgium - Brussels',
    'Europe/Bucharest': 'Romania - Bucharest',
    'Europe/Kiev': 'Ukraine - Kiev',
    'Europe/Lisbon': 'Portugal - Lisbon',
    'Europe/London': 'United Kingdom - London',
    'Europe/Madrid': 'Spain - Madrid',
    'Europe/Moscow': 'Russia - Moscow',
    'Europe/Oslo': 'Norway - Oslo',
    'Europe/Samara': 'Russia - Samara',
    'Europe/Sofia': 'Bulgaria - Sofia',
    'Europe/Stockholm': 'Sweden - Stockholm',
    'Europe/Warsaw': 'Poland - Warsaw',
    'Europe/Zagreb': 'Croatia - Zagreb',
    'Europe/Zurich': 'Switzerland - Zurich',

    'Pacific/Auckland': 'New Zealand - Auckland',
    'Pacific/Honolulu': 'USA - Hawaii - Honolulu',
    'Pacific/Niue': 'New Zealand - Niue',
    'Pacific/Norfolk': 'Australia - Norfolk',

    UTC: 'UTC'
};

export const timeZoneListOptions = () => {
    return Object.keys(timeZoneList).map(value => {
        const label = `${value} ${moment.tz(value).format('Z')}`;

        return { label, value };
    });
};
