import React, { Component } from 'react';

import { responceProcess, onError } from '../../services/error.service';
import FormTemplateSelector from './formTemplateSelector';
import { Row } from 'antd';

const HREF_PREFIX = '/forms/formenter/';
const initFormTemplate = () => {
    return { fields: [] };
};

export default class FormSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            template: initFormTemplate(),
            templateLoading: false
        };
        this.templateid = this.props.match.params.templateid;
        this.responceProcess = this.responceProcess.bind(this);
        //this.props.history.push(path);
    }

    responceProcess(res) {
        return responceProcess(res);
    }

    componentDidMount() {}

    render() {
        return (
            <React.Fragment>
                <div className="formbuilder-wrapper">
                    <Row align="middle">
                        <FormTemplateSelector
                            disabled={this.state.newmode}
                            span={14}
                            offset={4}
                            onTemplateChange={this.onTemplateChange}
                            onError={onError}
                            responceProcess={this.responceProcess}
                            hrefPrefix={HREF_PREFIX}
                            listType="list"
                        />
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}
