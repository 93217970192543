import React, { Component } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';

import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';

import { Select, Spin } from 'antd';
import 'antd/dist/antd.css';

const { Option } = Select;

export default class CitySelector extends Component {
    constructor(props) {
        super(props);
        this.lastFetchId = 0;
        this.getCities = debounce(this.getCities, 500);
        this.state = {
            data: [],
            fetching: false
        };
    }

    getCities = query => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });
        if (query.length > 0) {
            return axios
                .get(`${API_BASE_URL}anx/readonlyservice/city?q=${query}`, {
                    headers: authHeader()
                })
                .then(response => {
                    if (fetchId !== this.lastFetchId) {
                        // for fetch callback order
                        return;
                    }
                    let data = response.data;
                    data = data.map(city => {
                        const region = city.country_code === 'US' ? city.region_code : city.region_name;
                        const country = city.country_code === 'US' ? 'US' : city.country_name;
                        return { text: `${city.name}, ${region}, ${country}`, value: city.id };
                    });

                    this.setState({
                        data,
                        fetching: false
                    });
                })
                .catch(error => {
                    console.error('Error getting data!', error);
                });
        } else {
            this.setState({
                fetching: false
            });
        }
    };

    handleChange = value => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        this.setState({
            data: [],
            fetching: false
        });
    };

    render() {
        const { fetching, data } = this.state;

        // console.log('CitySelector props:', this.props);

        return (
            <Select
                mode="multiple"
                labelInValue
                value={this.props.value}
                placeholder="Start typing to select cities..."
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.getCities}
                onChange={this.handleChange}
            >
                {data.map(d => (
                    <Option key={d.value}>{d.text}</Option>
                ))}
            </Select>
        );
    }
}
