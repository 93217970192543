import React, { Component, Fragment } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import { HomeOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, message, Modal, Popconfirm, Select, Spin, Descriptions, Collapse, Typography } from 'antd';
import ReactSelect from 'react-select';
import addresser from 'addresser';
import states from 'us-state-codes';
import zipcode_to_timezone from 'zipcode-to-timezone';

import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';
import LocationAndCampaign from './FormSections/LocationAndCampaign';
import NonBrandAdGroups from './FormSections/NonBrandAdGroups';
import DescriptiveResAdGroups from './FormSections/DescriptiveResAdGroups';
import DescriptiveStudentAdGroups from './FormSections/DescriptiveStudentAdGroups';
import Neighborhoods from './FormSections/Neighborhoods';
import SnippetsAndCallouts from './FormSections/SnippetsAndCallouts';
import SiteLinks from './FormSections/SiteLinks';
import Headlines from './FormSections/Headlines';
import GadsDescriptions from './FormSections/Descriptions';

const { Option } = Select;
const SENIOR_LIVING = 'srl';
const { Panel } = Collapse;
const { Text } = Typography;

class SearchCampaignBuilderForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            managerAccounts: [
                {
                    name: 'Ascendance Digital Media',
                    id: '302-517-0280'
                },
                {
                    name: 'Z_CL_ Accounts',
                    id: '573-968-9865'
                }
            ],
            student: false,
            parsedAddress: null
        };
    }

    getClassNames() {
        let classes = 'content';

        if (this.state.loadingData) {
            classes += ' clx-loading';
        }

        return classes;
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            //delete values.gs_or_ave5_rent_cafe_website;

            console.log('Received values of form: ', values);
            this.setState({ loading: true });

            let requestBody = values;

            axios({
                method: 'post',
                url: `${API_BASE_URL}google/search/account`,
                headers: authHeader(),
                data: requestBody
            })
                .then(response => {
                    message.success(`Successfully created account.`);

                    ReactGA.event({
                        category: 'AdOps',
                        action: 'Build Google Ads Account',
                        label: `Built account ${values.account_name}`,
                        value: 1
                    });
                })
                .catch(error => {
                    message.error(`Build account failed: ${error.message}`);
                    console.error('Error building account!', error);
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        });
    };

    handleDownloadCSV = e => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            //delete values.gs_or_ave5_rent_cafe_website;

            this.setState({ csvLoading: true });

            let {
                account_name: brand,

                ...requestBody
            } = values;

            requestBody.brand = brand;

            let parsedAddress;
            try {
                parsedAddress = addresser.parseAddress(requestBody.address);
                requestBody.state_ab = parsedAddress.stateAbbreviation;
                requestBody.state = states.getStateNameByStateCode(parsedAddress.stateAbbreviation);
                requestBody.city = parsedAddress.placeName;
            } catch (error) {
                console.error(error);
            }

            let queryString = Object.keys(requestBody)
                .map(key => key + '=' + encodeURIComponent(JSON.stringify(requestBody[key])))
                .join('&');

            //console.info(`${API_BASE_URL}google/search/download-csv?${queryString}`);
            window.open(`${API_BASE_URL}google/search/download-csv?${queryString}`);

            ReactGA.event({
                category: 'AdOps',
                action: 'Generate Google Search Ads CSV',
                label: `Generated Search CSV for account ${values.account_name}`,
                value: 1
            });

            this.setState({ csvLoading: false });
        });

        const errors = this.props.form.getFieldsError();

        for (let field in errors) {
            if (errors[field]) {
                message.error(`There was an issue with the ${field} field.`, 6);
            }
        }
    };

    getManagerAccounts() {
        return axios
            .get(`${API_BASE_URL}google/manageraccounts`, {
                headers: authHeader()
            })
            .then(response => {
                const accounts = response.data;

                this.setState({
                    managerAccounts: accounts
                });
            })
            .catch(error => {
                console.error('Error getting list of accounts!', error.message);
            });
    }

    componentDidMount() {
        this.getManagerAccounts().then(result => {
            this.setState({
                loadingData: false
            });
        });
    }

    setInitialFields = (data, parsedAddress) => {
        data.base_campaign_template = data.vertical_id === SENIOR_LIVING ? SENIOR_LIVING : 'res';
        let headline_1 = undefined;
        let headline_2 = undefined;
        let headline_3 = undefined;
        let headline_4 = undefined;
        const locationName = data.account_name.replace('Apartments', '').trim();

        if (data.base_campaign_template === 'res') {
            data.location_targeting = 'radius_15';
            headline_1 = `${locationName} Apartments`;
            headline_2 = `Apartments in ${parsedAddress.placeName}`;
            headline_3 = `Apts for Rent in ${parsedAddress.placeName}`;
            headline_4 = `${parsedAddress.placeName} Apartments for Rent`;
        }

        if (data.base_campaign_template === SENIOR_LIVING) {
            headline_1 = locationName;
            headline_2 = `Senior Living in ${parsedAddress.placeName}`;
            headline_3 = `${parsedAddress.placeName} Senior Living`;
        }

        data.headline_1 = headline_1.length < 31 ? headline_1 : headline_1.replace('Apartments', 'Apts');
        data.headline_2 = headline_2.length < 31 ? headline_2 : headline_2.replace('Apartments', 'Apts');
        data.headline_3 = headline_3.length < 31 ? headline_3 : headline_3.replace('Apartments', 'Apts');

        if (headline_4) {
            data.headline_4 = headline_4.length < 31 ? headline_4 : headline_4.replace('Apartments', 'Apts');
        }

        return data;
    };

    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        const formItemLayoutWithoutLabel = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 18,
                    offset: 6
                }
            }
        };

        return (
            <React.Fragment>
                <AutofillClientModal
                    open={this.state.gmbAutofillClientModalVisible}
                    onCancel={() => {
                        this.setState({ gmbAutofillClientModalVisible: false });
                    }}
                    mapData={client => {
                        let parsedAddress;
                        let timezone;
                        try {
                            parsedAddress = addresser.parseAddress(client.location);
                            timezone = zipcode_to_timezone.lookup(parsedAddress.zipCode);
                        } catch (error) {
                            console.error(error);
                        }

                        return {
                            account_name: client.company_name,
                            address: client.location,
                            vertical_id: client.vertical,
                            time_zone: timezone,
                            landing_url: client.homepage_urls
                        };
                    }}
                    onOk={data => {
                        const parsedAddress = addresser.parseAddress(data.address);
                        const updatedData = this.setInitialFields(data, parsedAddress);

                        setFieldsValue(updatedData);

                        this.setState({
                            gmbAutofillClientModalVisible: false,
                            student: false,
                            parsedAddress: parsedAddress
                        });
                    }}
                />
                <Form {...formItemLayout} onSubmit={this.handleSubmit} autoComplete="off">
                    <Form.Item style={{ marginRight: '30px' }}>
                        <Button
                            style={{ backgroundColor: '#AD3433', color: '#fff', width: '100%' }}
                            icon={<HomeOutlined />}
                            onClick={() => {
                                this.setState({ gmbAutofillClientModalVisible: true });
                            }}
                        >
                            Select Client Location
                        </Button>
                    </Form.Item>

                    <Collapse size="small" bordered={false} ghost defaultActiveKey={'1'}>
                        <Panel
                            key="1"
                            forceRender={true}
                            header={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Divider
                                        style={{ padding: '0px', marginTop: '0px' }}
                                        orientation="left"
                                        orientationMargin="0"
                                    >
                                        <Text>Location & Campaign</Text>
                                    </Divider>
                                </div>
                            }
                        >
                            <LocationAndCampaign
                                form={this.props.form}
                                setGmbAutofillClientModalVisible={this.setState}
                                managerAccounts={this.state.managerAccounts}
                            />
                        </Panel>

                        {getFieldValue('vertical_id') !== SENIOR_LIVING && (
                            <Panel
                                key="3"
                                forceRender={true}
                                header={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Divider
                                            style={{ padding: '0px', marginTop: '0px' }}
                                            orientation="left"
                                            orientationMargin="0"
                                        >
                                            <Text>Descriptive Campaigns/Ad Groups</Text>
                                        </Divider>
                                    </div>
                                }
                            >
                                {getFieldValue('base_campaign_template') === 'res' && (
                                    <DescriptiveResAdGroups
                                        form={this.props.form}
                                        parsedAddress={this.state.parsedAddress}
                                    />
                                )}
                                {getFieldValue('base_campaign_template') === 'student' && (
                                    <DescriptiveStudentAdGroups
                                        form={this.props.form}
                                        parsedAddress={this.state.parsedAddress}
                                    />
                                )}
                            </Panel>
                        )}

                        {/* floor plans */}
                        {getFieldValue('base_campaign_template') !== 'student' && (
                            <Panel
                                key="2"
                                forceRender={true}
                                header={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Divider
                                            style={{ padding: '0px', marginTop: '0px' }}
                                            orientation="left"
                                            orientationMargin="0"
                                        >
                                            <Text>NonBrand Ad Groups</Text>
                                        </Divider>
                                    </div>
                                }
                            >
                                <NonBrandAdGroups form={this.props.form} parsedAddress={this.state.parsedAddress} />
                            </Panel>
                        )}

                        {getFieldValue('base_campaign_template') === 'res' && (
                            <Panel
                                key="4"
                                forceRender={true}
                                header={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Divider
                                            style={{ padding: '0px', marginTop: '0px' }}
                                            orientation="left"
                                            orientationMargin="0"
                                        >
                                            <Text>Neighborhood Ad Groups</Text>
                                        </Divider>
                                    </div>
                                }
                            >
                                <Neighborhoods form={this.props.form} />
                            </Panel>
                        )}
                        <Panel
                            key="5"
                            forceRender={true}
                            header={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Divider
                                        style={{ padding: '0px', marginTop: '0px' }}
                                        orientation="left"
                                        orientationMargin="0"
                                    >
                                        <Text>Snippets and Callouts</Text>
                                    </Divider>
                                </div>
                            }
                        >
                            <SnippetsAndCallouts form={this.props.form} />
                        </Panel>
                        <Panel
                            key="6"
                            forceRender={true}
                            header={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Divider
                                        style={{ padding: '0px', marginTop: '0px' }}
                                        orientation="left"
                                        orientationMargin="0"
                                    >
                                        <Text>Headlines</Text>
                                    </Divider>
                                </div>
                            }
                        >
                            <Headlines form={this.props.form} parsedAddress={this.state.parsedAddress} />
                        </Panel>

                        <Panel
                            key="7"
                            forceRender={true}
                            header={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Divider
                                        style={{ padding: '0px', marginTop: '0px' }}
                                        orientation="left"
                                        orientationMargin="0"
                                    >
                                        <Text>Descriptions</Text>
                                    </Divider>
                                </div>
                            }
                        >
                            <GadsDescriptions form={this.props.form} />
                        </Panel>

                        <Panel
                            key="8"
                            forceRender={true}
                            header={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Divider
                                        style={{ padding: '0px', marginTop: '0px' }}
                                        orientation="left"
                                        orientationMargin="0"
                                    >
                                        <Text>Site Links</Text>
                                    </Divider>
                                </div>
                            }
                        >
                            <SiteLinks form={this.props.form} />
                        </Panel>
                    </Collapse>

                    <Form.Item {...formItemLayoutWithoutLabel}>
                        <Popconfirm
                            title="Are you sure you want to create this account in Google Ads?"
                            onConfirm={this.handleSubmit}
                            onCancel={e => {
                                message.error('Account was not added to Google Ads');
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" loading={this.state.loading} style={{ marginRight: 8 }}>
                                {this.state.loading ? 'Creating Account' : 'Create Account'}
                            </Button>
                        </Popconfirm>
                        <Button
                            disabled={!getFieldValue('account_name')}
                            type="secondary"
                            onClick={this.handleDownloadCSV}
                            loading={this.state.csvLoading}
                        >
                            Download CSV
                        </Button>
                    </Form.Item>
                </Form>
            </React.Fragment>
        );
    }
}

class AutofillClientModal extends React.Component {
    constructor() {
        super();
        this.state = { loadingLocations: true, locations: [], selectedLocation: null };
    }

    componentDidMount() {
        axios.get(`${API_BASE_URL}companies/companyautofill`).then(res => {
            this.setState({ locations: res.data.clients, loadingLocations: false });
        });

        // axios.get(`${CORE_API_URL}/entity/companyautofill`).then(res => {
        //     const results = res.data.map(client => {
        //         // update the data shape to work with existing code
        //         return {
        //             company_name: client.name,
        //             homepage_urls: client.website,
        //             item_id: client.master_id,
        //             location:
        //                 client.street1 + ', ' + client.city + ', ' + client.state + ' ' + client.postal_code + ', USA',
        //             time_zone: client.time_zone,
        //             vertical_id: client.vertical
        //         };
        //     });

        //     this.setState({ locations: results, loadingLocations: false });
        // });
    }

    render() {
        const {
            open,
            onCancel,
            onOk,

            confirmLoading
        } = this.props;

        return (
            <Modal
                open={open}
                title={'Autofill Client Details...'}
                okText="Autofill Client Details"
                onCancel={() => {
                    onCancel();
                }}
                onOk={() => {
                    if (onOk && typeof onOk === 'function') {
                        onOk(this.state.selectedLocation);
                    }
                }}
                okButtonProps={{ disabled: !this.state.selectedLocation }}
                centered
                confirmLoading={confirmLoading}
            >
                <Spin spinning={this.state.loadingLocations}>
                    <ReactSelect
                        autoFocus
                        formatOptionLabel={({ value, company_name, location }) => (
                            <div
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                <div></div>
                                {company_name} <small style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{location}</small>
                            </div>
                        )}
                        getOptionLabel={({ company_name }) => company_name}
                        getOptionValue={({ item_id }) => item_id}
                        onChange={client => {
                            let parsedAddress;
                            let timezone;
                            try {
                                parsedAddress = addresser.parseAddress(client.location);
                                timezone = zipcode_to_timezone.lookup(parsedAddress.zipCode);
                            } catch (error) {
                                console.error(error);
                            }

                            this.setState({
                                selectedLocation: {
                                    account_name: client.company_name,
                                    address: client.location,
                                    vertical_id: client.vertical_id,
                                    time_zone: timezone,
                                    landing_url: client.homepage_urls
                                }
                            });
                        }}
                        options={this.state.locations}
                    />

                    {this.state.selectedLocation && (
                        <Descriptions title="Preview" bordered={true} column={1} style={{ marginTop: 16 }}>
                            {Object.keys(this.state.selectedLocation).map(key => (
                                <Descriptions.Item
                                    label={
                                        {
                                            account_name: 'Account Name',
                                            address: 'Address',
                                            vertical_id: 'Vertical',
                                            landing_url: 'Main Landing Page URL',
                                            time_zone: 'Time Zone'
                                        }[key]
                                    }
                                    key={key}
                                >
                                    {this.state.selectedLocation[key]}
                                </Descriptions.Item>
                            ))}
                        </Descriptions>
                    )}
                </Spin>
            </Modal>
        );
    }
}

export default Form.create({ name: 'build-search-campaign' })(SearchCampaignBuilderForm);
