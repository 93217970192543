import React, { Component } from 'react';
import { Grid, Row, Col, FormControl } from 'react-bootstrap';
import axios from 'axios';

import Card from '../../components/Card/Card.jsx';
import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';
import Button from '../../components/CustomButton/CustomButton.jsx';

const DEFAULT_STATE = {
    accounts: {},
    masterAccounts: {},
    selectedMasterAccount: null
};

const ACCOUNT_MAPPING_API = {
    Map: 'mapaccountstomasteraccount',
    UnMap: 'unmapmasteraccount'
};

class AccountMapping extends Component {
    constructor() {
        super();

        this.state = DEFAULT_STATE;
    }

    getData() {
        return axios
            .get(`${API_BASE_URL}getunclassifiedaccounts`, {
                headers: authHeader()
            })
            .then(response => {
                const accounts = response.data[0];
                const masterAccounts = response.data[1];

                this.setState({
                    accounts: accounts,
                    masterAccounts: masterAccounts
                });
            })
            .catch(error => {
                console.error('Error getting list of unclassified accounts!', error);
            });
    }

    postData = (api, masterAccount) => {
        return axios
            .post(
                `${API_BASE_URL}${api}`,
                {
                    data: JSON.stringify(masterAccount)
                },
                { headers: authHeader() }
            )
            .catch(error => {
                console.error('Error mapping accounts!', error);
            });
    };

    onAccountSelected = event => {
        const selectedAccountId = event.target.value;
        this.setState({
            selectedAccount: this.state.accounts[selectedAccountId]
        });
    };

    onMasterAccountSelected = event => {
        const selectedMasterAccountId = event.target.value;
        this.setState({
            selectedMasterAccount: this.state.masterAccounts[selectedMasterAccountId]
        });
    };

    onChildAccountSelected = event => {
        const selectedChildAccountId = event.target.value;
        this.setState({
            selectedChildAccount: this.state.selectedMasterAccount.addedChildAccounts[selectedChildAccountId]
        });
    };

    onAddAsMasterAccount = () => {
        const newMasterAccount = {
            account: this.state.selectedAccount,
            addedChildAccounts: {},
            removedChildAccounts: {}
        };

        // Every master account is also a child so needs to be added to the child list.
        newMasterAccount.addedChildAccounts[this.state.selectedAccount.id] = this.state.selectedAccount;

        this.postData(ACCOUNT_MAPPING_API.Map, newMasterAccount).then(() => {
            const masterAccounts = this.state.masterAccounts;
            masterAccounts[this.state.selectedAccount.id] = newMasterAccount;

            const accounts = this.state.accounts;
            delete accounts[this.state.selectedAccount.id];

            this.setState({
                accounts,
                masterAccounts,
                selectedAccount: null,
                selectedMasterAccount: newMasterAccount
            });
        });
    };

    onRemoveAsMasterAccount = () => {
        const masterAccounts = this.state.masterAccounts;
        const masterAccount = masterAccounts[this.state.selectedMasterAccount.account.id];

        this.postData(ACCOUNT_MAPPING_API.UnMap, masterAccount).then(() => {
            // Remove the master account from both the master and child accounts lists.
            delete masterAccounts[masterAccount.account.id];

            // Add the master account back to the list of all unclassified accounts.
            const accounts = this.state.accounts;
            accounts[masterAccount.account.id] = masterAccount.account;

            Object.values(masterAccount.addedChildAccounts).forEach(childAccount => {
                accounts[childAccount.id] = childAccount;
            });

            this.setState({
                accounts,
                masterAccounts,
                selectedMasterAccount: null
            });
        });
    };

    onAddAccountAsChildOfMasterAccount = () => {
        const selectedMasterAccount = this.state.selectedMasterAccount;
        const selectedAccount = this.state.selectedAccount;

        selectedMasterAccount.addedChildAccounts[selectedAccount.id] = selectedAccount;

        this.postData(ACCOUNT_MAPPING_API.Map, selectedMasterAccount).then(() => {
            const accounts = this.state.accounts;
            delete accounts[selectedAccount.id];

            this.setState({
                accounts,
                selectedMasterAccount,
                selectedAccount: null
            });
        });
    };

    onRemoveAccountAsChildOfMasterAccount = () => {
        if (this.state.selectedChildAccount.id === this.state.selectedMasterAccount.account.id) {
            // You can't remove the default child of a master account.
            return;
        }

        const selectedMasterAccount = this.state.selectedMasterAccount;
        const childAccount = selectedMasterAccount.addedChildAccounts[this.state.selectedChildAccount.id];
        selectedMasterAccount.removedChildAccounts[childAccount.id] = childAccount;
        delete selectedMasterAccount.addedChildAccounts[childAccount.id];

        this.postData(ACCOUNT_MAPPING_API.Map, selectedMasterAccount).then(() => {
            // Add the master account back to the list of all unclassified accounts.
            const accounts = this.state.accounts;
            accounts[childAccount.id] = childAccount;

            this.setState({
                accounts,
                selectedMasterAccount,
                selectedChildAccount: null
            });
        });
    };

    getSortedAccounts(accounts) {
        return Object.values(accounts).sort((a, b) => {
            const x = a.account ? a.account.name.toLowerCase() : a.name.toLowerCase();
            const y = b.account ? b.account.name.toLowerCase() : b.name.toLowerCase();

            if (x < y) {
                return -1;
            }

            if (x > y) {
                return 1;
            }

            return 0;
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className={'content account-mapping-component'}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Account Mapping Tool"
                                category="Map accounts across all platforms to one master account."
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <div className={'account-mapping-tools'}>
                                        <Grid fluid>
                                            <Row>
                                                <Col mdOffset={3} md={6}>
                                                    <h4>All Unclassified Accounts</h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col mdOffset={3} md={6}>
                                                    <FormControl
                                                        componentClass="select"
                                                        size="15"
                                                        onChange={this.onAccountSelected}
                                                    >
                                                        {this.getSortedAccounts(this.state.accounts).map(
                                                            (prop, key) => {
                                                                return (
                                                                    <option
                                                                        onDoubleClick={this.onAddAsMasterAccount}
                                                                        key={prop.id}
                                                                        value={prop.id}
                                                                    >
                                                                        {prop.name} - {prop.platform}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={1}>
                                                    <Button
                                                        onClick={this.onAddAsMasterAccount}
                                                        bsStyle="info"
                                                        fill
                                                        disabled={!this.state.selectedAccount}
                                                    >
                                                        <i className={'pe-7s-angle-down-circle'} />
                                                    </Button>
                                                </Col>
                                                <Col md={4}>
                                                    <h4>Master Accounts</h4>
                                                </Col>
                                                <Col md={1}>
                                                    <Button
                                                        onClick={this.onRemoveAsMasterAccount}
                                                        bsStyle="info"
                                                        pullRight
                                                        fill
                                                        disabled={!this.state.selectedMasterAccount}
                                                    >
                                                        <i className={'pe-7s-angle-up-circle'} />
                                                    </Button>
                                                </Col>
                                                <Col md={1}>
                                                    <Button
                                                        onClick={this.onAddAccountAsChildOfMasterAccount}
                                                        bsStyle="info"
                                                        pullRight
                                                        fill
                                                        disabled={
                                                            !this.state.selectedAccount ||
                                                            !this.state.selectedMasterAccount
                                                        }
                                                    >
                                                        <i className={'pe-7s-angle-down-circle'} />
                                                    </Button>
                                                </Col>
                                                <Col md={4}>
                                                    <h4>
                                                        Child Accounts for <br />
                                                        <strong>
                                                            {this.state.selectedMasterAccount &&
                                                                this.state.selectedMasterAccount.account &&
                                                                this.state.selectedMasterAccount.account.name}
                                                        </strong>
                                                    </h4>
                                                </Col>
                                                <Col md={1}>
                                                    <Button
                                                        onClick={this.onRemoveAccountAsChildOfMasterAccount}
                                                        bsStyle="info"
                                                        fill
                                                        disabled={!this.state.selectedChildAccount}
                                                    >
                                                        <i className={'pe-7s-angle-up-circle'} />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormControl
                                                        componentClass="select"
                                                        size="15"
                                                        onChange={this.onMasterAccountSelected}
                                                    >
                                                        {this.getSortedAccounts(this.state.masterAccounts).map(
                                                            (prop, key) => {
                                                                return (
                                                                    <option
                                                                        key={prop.account.id}
                                                                        value={prop.account.id}
                                                                    >
                                                                        {prop.account.name}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </FormControl>
                                                </Col>
                                                <Col md={6}>
                                                    <FormControl
                                                        componentClass="select"
                                                        size="15"
                                                        onChange={this.onChildAccountSelected}
                                                    >
                                                        {this.state.selectedMasterAccount &&
                                                            this.getSortedAccounts(
                                                                this.state.selectedMasterAccount.addedChildAccounts
                                                            ).map((prop, key) => {
                                                                return (
                                                                    <option key={prop.id} value={prop.id}>
                                                                        {prop.name} - {prop.platform}
                                                                    </option>
                                                                );
                                                            })}
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default AccountMapping;
