import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserService } from '../../services/user.service';


export class PrivateRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isProcessing: true,
            hasValidJwt: false
        }
    }
    
    componentDidMount() {
        UserService.checkJwtValidity()
            .then(result => {
                this.setState({
                    isProcessing: false,
                    hasValidJwt: result
                });
            });
    }
    
    render() {
        const { component: Component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={props =>
                    (localStorage.getItem('user') && this.state.hasValidJwt) ? (
                        <Component {...props} />
                    ) : (
                        this.state.isProcessing ? (
                            <div>Loading...</div>
                        ) : (
                            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                        )
                    )
                }
            />
        )
    }
}