import moment from 'moment-timezone';
import {DATE_FORMAT_SAVE} from './GADSconstants';

export default class HelperInternal {
    static processResponse(response, errText) {
        if (!response.data || response.data.response !== 'OK') {
            throw Error(errText);
        }
        return response.data.data;
    }

    static generatesDatesArray(startDate = moment().startOf("month"), endDate= moment().endOf("month")) {
        let dateVarible = moment(startDate);
        let datesArray = [];
        while (dateVarible <= moment(endDate)) {
            datesArray.push(dateVarible.format(DATE_FORMAT_SAVE));
            dateVarible = dateVarible.clone().add(1, 'd');
        }
        return datesArray;
    }

    static summaryByDates(startDate, endDate, spendData) {
        if (!spendData){return false}
        let summaryByDates = {};
        let actualSpendSum = {};
        const datesArray = this.generatesDatesArray(startDate, endDate);
        for (let i = 0; i < datesArray.length; i++) {
            const thisDate = datesArray[i];
            summaryByDates[thisDate] = 0;
            actualSpendSum[thisDate] = 0;
            if (spendData[thisDate]){
                Object.keys(spendData[thisDate]).map(campaign => {
                    let todayAdding =
                        spendData[thisDate][campaign] && !isNaN(spendData[thisDate][campaign])
                            ? parseInt(spendData[thisDate][campaign])/1000000
                            : 0;
                    summaryByDates[thisDate] += todayAdding;
                    actualSpendSum[thisDate] += todayAdding;
                });
            }
        }
        const spentTillToday = Object.values(summaryByDates).reduce(function (a, b) {
            return a + b;
        }, 0);
        return { summaryByDates, spentTillToday, actualSpendSum };
    }
}
