import axios from 'axios';

import React, { useState, useEffect, useRef } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Form, Button, AutoComplete } from 'antd';
import FormBuilder from 'antd-form-builder';
import { API_BASE_URL } from '../../variables/Variables';
import { responceProcess, onError } from '../../services/error.service';
import './form.css';
import { AUTOCOMPLETE_ENDPOINT } from './fieldsTemplates.jsx';
import { topicsKeys } from '../../constants/template-fields1'
import Validators from './validators';
import Timetable from '../../components/DayTimePicker/Timetable';
import _ from 'lodash';

export default props => {
    const [form] = Form.useForm();
    const forceUpdate = FormBuilder.useForceUpdate()

    const { onSubmit, onValidationError } = props;
    const [values, setValues] = useState(props.initialValues);
    const [metaProps, setMetaProps] = useState([]);
    const [meta, setMeta] = useState([]);
    const [autoCompleteFields, setAutocompleteFields] = useState([]);
    const [viewMode, setViewMode] = useState(props.viewMode);
    const formRef = useRef()

    const getMetaKeys = () => {
        let keysMeta = {};
        props.meta.forEach(field => {
            keysMeta[field.key] = { ...field };
        });

        return keysMeta;
    };
    const keysMeta = getMetaKeys();

    const processPropsMetaData = (meta, autoCompleteFields) => {

        let metaValidated = [...meta];

        metaValidated.forEach((field, index) => {
            if (!!metaValidated[index].rules) {
                const rules = [...metaValidated[index].rules];
                metaValidated[index].rules = [];

                rules.forEach(rule => {
                    if (rule in allValidators) {
                        metaValidated[index].rules.push(allValidators[rule])
                    } else if (metaValidated[index][rule]) {
                        const validator = metaValidated[index][rule];
                        metaValidated[index].rules.push({ validator });
                    }
                });
            }

            if (metaValidated[index].widget === 'label') {
                let textForLabel = metaValidated[index].text;
                let widgetProps = metaValidated[index].widgetProps;
                let underline = metaValidated[index].underline;
                metaValidated[index].forwardRef = true;
                metaValidated[index].widget = () => {
                    return labelWidget(textForLabel, widgetProps,underline);
                };
                metaValidated[index].viewWidget = () => {
                    return labelWidget(textForLabel, widgetProps,underline);
                };
            }

            if (metaValidated[index].widget === 'timetable') {
                metaValidated[index].forwardRef = true;
                metaValidated[index].widget = props => {
                    return <Timetable {...props} />;
                };
                metaValidated[index].viewWidget = props => {
                    return <Timetable viewMode={true} value={props.value} />;
                };
                metaValidated[index].onChange = v => {
                    const newVal = {};
                    newVal[metaValidated[index].key] = v;

                    form.setFieldsValue({ ...newVal });
                };
            }

            if (metaValidated[index].widget === 'CLXautocomplete') {
                const { fieldsMapping, fieldConnected, dbSource, dbfield, fieldsUpdate } = metaValidated[index];

                metaValidated[index] = {
                    ...metaValidated[index],
                    forwardRef: true,
                    autoCompleteFields: [],
                    widget(obj) {
                        const showText = obj.value;
                        const { onChange } = obj;
                        const { submitArray, dataSource } = this.autoCompleteFields;

                        return (
                            <AutoComplete
                                name={fieldConnected}
                                defaultValue={showText}
                                onChange={val => {
                                    onChange(val, fieldConnected);
                                }}
                                onSelect={async val => {
                                    const newValues = await populateSelecteded(fieldsMapping, dbSource, dbfield, submitArray[val], ...fieldsUpdate)
                                    form.setFieldsValue({ ...newValues });
                                }}
                                dataSource={dataSource}
                                onSearch={val => {
                                    getAutocompleteCompanyData(val, fieldConnected);
                                }}
                            />
                        );
                    },

                    widgetProps: {
                        submitArray: [],
                        dataSource: [],
                        viewMode: viewMode
                    },
                    getInitialValue(f, allValues, props) {
                        return values ? values[f.key] : '';
                    },
                    onChange(obj, field) {
                        setValues({ ...values, ...{ [field]: obj } });
                    }
                };
                metaValidated[index].widget = metaValidated[index].widget.bind(metaValidated[index]);
            }

            if (metaValidated[index].fullline) {
                metaValidated[index].wrapperCol = { span: 24 };
            }

            if (values && metaValidated[index] && values[metaValidated[index].key]) {
                delete metaValidated[index].initialValue;
            }
        });

        return metaValidated;
    };

    const processSwichedMeta = metaProps => {
        let metaValidated = [...metaProps];

        metaValidated = metaValidated.filter(field => {
            if (viewMode && keysMeta[field.key].widget === 'label') {
                return false;
            }

            if (!field.managed) {
                return true;
            }
            if (!!field.managed) {
                if (values && (values[field.managed] === 'Yes' || values[field.managed] === true)) {
                    return true;
                }
                if (
                    values &&
                    field.managed.indexOf(':!') > -1 &&
                    values[field.managed.split(':!')[0]] !== field.managed.split(':!')[1]
                ) {
                    return true;
                }

                if (
                    values &&
                    field.managed.indexOf(':') > -1 &&
                    values[field.managed.split(':')[0]] === field.managed.split(':')[1]
                ) {
                    return true;
                }
            }
            return false;
        });
        if (props.csTeam) {
            metaValidated.map(field => {
                if (!!field.formItemProps && !!field.formItemProps.hidden) { delete field.formItemProps.hidden; }
            })
        }
        const form = formRef.current
        topicsKeys.forEach((topicKey) => {
            if (form) {
                const value = form.getFieldValue(topicKey)
                if (value === 'Availability' || value === 'Pet Policy' || value === 'Do I Qualify' || value === 'Neighborhood' || value === 'Pricing' || value === 'Custom') {
                    const index = metaValidated.findIndex((field) => field.key === topicKey)
                    metaValidated.splice(index + 1, 0, {
                        key: `${topicKey}_additional_field`,
                        widget: 'input',
                        placeholder: 'Enter the details of your selected topic',
                        required: true,
                        formItemProps: {
                            style: { width: '100%' }
                        },
                    })
                }
            }

        })
        return metaValidated;
    };

    useEffect(() => {
        setMetaProps(processPropsMetaData(props.meta));
    }, [props.meta]);

    useEffect(() => {
        let metaMod = [...metaProps];
        function findWithAttr(array, value) {
            for (var i = 0; i < array.length; i += 1) {
                if (array[i].key === value) {
                    return i;
                }
            }
            return -1;
        }
        Object.keys(autoCompleteFields).forEach(key => {
            metaMod[findWithAttr(metaMod, key)].autoCompleteFields = autoCompleteFields[key];
        });
        setMeta(processSwichedMeta(metaMod));
    }, [autoCompleteFields]);

    useEffect(() => {
        setMeta(processSwichedMeta(metaProps));
    }, [metaProps, values]);

    useEffect(() => {
        setValues(props.initialValues);
    }, [props.initialValues]);

    useEffect(() => {
        let initialValues = { ...props.initialValues };
        let currentValues = form.getFieldsValue();
        let viewMode = props.viewMode;

        if (!viewMode) {
            Object.keys(initialValues).forEach(field_key => {

                if (!!keysMeta[field_key] && keysMeta[field_key].widget === 'switch') {
                    currentValues[field_key] = initialValues[field_key] === 'Yes' ? true : false;
                } else {
                    currentValues[field_key] = initialValues[field_key];
                }
            });
        } else {
            currentValues = initialValues;
        }

        let metaMod = [...meta];
        for (let index = 0; index < metaMod.length; index++) {
            if (metaMod[index].hasOwnProperty('viewMode')) {
                metaMod[index].viewMode = viewMode;
            }
        }

        setMeta(metaMod);
        setValues(currentValues);
        setViewMode(viewMode);
    }, [props.viewMode]);

    useEffect(() => {
        const formValues = form.getFieldsValue();
        const changedValues = {}
        Object.keys(formValues).map(field => {
            if (!_.isEqual(formValues[field], values[field])) {
                changedValues[field] = formValues[field]
            }
        })
        if (
            !values ||
            Object.keys(changedValues).length > 0
        ) {
            setValues({ ...values, ...changedValues });
        }
    });

    const onFinish = (values) => {

        const rawData = { ...values }; 

        let preparedData = {};
        Object.keys(rawData).forEach(field_key => {
            let value = rawData[field_key];
            if (!keysMeta[field_key]) {
                preparedData[field_key] = value;
                return;
            }
            if (keysMeta[field_key].widget === 'label') {
                return;
            }

            if (keysMeta[field_key].widget === 'switch') {
                value = value === true ? 'Yes' : 'No';
            }

            if (typeof value === 'undefined') {
                return;
            }

            preparedData[field_key] = value;
        });

        return onSubmit(preparedData);

    };

    const allValidators = Validators

    const labelWidget = (value, widgetProps, underline=false) => {
        return (<React.Fragment>
            <legend style={!!widgetProps && !!widgetProps.style ? widgetProps.style : {}}>{value}</legend>
            {underline && <a style={!underline.uStyle?{}:underline.uStyle} href={underline.href}>{underline.text}</a>}
            </React.Fragment>);
    };

    const getAutocompleteCompanyData = (search, fieldConnected) => {

        if (search.length < 3) {
            return;
        }

        const apiUrl = `${API_BASE_URL}companies/autocomplete/`;

        axios
            .get(apiUrl, { params: { search } })
            .then(res => {
                return responceProcess(res);
            })
            .then(result => {
                let values = { ...autoCompleteFields };
                values[fieldConnected] = {
                    value: search,
                    dataSource: result.map(row => {
                        return row['company_name'];
                    })
                };
                values[fieldConnected]['submitArray'] = {};
                result.forEach(row => {
                    values[fieldConnected]['submitArray'][row['company_name']] = row['item_id'];
                });

                setAutocompleteFields(values);
            })
            .catch(e => { });
    };

    const getAutocompleteData = (searchString, dbSource, dbfield, fieldConnected, fieldsListReturn) => {
        if (searchString.length < 3) {
            return;
        }
        const fieldsList = fieldsListReturn || ['item_id', 'company_name'];
        axios
            .get(`${API_BASE_URL}${AUTOCOMPLETE_ENDPOINT}${dbSource}/${dbfield}/${searchString}/${fieldsList}`)
            .then(res => {
                return responceProcess(res);
            })
            .then(result => {
                let values = { ...autoCompleteFields };
                values[fieldConnected] = {
                    value: searchString,
                    dataSource: result.map(row => {
                        return row[fieldsList[1]];
                    })
                };
                values[fieldConnected]['submitArray'] = {};
                result.forEach(row => {
                    values[fieldConnected]['submitArray'][row[fieldsList[1]]] = row[fieldsList[0]];
                });
                setAutocompleteFields(values);
            })
            .catch(error => {
                console.log('error getting companies info');
            });
    };

    const populateSelecteded = (fieldsMapping, dbSource, dbfield, choosedId, fieldConnected) => {
        const setValues = setValues
        const fieldsList = Object.keys(fieldsMapping);
        return axios
            .get(`${API_BASE_URL}${AUTOCOMPLETE_ENDPOINT}${dbSource}/${dbfield}/${choosedId}/${fieldsList}`)
            .then(res => {
                return responceProcess(res);
            })
            .then(result => {
                if (!result[0]) {
                    return;
                }
                let transformedFields = {};
                Object.keys(result[0]).forEach(dbField => {
                    if (typeof fieldsMapping[dbField] === 'string') {
                        transformedFields[fieldsMapping[dbField]] =
                            typeof result[0][dbField] === 'string' ? result[0][dbField].trim() : result[0][dbField];
                    } else {
                        // eslint-disable-next-line no-eval
                        transformedFields[Object.keys(fieldsMapping[dbField])[0]] = eval(
                            Object.values(fieldsMapping[dbField])[0]
                        )(result[0][dbField]);
                    }
                });
                return transformedFields;
            })
            .catch(error => {
                console.log('error getting companies info');
            });
    };

    return !meta || !meta.length ? (
        <h4>No form data</h4>
    ) : (
        <React.Fragment>
                <Form scrollToFirstError ref={formRef} name={"basic-form"} form={form} onFinish={onFinish} onValuesChange={forceUpdate} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal">
                    {/* Use Form Builder to dynamically create the needed input fileds.
             Input definitions provided by a template and passed to formbuilder in the meta property */}
                    <FormBuilder form={form} meta={{ fields: meta, initialValues: values }} layout="vertical" viewMode={viewMode} />
                    {viewMode ? '' :
                        (<Form.Item wrapperCol={{ span: 16, offset: 8 }}>
                            <Button type="primary" htmlType="submit" loading={props.loading}>
                                Submit
                            </Button>
                        </Form.Item>)
                    }
                </Form>
                <div style={{ height: "10px" }}></div>
        </React.Fragment>
    );
};