import React, { Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { SRL_SITE_LINKS, RES_SITE_LINKS } from '../FormConstants';
import { Input, Switch, Divider } from 'antd';

const SENIOR_LIVING = 'srl';

function SiteLinks(props) {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;

    const SiteLinkItems = fields => {
        return fields.map((item, i) => {
            return (
                <Form.Item label={item.label} key={item.name} extra={item.extra}>
                    {getFieldDecorator(`${item.name}`, {
                        rules: [
                            {
                                required: item.label.includes('Main Landing Page')
                            },
                            {
                                type: 'url',
                                warningOnly: true
                            }
                        ]
                    })(<Input placeholder={item.placeholder} />)}
                </Form.Item>
            );
        });
    };

    return (
        <Fragment>
            {SiteLinkItems(getFieldValue('vertical_id') === SENIOR_LIVING ? SRL_SITE_LINKS : RES_SITE_LINKS)}
            <Divider />
            {getFieldValue('vertical_id') !== SENIOR_LIVING && (
                <Form.Item label="Avenue 5" extra={'Selecting Yes will enable the Avenue 5-specific UTM tag'}>
                    {getFieldDecorator('isAve5', {
                        valuePropName: 'checked'
                    })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
                </Form.Item>
            )}
            <Form.Item
                label="Final URL DNI"
                key="final_url_dni"
                extra="Alphanumeric  characters only! This will be suffixed to all campaign and ad group Final URLs."
            >
                {getFieldDecorator('final_url_dni', {
                    rules: [
                        {
                            pattern: new RegExp('^[a-zA-Z0-9.]*$'),
                            message: 'No special characters. Letters and numbers only'
                        }
                    ]
                })(<Input placeholder="ex: 123th7ye" />)}
            </Form.Item>
        </Fragment>
    );
}

export default SiteLinks;
