import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';
import tooltip from './chartist-plugin-tooltip';
import { Radio } from 'react-bootstrap';
import axios from 'axios';
import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';
import cloneDeep from 'lodash/cloneDeep';
import ReportingConstants from './constants/reporting.constants';

const MAX_NUM_LABELS_X_AXIS = 20;

const GRAPH_REPSPONSIVE_OPTIONS = [
    [
        'screen and (max-width: 640px)',
        {
            axisX: {
                labelInterpolationFnc: function(value) {
                    return value[0];
                }
            }
        }
    ]
];

class DataTableChart extends Component {
    constructor() {
        super();
        this.state = {
            graphableData: {},
            activeGraphableData: {},
            graphableFields: [],
            loadingData: false
        };
    }

    getGraphOptions() {
        return {
            showArea: false,
            height: '245px',
            axisX: {
                showGrid: true,
                labelInterpolationFnc: (value, index, labels) => {
                    if (labels.length > MAX_NUM_LABELS_X_AXIS) {
                        const labelsDividedByMax = Math.trunc(Math.ceil(labels.length / MAX_NUM_LABELS_X_AXIS));
                        return index % labelsDividedByMax ? null : value;
                    }

                    return value;
                }
            },
            axisY: {
                labelInterpolationFnc: value => {
                    const adjustment = this.state.activeGraphableData.yAxisLabelAdjustments[
                        this.state.activeGraphableField
                    ];

                    if (!adjustment) {
                        return value;
                    }

                    if (adjustment.type === 'pre') {
                        return `${adjustment.modifier}${value.toFixed(2)}`;
                    }

                    return `${value.toFixed(2)}${adjustment.modifier}`;
                },
                stretch: true
            },
            lineSmooth: true,
            showLine: true,
            showPoint: true,
            fullWidth: true,
            chartPadding: {
                right: 50
            },
            plugins: [
                tooltip({
                    appendToBody: true
                })
            ]
        };
    }

    getGraphData(graphableField, forceUpdate) {
        const graphableData = this.state.graphableData;
        if (!forceUpdate && graphableData[graphableField]) {
            // Use cached data.
            this.setState({
                activeGraphableField: graphableField,
                activeGraphableData: graphableData[graphableField],
                showChart: true
            });

            return;
        }

        if (this.state.loadingData) {
            return;
        }

        this.setState({
            loadingData: true
        });

        const graphableFilterOptions = this.state.graphableFilterOptions;
        graphableFilterOptions.accountId = this.props.data.id;
        graphableFilterOptions.graphableField = graphableField;

        return axios
            .get(`${API_BASE_URL}${ReportingConstants.GET_GRAPHABLE_DATA_FOR_BAR}`, {
                params: JSON.stringify(this.state.graphableFilterOptions),
                headers: authHeader()
            })
            .then(data => {
                // The summary row component in the campaign tabs gets unmounted by design when
                // filters change.  We will likely be in the middle of a network request when that
                // happens.  If that is the case then just ignore the response.
                if (!this.componentIsMounted) {
                    return;
                }

                graphableData[graphableField] = data.data;
                this.setState({
                    graphableData,
                    activeGraphableField: graphableField,
                    activeGraphableData: data.data,
                    showChart: true,
                    graphableFilterOptions,
                    loadingData: false
                });
            })
            .catch(error => {
                console.error('Error downloading reporting data!', error);
            });
    }

    onGraphableFieldsClick = graphableField => {
        this.getGraphData(graphableField);
    };

    componentDidMount() {
        this.componentIsMounted = true;
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isActiveChart || this.props === prevProps || !this.props.showChart) {
            return;
        }

        const graphableFilterOptions = cloneDeep(this.props.filterOptions);
        const filterOptions = cloneDeep(this.props.filterOptions);
        filterOptions.accountId = this.props.data.id;
        filterOptions.getCampaigns = true;

        if (this.props.data.campaignId) {
            graphableFilterOptions.campaignId = this.props.data.campaignId;
        }

        this.setState(
            {
                filterOptions,
                graphableFilterOptions
            },
            () => {
                if (this.props.showChart) {
                    const graphableField = this.state.activeGraphableField || this.props.graphableFields[0];
                    this.getGraphData(graphableField, true);
                }
            }
        );
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    render() {
        if (!this.state.showChart) {
            return <div />;
        }

        let now = Date.now();

        let mappedColumnNames = {};
        this.props.tableHeaders.forEach(prod => {
            mappedColumnNames[prod.field] = prod.display;
        });

        return (
            <div className={this.state.loadingData ? 'clx-loading' : ''}>
                {this.props.graphableFields.map(prop => {
                    const key = `prop${now++}`;
                    return (
                        <Radio
                            name={`${this.props.data.name}_graphableFields`}
                            key={key}
                            onChange={() => this.onGraphableFieldsClick(prop)}
                            checked={this.state.activeGraphableField === prop}
                            inline
                        >
                            {mappedColumnNames[prop]}
                        </Radio>
                    );
                })}
                <ChartistGraph
                    data={this.state.activeGraphableData}
                    type="Line"
                    options={this.getGraphOptions()}
                    responsiveOptions={GRAPH_REPSPONSIVE_OPTIONS}
                />
            </div>
        );
    }
}

export default DataTableChart;
