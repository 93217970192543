import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import HeaderLinks from '../Header/HeaderLinks.jsx';

import imagine from '../../assets/img/sidebar-3.jpg';
import logo from '../../assets/img/CL-logo.png';

import dashboardRoutes from '../../routes/dashboard.jsx';
import { UserService } from '../../services/user.service';
import { lastUpdate } from '../../lastupdate.json';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        };
    }
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    }
    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions.bind(this));
    }
    render() {
        const user = UserService.getInfoFromStorage();
        const sidebarBackground = {
            backgroundImage: 'url(' + imagine + ')'
        };
        return (
            <div id="sidebar" className="sidebar" data-color="black" data-image={imagine}>
                <div className="sidebar-background" style={sidebarBackground} />
                <div className="logo">
                    <a href="https://dashboard.clxinternal.com" className="simple-text logo-normal">
                        <img src={logo} alt="logo_image" className="logo-img" />
                    </a>
                </div>
                <div className="sidebar-wrapper">
                    <ul className="nav">
                        {this.state.width <= 991 ? <HeaderLinks /> : null}
                        {dashboardRoutes.map((prop, key) => {
                            if (!prop.allowExternal && !user.user.username.includes('@clxmedia.com')) {
                                return null;
                            }

                            if (prop.hidden) {
                                return null;
                            }
                            var st = {};
                            st[prop['state']] = !this.state[prop.state];
                            if (prop.collapse) {
                                return (
                                    <li className={this.activeRoute(prop.path)} key={key}>
                                        <a onClick={() => this.setState(st)}>
                                            <i className={prop.icon} />
                                            <p>
                                                {prop.name}
                                                <b className={this.state[prop.state] ? 'caret rotate-180' : 'caret'} />
                                            </p>
                                        </a>
                                        <Collapse in={this.state[prop.state]} style={{ marginTop: 0 }}>
                                            <ul className="nav">
                                                {prop.views.map((prop, key) => {
                                                    if (prop.hidden) {
                                                        return null;
                                                    }
                                                    return (
                                                        <li className={this.activeRoute(prop.path)} key={key} style={{ textAlign:'start', marginLeft: 30 }}>
                                                            <NavLink
                                                                to={prop.path}
                                                                className="nav-link"
                                                                activeClassName="active"
                                                            >
                                                                <span className="sidebar-normal">{prop.name} </span>
                                                            </NavLink>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </Collapse>
                                    </li>
                                );
                            } else {
                                if (prop.redirect) {
                                    return null;
                                } else {
                                    return (
                                        <li className={this.activeRoute(prop.path)} key={key}>
                                            <NavLink to={prop.path} className="nav-link" activeClassName="active">
                                                <i className={prop.icon} />
                                                <p>{prop.name}</p>
                                            </NavLink>
                                        </li>
                                    );
                                }
                            }
                        })}
                    </ul>
                    <div className="last-update">Last update: {lastUpdate}</div>
                </div>
            </div>
        );
    }
}

export default Sidebar;
