import React from 'react';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import { UserService } from '../../services/user.service';
import logo from '../../assets/img/CL-logo.png';
import { notification, Alert } from 'antd';
import { getUrlParameter } from '../../helpers/utilities';

class ResetPass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: 0,
            username: '',
            password: '',
            passwordConfirm: '',
            token: '',
            tokenExpired: false,
            tokenMissing: false,
            userIdMissing: false
        }
    }

    componentDidMount() {
        // Check for expired or missing token
        const token = getUrlParameter('token');
        const expiresAt = parseInt(getUrlParameter('expiresAt'), 10);
        const userId = parseInt(getUrlParameter('userId'), 10);
        const nowSec = Math.round((new Date()).getTime() / 1000);
        const tokenValidatorState = {
            tokenExpired: true,
            tokenMissing: true,
            token: undefined,
            userId: undefined,
            userIdMissing: true
        };
        if (typeof (userId) === 'number' && !Number.isNaN(userId)) {
            tokenValidatorState.userId = userId;
            tokenValidatorState.userIdMissing = false;
        }
        if (token !== '') {
            tokenValidatorState.tokenMissing = false;
            tokenValidatorState.token = token;
        }
        if (typeof (expiresAt) === 'number' && !Number.isNaN(expiresAt)) {
            // Expires at should be in sec, not MS
            if (expiresAt >= nowSec) {
                tokenValidatorState.tokenExpired = false;
            }
        }
        else {
            tokenValidatorState.tokenMissing = true;
        }
        
        // Update state
        this.setState(tokenValidatorState);
    }

    getErrorClassName(field) {
        return this.state.submitted && !this.state[field] ? ' has-error' : '';
    }

    getHelpBlockWithConditionAndMessage(condition, message) {
        if (!condition) {
            return;
        }

        return <div className="help-block">{message}</div>;
    }

    getPasswordMatch() {
        return this.state.password === this.state.passwordConfirm;
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = e => {
        e.preventDefault();

        this.setState({ submitted: true, error: '' });
        const { username, password, userId, token } = this.state;

        if (username && password) {
            UserService.resetPassword(userId, password, token)
                .then(res => {
                    if (res.success) {
                        notification.success({
                            message: 'Success!',
                            description: res.msg
                        });
                        window.setTimeout(() => {
                            window.location.pathname = '/login';
                        }, 1500);
                    }
                    else {
                        notification.error({
                            message: 'Error',
                            description: res.msg
                        });
                    }
                })
                .catch(error => {
                    this.setState({
                        error: 'Invalid inputs'
                    });
                });
        }
    };

    showErrorMsg() {
        let errorMsg = '';
        if (this.state.userIdMissing) {
            errorMsg = 'Missing a user Id. Make sure to come to this page via the special reset link you were sent.';
        }
        if (this.state.tokenExpired) {
            errorMsg = 'Your reset link has expired. Please go back to the main login screen and resend it.';
        }
        else if (this.state.tokenMissing) {
            errorMsg = 'You are missing a reset token. Make sure to come to this page via the special reset link you were sent.';
        }
        return (
            <div
                style={{
                    padding: '10px'
                }}
            >
                <Alert
                    message="Error"
                    description={errorMsg}
                    type="error"
                />
                <a href="/login">Back to login page</a>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
            {(this.state.tokenExpired || this.state.tokenMissing || this.state.userIdMissing) ? (this.showErrorMsg()) : (
                <div className={'login-form'}>
                    <h1>Reset your password:</h1>
                    <Form horizontal onSubmit={this.handleSubmit}>
                        <img src={logo} className={'login-logo'} alt="Conversion Logix Logo" />
                        <FormGroup controlId="formHorizontalEmail" className={this.getErrorClassName('username')}>
                            <Col componentClass={ControlLabel} sm={2}>
                                Email
                            </Col>
                            <Col sm={10}>
                                <FormControl
                                    type="email"
                                    placeholder="Email"
                                    name="username"
                                    onChange={this.handleChange}
                                />
                                {this.getHelpBlockWithConditionAndMessage(
                                    this.state.submitted && !this.state.username,
                                    'Email is required'
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="formHorizontalPassword" className={this.getErrorClassName('password')}>
                            <Col componentClass={ControlLabel} sm={2}>
                                Password
                            </Col>
                            <Col sm={10}>
                                <FormControl
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    onChange={this.handleChange}
                                />
                                {this.getHelpBlockWithConditionAndMessage(
                                    this.state.submitted && !this.state.password,
                                    'Password is required'
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="formHorizontalPasswordConfirm" className={this.getErrorClassName('password')}>
                            <Col componentClass={ControlLabel} sm={2}>
                                Password Confirm
                            </Col>
                            <Col sm={10}>
                                <FormControl
                                    type="password"
                                    placeholder="Password"
                                    name="passwordConfirm"
                                    onChange={this.handleChange}
                                />
                                {this.getHelpBlockWithConditionAndMessage(
                                    this.state.submitted && (!this.state.password || !this.getPasswordMatch()),
                                    'Password is required and must be confirmed'
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup className={this.state.error ? ' has-error' : ''}>
                            <Col smOffset={2} sm={10}>
                                {this.getHelpBlockWithConditionAndMessage(!!this.state.error, this.state.error)}
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Col smOffset={2} sm={10}>
                                <Button type="submit">Reset Password</Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </div>
            )}
            </React.Fragment>
        )
    }
}

export default ResetPass;
