import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';

import { Grid, Table } from 'react-bootstrap';

import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';

import {
    CheckCircleTwoTone,
    CheckSquareOutlined,
    CloseSquareOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    FrownOutlined,
    LinkOutlined,
    LoadingOutlined,
    MinusCircleTwoTone,
    StopTwoTone
} from '@ant-design/icons';

import { Row, Col, DatePicker, Collapse, message, Tag, Tooltip, Typography, Pagination, Spin } from 'antd';
import 'antd/dist/antd.css';
import './banner-review.css';

const { Text } = Typography;
const DATE_FORMAT = 'MM/DD/YYYY';
const DATE_FORMAT_CARD = 'MM/DD';
const thisTimezone = 'America/Los_Angeles';

class BannerReview extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            page: 1,
            itemsPerPage: 25,
            loadingData: true,
            total: 0,
            banners: [],
            statistic: {},
            statisticAdvertiserProcessed: [],
            statisticZeroAdvertiser: [],
            statisticLI: {},
            statisticLIProcessed: [],
            lineItems: {},
            loadingStats: false,
            startDate: moment().subtract(1, 'days'),
            endDate: moment().subtract(1, 'days')
        };
        this.handlePage = this.handlePage.bind(this);
        this.handlePageSize = this.handlePageSize.bind(this);
        this.getStatistic = this.getStatistic.bind(this);
    }

    getBanners() {
        const { page, itemsPerPage, startDate, endDate } = this.state;
        if (!startDate || !endDate) {
            return;
        }
        this.setState({ loadingData: true });
        return axios
            .get(`${API_BASE_URL}anx/getcreatives`, {
                headers: authHeader(),
                params: {
                    page,
                    itemsPerPage,
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD')
                }
            })
            .then(response => {
                if (!response.data) {
                    throw Error('Error Banners getting ');
                }
                let creativesGroupedObject = {};
                const regExPattern = /(.*)\s(\d*)x(\d*).*?\.(.*)/;

                response.data.forEach(creative => {
                    let regResult = regExPattern.exec(creative.name);
                    let groupName = creative.name;
                    if (regResult != null && regResult[1] && regResult[1] !== '') {
                        groupName = regResult[1];
                    }
                    groupName +=
                        '||' +
                        (!!creative['line_items'] && creative['line_items'].length
                            ? creative['line_items']
                                  .map(li => {
                                      return li.id;
                                  })
                                  .sort()
                                  .join('%')
                            : '');

                    if (!creativesGroupedObject.hasOwnProperty(groupName)) {
                        creativesGroupedObject[groupName] = { groupName, creatives: [creative] };
                    } else {
                        let sizeError = Math.abs(creative.height - 250) + Math.abs(creative.width - 320);
                        let firstMemberSizeError =
                            Math.abs(creativesGroupedObject[groupName].creatives[0].height - 250) +
                            Math.abs(creativesGroupedObject[groupName].creatives[0].width - 320);
                        if (firstMemberSizeError < sizeError) {
                            creativesGroupedObject[groupName].creatives.push(creative);
                        } else {
                            creativesGroupedObject[groupName].creatives.unshift(creative);
                        }
                    }
                });

                const creativesGrouped = Object.keys(creativesGroupedObject).map(groupName => {
                    return creativesGroupedObject[groupName];
                });

                this.setState({
                    statistic: {},
                    banners: creativesGrouped,
                    total: creativesGrouped.length
                });
                let lineitems = {};
                let advertisers = [];
                response.data.forEach(creative => {
                    if (!creative.line_items) {
                        return;
                    }
                    if (advertisers.indexOf(creative.advertiser_id) === -1) {
                        advertisers.push(creative.advertiser_id);
                    }
                    creative.line_items.forEach(li => {
                        if (!lineitems.hasOwnProperty(li.id)) {
                            lineitems[li.id] = [creative.advertiser_id, li.id];
                        }
                    });
                });

                //get statistics for all crives
                advertisers.reduce((s, adv) => {
                    return s.then(() => {
                        return this.getStatistic(adv, 'creative');
                    });
                }, Promise.resolve());

                //get statistics for all lineitems
                advertisers.reduce((s, adv) => {
                    return s.then(() => {
                        return this.getStatistic(adv, 'lineitem');
                    });
                }, Promise.resolve());

                //get all lineitems for advertiser
                advertisers.reduce((s, adv) => {
                    return s.then(() => {
                        return this.getLineItems(adv);
                    });
                }, Promise.resolve());
            })
            .catch(error => {
                message.error(`Error getting banners ${error} .`);
                console.error('Error getting list of accounts!', error);
            })
            .finally(() => {
                this.setState({ loadingData: false });
            });
    }

    getLineItems(advertiserId) {
        let lineItems = { ...this.state.lineItems };
        if (lineItems.hasOwnProperty(advertiserId)) {
            return;
        }
        axios
            .get(`${API_BASE_URL}anx/getlineitems`, {
                headers: authHeader(),
                params: {
                    advertiserId
                }
            })
            .then(response => {
                if (!response.data['line-items']) {
                    throw Error('Error Line Item getting ');
                }
                let lineItems = { ...this.state.lineItems };
                lineItems[advertiserId] = response.data['line-items'];
                this.setState({
                    lineItems
                });
            })
            .catch(error => {
                console.error('Error getting line-items details', error);
            });
    }

    getStatistic(advertiserId, type = 'creative') {
        const { startDate, endDate } = this.state;
        if (!startDate || !endDate) {
            return;
        }
        return axios
            .get(`${API_BASE_URL}anx/getcreativestatistic`, {
                headers: authHeader(),
                params: {
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                    advertiserId,
                    type
                }
            })
            .then(response => {
                if (type === 'creative') {
                    let statisticZeroAdvertiser = [...this.state.statisticZeroAdvertiser];
                    if (!response.data.length || response.data.length < 2) {
                        statisticZeroAdvertiser.push(advertiserId);
                        this.setState({
                            statisticZeroAdvertiser
                        });
                        return;
                    }
                    let statistic = { ...this.state.statistic };
                    let statisticAdvertiserProcessed = [...this.state.statisticAdvertiserProcessed];
                    response.data.shift();
                    response.data.forEach(creative => {
                        statistic[creative[0]] = { imps: creative[1], clicks: creative[2] };
                    });
                    statisticAdvertiserProcessed.push(advertiserId);
                    this.setState({
                        statistic,
                        statisticAdvertiserProcessed
                    });
                } else {
                    let statisticLI = { ...this.state.statisticLI };
                    let statisticLIProcessed = [...this.state.statisticLIProcessed];
                    response.data.shift();
                    response.data.forEach(lineItem => {
                        statisticLI[lineItem[0]] = { imps: lineItem[1], clicks: lineItem[2] };
                    });
                    statisticLIProcessed.push(advertiserId);
                    this.setState({
                        statisticLI,
                        statisticLIProcessed
                    });
                }
                return Promise.resolve();
            })
            .catch(error => {
                console.error('Error getting statistics!', error);
            });
    }

    componentDidMount() {
        this.getBanners();
    }

    handlePage(page, pageSize) {
        this.setState({ page });
    }

    handlePageSize(current, size) {
        this.setState({ itemsPerPage: size });
    }

    handleDate(field, date) {
        let state = { ...this.state };
        state[field] = date;
        state.page = 1;
        state.statisticZeroAdvertiser = [];
        state.statistic = {};
        state.banners = [];
        state.statisticAdvertiserProcessed = [];
        state.statisticLI = {};
        state.statisticLIProcessed = [];
        this.setState(state, this.getBanners);
    }

    renderLineItem(row) {
        const { lineItems, statisticLIProcessed, statisticLI } = this.state;
        const renderLi = !lineItems[row.advertiser_id] ? row.line_items : lineItems[row.advertiser_id];
        let nativeLiIds = [];
        if (!!row.line_items && row.line_items.length) {
            nativeLiIds = row.line_items.map(li => {
                return li.id;
            });
        }

        return renderLi ? (
            <div>
                {renderLi.map(lineitem => {
                    let imps = (
                        <Tag color="orange">
                            <LoadingOutlined />
                        </Tag>
                    );

                    if (statisticLIProcessed.indexOf(row.advertiser_id) > -1) {
                        if (!!statisticLI[lineitem.id]) {
                            imps = <Tag color="orange">{statisticLI[lineitem.id].imps}</Tag>;
                        } else {
                            imps = <Tag color="orange">{0}</Tag>;
                        }
                    }
                    return (
                        <Row>
                            <div className={'brand-name' + (nativeLiIds.indexOf(lineitem.id) === -1 ? '' : '-good')}>
                                <a href={`https://console.appnexus.com/order-home/line-item/${lineitem.id}`}>
                                    {lineitem.name}
                                </a>
                                <span className="banner-id">({lineitem.id})</span>
                                <Tooltip placement="top" title={'State: ' + lineitem.state}>
                                    {lineitem.state === 'active' ? (
                                        <CheckCircleTwoTone className="status-icon good" twoToneColor="#52c41a" />
                                    ) : (
                                        <MinusCircleTwoTone className="status-icon bad" twoToneColor="#c4521a" />
                                    )}
                                </Tooltip>
                                <Tooltip placement="top" title={'Impressions'}>
                                    {imps}
                                </Tooltip>
                                {lineitem['click_url'] ? (
                                    <a href={lineitem['click_url']}>
                                        <LinkOutlined />
                                    </a>
                                ) : lineitem && lineitem.hasOwnProperty('click_url') ? (
                                    <CloseSquareOutlined />
                                ) : (
                                    <LoadingOutlined />
                                )}
                            </div>
                        </Row>
                    );
                })}
            </div>
        ) : (
            '-'
        );
    }

    renderStatusBig(row, imps, adx_audit_status) {
        return (
            <div className="banner-status">
                <Tooltip placement="top" title="Target">
                    {row.landing_page_url ? (
                        <a href={row.landing_page_url}>
                            <LinkOutlined className="status-icon" />
                        </a>
                    ) : (
                        ''
                    )}
                </Tooltip>
                <Tooltip
                    placement="top"
                    title={
                        <React.Fragment>
                            <Tag color="green">
                                {'Created: ' + moment.utc(row.created_on).tz(thisTimezone).format('MM/DD HH:MM')}
                            </Tag>
                            <Tag color="green">{'APN Created: ' + moment(row.created_on).format('MM/DD HH:MM')}</Tag>
                        </React.Fragment>
                    }
                >
                    <div>
                        <Tag color="purple">
                            {moment(moment.utc(row.created_on).tz(thisTimezone)).format(DATE_FORMAT_CARD)}
                        </Tag>
                    </div>
                </Tooltip>
                <Tooltip placement="top" title="Image size">
                    <div>
                        <Tag color="purple">{row.width + 'x' + row.height}</Tag>
                    </div>
                </Tooltip>

                <Tooltip placement="top" title={'ADX audit : ' + adx_audit_status}>
                    {adx_audit_status === 'approved' ? (
                        <CheckSquareOutlined className="status-icon good" />
                    ) : (
                        <CloseSquareOutlined className="status-icon bad" />
                    )}
                </Tooltip>
                <Tooltip placement="top" title={'Audit status: ' + row.audit_status}>
                    {row.audit_status === 'audited' ? (
                        <EyeOutlined className="status-icon good" />
                    ) : (
                        <FrownOutlined className="status-icon bad" />
                    )}
                </Tooltip>
                <Tooltip placement="top" title={'SSL Status : ' + row.ssl_status}>
                    {row.ssl_status === 'approved' ? (
                        <CheckSquareOutlined className="status-icon good" />
                    ) : (
                        <CloseSquareOutlined className="status-icon bad" />
                    )}
                </Tooltip>
                <Tooltip placement="top" title={'Impressions'}>
                    {imps}
                </Tooltip>
            </div>
        );
    }
    renderCreative(row, kind) {
        let imps = (
            <Tag color="orange">
                <LoadingOutlined />
            </Tag>
        );

        if (
            this.state.statisticZeroAdvertiser.indexOf(row.advertiser_id) > -1 ||
            this.state.statisticAdvertiserProcessed.indexOf(row.advertiser_id) > -1
        ) {
            imps = <Tag color="orange">{0}</Tag>;
        }

        if (!!this.state.statistic[row.id] && !!this.state.statistic[row.id].imps) {
            imps = <Tag color="orange">{this.state.statistic[row.id].imps}</Tag>;
        }

        let adx_audit_status = !row.adx_audit || !row.adx_audit.audit_status ? '-' : row.adx_audit.audit_status;
        return (
            <div className={'banner-card' + kind}>
                {kind === '' ? (
                    ''
                ) : (
                    <div className="banner-image">
                        <a
                            className="banner-image"
                            href={`https://console.appnexus.com/creative?id=${row.advertiser_id}&creative_id=${row.id}`}
                        >
                            <img src={row.media_url} />
                        </a>
                    </div>
                )}
                {this.renderStatusBig(row, imps, adx_audit_status)}
            </div>
        );
    }

    creativeGroupRender(group) {
        if (!group || !group.creatives) {
            return;
        }
        let groupStatus = {};
        let groupStatusFlag = [];
        let allCreatives = [...group.creatives];

        groupStatus.adx_audit = allCreatives.filter(crv => {
            return !crv.adx_audit || !crv.adx_audit.audit_status || crv.adx_audit.audit_status !== 'approved';
        }).length;

        groupStatus.audit_status = allCreatives.filter(crv => {
            return !crv.audit_status || crv.audit_status !== 'audited';
        }).length;

        groupStatus.ssl_status = allCreatives.filter(crv => {
            return !crv.ssl_status || crv.ssl_status !== 'approved';
        }).length;

        Object.keys(groupStatus).forEach(audit_key => {
            if (groupStatus[audit_key] > 0) {
                groupStatusFlag.push(audit_key);
            }
        });

        let mainCreative = allCreatives[0];
        allCreatives = allCreatives.slice(1);

        return (
            <React.Fragment>
                <tr>
                    <td rowSpan="3">
                        {groupStatusFlag.length ? (
                            <Tooltip placement="top" title={'Issue with: ' + groupStatusFlag.join(',')}>
                                <ExclamationCircleOutlined className="warning-icon" />
                            </Tooltip>
                        ) : (
                            ''
                        )}
                        {this.renderCreative(mainCreative, '-main')}
                    </td>
                    <td>
                        <span>Name: </span>
                        <span className="name">{group.groupName.split('||')[0]}</span>
                    </td>
                </tr>
                <tr>
                    <td>{this.renderLineItem(mainCreative)}</td>
                </tr>
                <tr>
                    <td>
                        {allCreatives.map(creative => {
                            return this.renderCreative(creative, '');
                        })}
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    render() {
        const { loadingData, total, itemsPerPage, page, banners } = this.state;

        const bannerGroupsToRender = [];
        for (let i = 0; i < banners.length && i < itemsPerPage; i++) {
            bannerGroupsToRender.push(banners[i + (page - 1) * itemsPerPage]);
        }

        return (
            <div>
                <Grid fluid key="block-container">
                    <Row key="dates-block" className="indent-date-review">
                        <Col span={3}>
                            <Text>Start date:</Text>
                            <DatePicker
                                onChange={date => this.handleDate('startDate', date)}
                                format={DATE_FORMAT}
                                placeholder="Start Date"
                                defaultValue={moment(this.state.startDate, DATE_FORMAT)}
                            />
                        </Col>

                        <Col span={3} offset={1}>
                            <Text>End date:</Text>
                            <DatePicker
                                onChange={date => this.handleDate('endDate', date)}
                                placeholder="End Date"
                                format={DATE_FORMAT}
                                defaultValue={moment(this.state.endDate, DATE_FORMAT)}
                            />
                        </Col>
                    </Row>
                    <Row key="pagination-up-block" className="indent-date-review">
                        <Pagination
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            pageSize={itemsPerPage}
                            current={page}
                            pageSizeOptions={['25', '50', '100']}
                            showTotal={total => `Total ${total} items`}
                            onChange={this.handlePage}
                            onShowSizeChange={this.handlePageSize}
                        />
                    </Row>
                    <Row>
                        <Spin size="large" spinning={loadingData}>
                            {banners.length ? (
                                <Table striped bordered hover>
                                    <tbody>
                                        {bannerGroupsToRender.map(group => {
                                            return this.creativeGroupRender(group);
                                        })}
                                    </tbody>
                                </Table>
                            ) : (
                                <Row type="flex" justify="center" align="middle">
                                    <Col span={4}>
                                        <div className="icon-card">
                                            <StopTwoTone />
                                            <span>NO DATA</span>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Spin>
                    </Row>
                    <Row key="pagination-down-block" className="indent-date-review">
                        <Pagination
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            pageSize={itemsPerPage}
                            current={page}
                            pageSizeOptions={['25', '50', '100']}
                            showTotal={total => `Total ${total} items`}
                            onChange={this.handlePage}
                            onShowSizeChange={this.handlePageSize}
                        />
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default BannerReview;
