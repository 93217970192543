export default [
    {
        name: 'Argentine Peso (ARS)',
        value: 'ARS'
    },
    {
        name: 'Australian Dollar (AUD A$)',
        value: 'AUD'
    },
    {
        name: 'Bolivian Boliviano (BOB)',
        value: 'BOB'
    },
    {
        name: 'Brazilian Real (BRL R$)',
        value: 'BRL'
    },
    {
        name: 'British Pound (GBP £)',
        value: 'GBP'
    },
    {
        name: 'Bulgarian Lev (BGN)',
        value: 'BGN'
    },
    {
        name: 'Canadian Dollar (CAD CA$)',
        value: 'CAD'
    },
    {
        name: 'Chilean Peso (CLP)',
        value: 'CLP'
    },
    {
        name: 'Chinese Yuan (CNY CN¥)',
        value: 'CNY'
    },
    {
        name: 'Colombian Peso (COP)',
        value: 'COP'
    },
    {
        name: 'Croatian Kuna (HRK)',
        value: 'HRK'
    },
    {
        name: 'Czech Koruna (CZK)',
        value: 'CZK'
    },
    {
        name: 'Danish Krone (DKK)',
        value: 'DKK'
    },
    {
        name: 'Egyptian Pound (EGP)',
        value: 'EGP'
    },
    {
        name: 'Euro (EUR €)',
        value: 'EUR'
    },
    {
        name: 'Hong Kong Dollar (HKD HK$)',
        value: 'HKD'
    },
    {
        name: 'Hungarian Forint (HUF)',
        value: 'HUF'
    },
    {
        name: 'Indian Rupee (INR ₹)',
        value: 'INR'
    },
    {
        name: 'Indonesian Rupiah (IDR)',
        value: 'IDR'
    },
    {
        name: 'Israeli New Shekel (ILS ₪)',
        value: 'ILS'
    },
    {
        name: 'Japanese Yen (JPY ¥)',
        value: 'JPY'
    },
    {
        name: 'Malaysian Ringgit (MYR)',
        value: 'MYR'
    },
    {
        name: 'Mexican Peso (MXN MX$)',
        value: 'MXN'
    },
    {
        name: 'Moroccan Dirham (MAD)',
        value: 'MAD'
    },
    {
        name: 'New Taiwan Dollar (TWD NT$)',
        value: 'TWD'
    },
    {
        name: 'New Zealand Dollar (NZD NZ$)',
        value: 'NZD'
    },
    {
        name: 'Nigerian Naira (NGN)',
        value: 'NGN'
    },
    {
        name: 'Norwegian Krone (NOK)',
        value: 'NOK'
    },
    {
        name: 'Pakistani Rupee (PKR)',
        value: 'PKR'
    },
    {
        name: 'Peruvian Sol (PEN)',
        value: 'PEN'
    },
    {
        name: 'Philippine Piso (PHP)',
        value: 'PHP'
    },
    {
        name: 'Polish Zloty (PLN)',
        value: 'PLN'
    },
    {
        name: 'Romanian Leu (RON)',
        value: 'RON'
    },
    {
        name: 'Russian Ruble (RUB)',
        value: 'RUB'
    },
    {
        name: 'Saudi Riyal (SAR)',
        value: 'SAR'
    },
    {
        name: 'Serbian Dinar (RSD)',
        value: 'RSD'
    },
    {
        name: 'Singapore Dollar (SGD)',
        value: 'SGD'
    },
    {
        name: 'South African Rand (ZAR)',
        value: 'ZAR'
    },
    {
        name: 'South Korean Won (KRW ₩)',
        value: 'KRW'
    },
    {
        name: 'Swedish Krona (SEK)',
        value: 'SEK'
    },
    {
        name: 'Swiss Franc (CHF)',
        value: 'CHF'
    },
    {
        name: 'Thai Baht (THB)',
        value: 'THB'
    },
    {
        name: 'Turkish Lira (TRY)',
        value: 'TRY'
    },
    {
        name: 'Ukrainian Hryvnia (UAH)',
        value: 'UAH'
    },
    {
        name: 'United Arab Emirates Dirham (AED)',
        value: 'AED'
    },
    {
        name: 'US Dollar (USD $)',
        value: 'USD'
    },
    {
        name: 'Vietnamese Dong (VND ₫)',
        value: 'VND'
    }
];
