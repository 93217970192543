export const RES_DESC_CAMPAIGN_AD_GROUPS = [
    'Luxury',
    'New',
    'Descriptive',
    'Affordable',
    'NonBrand Natl',
    'NonBrand Natl State'
];

export const FLOOR_PLANS = ['1 Bed', '2 Bed', '3 Bed', 'Studio', 'Penthouses', 'Townhomes', 'Pet Friendly'];

export const STUDENT_DESC_CAMPAIGN_AD_GROUPS = [
    'NonBrand State',
    'NonBrand State Sch City',
    'NonBrand State Sch State',
    'NonBrand Natl',
    'NonBrand Natl Sch City',
    'NonBrand Natl Sch State'
];

export const STUDENT_HOUSING = [
    'Apartments - School',
    'Off Campus - City',
    'Off Campus - School',
    'Student Apartments - City',
    'Student Apartments - School',
    'Student Housing - City',
    'Student Housing - School'
];

export const CARE_LEVELS = [
    'Alzheimers',
    'Assisted Living',
    'Dementia',
    'Elder Care',
    'Independent Living',
    'Memory Care',
    'Respite Care',
    'Retirement Living',
    'Senior Care',
    'Senior Community',
    'Senior Living',
    'Senior Living Apartments'
];

export const US_TIMEZONES = [
    {
        name: '(GMT-04:00) New York Time',
        value: 'America/New_York'
    },
    {
        name: '(GMT-05:00) Chicago Time',
        value: 'America/Chicago'
    },
    {
        name: '(GMT-06:00) Denver Time',
        value: 'America/Denver'
    },
    {
        name: '(GMT-07:00) Los Angeles Time',
        value: 'America/Los_Angeles'
    },
    {
        name: '(GMT-07:00) Phoenix Time',
        value: 'America/Phoenix'
    },
    {
        name: '(GMT-08:00) Anchorage Time',
        value: 'America/Anchorage'
    },
    {
        name: '(GMT-10:00) Honolulu Time',
        value: 'Pacific/Honolulu'
    }
];

export const LOCATION_TARGETING = [
    {
        name: 'Radius15',
        value: 'radius_15'
    },
    {
        name: 'Radius30',
        value: 'radius_30'
    },
    {
        name: 'Radius50',
        value: 'radius_50'
    },
    {
        name: 'DMA',
        value: 'dma'
    },
    {
        name: 'State',
        value: 'state'
    }
];

export const CAMPAIGN_TEMPLATES = [
    {
        name: 'Residential',
        value: 'res'
    },
    {
        name: 'Student Housing',
        value: 'student'
    },
    // {
    //     name: 'Active Adults',
    //     value: 'active'
    // },
    {
        name: 'Senior Living',
        value: 'srl'
    }
];

export const BASE_HEADLINES = [
    {
        label: 'Headline 1',
        name: 'headline_1'
    },
    {
        label: 'Headline 2',
        name: 'headline_2'
    },
    {
        label: 'Headline 3',
        name: 'headline_3'
    },
    {
        label: 'Headline 4',
        name: 'headline_4'
    }
];

export const DESCRIPTION_FIELDS = [
    {
        label: 'Description 1/Promo',
        name: 'description_1'
    },
    {
        label: 'Description 2',
        name: 'description_2'
    },
    {
        label: 'Description 3',
        name: 'description_3'
    },
    {
        label: 'Description 4',
        name: 'description_4'
    }
];

export const ADDITIONAL_HEADLINES = [
    { label: 'Headline 5', name: 'headline_5' },
    { label: 'Headline 6', name: 'headline_6' },
    { label: 'Headline 7', name: 'headline_7' },
    { label: 'Headline 8', name: 'headline_8' },
    { label: 'Headline 9', name: 'headline_9' },
    { label: 'Headline 10', name: 'headline_10' },
    { label: 'Headline 11', name: 'headline_11' },
    { label: 'Headline 12', name: 'headline_12' },
    { label: 'Headline 13', name: 'headline_13' },
    { label: 'Headline 14', name: 'headline_14' },
    { label: 'Headline 15', name: 'headline_15' }
];

export const RES_SITE_LINKS = [
    { label: 'Main Landing Page URL', name: 'landing_url' },
    { label: 'Floor Plans Landing Page URL', name: 'floor_plans_url' },
    { label: 'Amenities Landing Page URL', name: 'amenities_url' },
    { label: 'Photo Gallery Landing Page URL', name: 'gallery_url' },
    { label: 'Contact Page URL', name: 'contact_url' }
];

export const SRL_SITE_LINKS = [
    { label: 'Main Landing Page URL', name: 'landing_url' }, //Home page
    { label: 'Map & Directions', name: 'floor_plans_url' }, //Types of Care
    { label: 'Care Levels Landing Page URL', name: 'amenities_url' }, //Type of Care
    { label: 'Photo Gallery Landing Page URL', name: 'gallery_url' }, //Photos
    { label: 'Contact Page URL', name: 'contact_url' }, //Contact Us
    { label: 'Neighborhood Page URL', name: 'neighborhood_url' } //Neighborhood
];

export const NON_BRAND_AD_COPY = {
    '1_bed_headline_12': '1 Bed Apartments in',
    '1_bed_headline_13': '1 Bed Apartments',
    '1_bed_headline_14': '1 Bedroom Apartments For Rent',
    one_bed_headline_12: 'One Bed Apartments in',
    one_bed_headline_13: 'One Bed Apartments',
    one_bed_headline_14: 'One Bedroom Apartments For Rent',
    '2_bed_headline_12': '2 Bed Apartments in',
    '2_bed_headline_13': '2 Bed Apartments',
    '2_bed_headline_14': '2 Bedroom Apartments For Rent',
    two_bed_headline_12: 'Two Bed Apartments in',
    two_bed_headline_13: 'Two Bed Apartments',
    two_bed_headline_14: 'Two Bedroom Apartments For Rent',
    '3_bed_headline_12': '3 Bed Apartments in',
    '3_bed_headline_13': '3 Bed Apartments',
    '3_bed_headline_14': '3 Bedroom Apartments For Rent',
    three_bed_headline_12: 'Three Bed Apartments in',
    three_bed_headline_13: 'Three Bed Apartments',
    three_bed_headline_14: 'Three Bedroom Apartments For Rent',
    '4_bed_headline_12': '4 Bed Apartments in',
    '4_bed_headline_13': '4 Bed Apartments',
    '4_bed_headline_14': '4 Bedroom Apartments For Rent',
    four_bed_headline_12: 'Four Bed Apartments in',
    four_bed_headline_13: 'Four Bed Apartments',
    four_bed_headline_14: 'Four Bedroom Apartments For Rent',
    studio_headline_12: 'Studio Apartments in',
    studio_headline_13: 'Studio Apartments',
    studio_headline_14: 'Studio Apartments For Rent',
    pet_friendly_headline_12: 'Pet Friendly Apartments in',
    pet_friendly_headline_13: 'Pet Friendly Apartments',
    pet_friendly_headline_14: 'Pet Friendly Apartments For Rent',
    penthouses_headline_12: 'Penthouse Apartments in',
    penthouses_headline_13: 'Penthouse Apartments',
    penthouses_headline_14: 'Penthouse Apartments For Rent',
    townhomes_headline_12: 'Townhome Apartments in',
    townhomes_headline_13: 'Townhome Apartments',
    townhomes_headline_14: 'Townhome Apartments For Rent'
};

export const DESCRIPTIVE_AD_COPY = {
    luxury_headline_12: 'Luxury Apartments in',
    luxury_headline_13: 'Luxury Apartments',
    new_headline_12: 'New Apartments in',
    new_headline_13: 'New Apartments',
    affordable_headline_12: 'Affordable Apartments in',
    affordable_headline_13: 'Affordable Apartments',
    nonbrand_natl_headline_12: 'Apartments in',
    nonbrand_natl_headline_13: 'Apartments',
    nonbrand_natl_state_headline_12: 'Apartments in',
    nonbrand_natl_state_headline_13: 'Apartments'
};

export const CARE_LEVEL_COPY = {
    alzheimers_headline_12: "Alzheimer's Care Near You",
    alzheimers_headline_13: "Alzheimer's Care in",
    alzheimers_headline_14: "Compassionate Alzheimer's Care",
    assisted_living_headline_12: 'Assisted Living Near You',
    assisted_living_headline_13: 'Assisted Living in',
    assisted_living_headline_14: 'Compassionate Assisted Living',
    dementia_headline_12: 'Dementia Care Near You',
    dementia_headline_13: 'Dementia Care in',
    dementia_headline_14: 'Compassionate Dementia Care',
    elder_care_headline_12: 'Elder Care Near You',
    elder_care_headline_13: 'Elder Care in',
    elder_care_headline_14: 'Compassionate Elder Care',
    independent_living_headline_12: 'Independent Living Near You',
    independent_living_headline_13: 'Independent Living in',
    independent_living_headline_14: 'Vibrant Independent Living',
    memory_care_headline_12: 'Memory Care Near You',
    memory_care_headline_13: 'Memory Care in',
    memory_care_headline_14: 'Compassionate Memory Care',
    respite_care_headline_12: 'Respite Care Near You',
    respite_care_headline_13: 'Respite Care in',
    respite_care_headline_14: 'Compassionate Respite Care',
    retirement_living_headline_12: 'Retirement Living Near You',
    retirement_living_headline_13: 'Retirement Living in',
    retirement_living_headline_14: 'Vibrant Retirement Living',
    senior_care_headline_12: 'Senior Care Near You',
    senior_care_headline_13: 'Senior Care in',
    senior_care_headline_14: 'Compassionate Senior Care',
    senior_community_headline_12: 'Senior Community Near You',
    senior_community_headline_13: 'Senior Community in',
    senior_community_headline_14: 'Vibrant Senior Living',
    senior_living_headline_12: 'Senior Living Near You',
    senior_living_headline_13: 'Exceptional Senior Living',
    senior_living_headline_14: 'Vibrant Senior Living',
    senior_living_apartments_headline_12: 'Senior Living Apts Near You',
    senior_living_apartments_headline_13: 'Senior Apartments in',
    senior_living_apartments_headline_14: 'Vibrant Senior Apartments'
};

export const STUDENT_COPY = {
    apts_school_headline_12: '{{school_upper}} Apartments', //prefix School
    apts_school_headline_13: '{{school_ab}} Apartments', //suffix School AB
    apts_school_headline_14: 'Apartments Near {{school_ab}}', //prefix School AB
    off_campus_city_headline_12: '{{city_upper}} Off Campus Apartments', //prefix City
    off_campus_city_headline_13: 'Off Campus Apartments {{city_upper}}', //prefix City
    off_campus_city_headline_14: 'Off Campus Apartments in {{city_upper}}', //suffix City
    off_campus_school_headline_12: '{{school_upper}} Off Campus Apartments', //prefix School
    off_campus_school_headline_13: '{{school_ab}} Off Campus Apartments', //Prefix School AB
    off_campus_school_headline_14: 'Off Campus Apartments Near {{school_ab}}', //suffix School AB
    student_apts_city_headline_12: '{{city_upper}} Student Apartments', //prefix City
    student_apts_city_headline_13: 'Student Apartments {{city_upper}}', //prefix City
    student_apts_city_headline_14: 'Student Apts in {{city_upper}}', //suffix City
    student_apts_school_headline_12: '{{school_upper}} Student Apts', //prefix School
    student_apts_school_headline_13: '{{school_ab}} Student Apartments', //prefix School AB
    student_apts_school_headline_14: 'Student Apartments Near {{school_ab}}', //suffix School AB
    student_housing_city_headline_12: '{{city_upper}} Student Housing', //prefix City
    student_housing_city_headline_13: 'Student Housing {{city_upper}}', //suffix City
    student_housing_city_headline_14: '{{school_ab}} Student Housing {{city_upper}}', //prefix School AB and suffix City
    student_housing_school_headline_12: '{{school_upper}} Student Housing', //prefix School
    student_housing_school_headline_13: 'Student Housing {{school_upper}}', //prefix School
    student_housing_school_headline_14: '{{school_ab}} Student Housing {{city_upper}}' //prefix School AB and suffix City
};
