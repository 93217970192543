import React, { Component } from 'react';
import { Grid, Row, Col, Button, FormControl, ProgressBar } from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import axios from 'axios';
import NotificationSystem from 'react-notification-system';
import { style } from '../../variables/Variables.jsx';

import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';
import moment from 'moment';
import '../../assets/css/bar.css';

const PATH_BUDGETS_TRANSFER = 'budgettransfer';
const PATH_BAR_GENERATE = 'generatebar';
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
//const DEFAULT_GOOGLE_SHEET_ID = '1uTJXtajYkh7w2AHWClGCAlxPQ87WfSEo2LyP12br_2A';
const DEFAULT_GOOGLE_SHEET_ID = '1hpkAAuLRV5si2ZNLjKKfv-Qbl-WiIZziMcdcZDuaAMs';
const DEFAULT_ODOO_SHEET_ID = '1QBzULVy9ryalh0FDOXCtMSzIVlFpoQwhl1P9I7vmCWY';
const DEFAULT_SHEET_NAME = 'Budgets2';
const DEFAULT_ODOO_SHEET_NAME = 'Budgets2';
const BAR_STATUS_VARIABLE = 'bar_status_'
const VAR_STATUS_MAPING = {
    'bar_status_google':'isLoadingOdooGADS',
    'bar_status_fb':'isLoadingOdooFB',
    'bar_status_budgettransfer':'isLoadingOdoo',
}

class Bar extends Component {
    constructor(props, context) {
        super(props, context);
        this.generateBarData = this.generateBarData.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.sendRequestToBudgetTransfer = this.sendRequestToBudgetTransfer.bind(this);
        this.sendProcessingRequest = this.sendProcessingRequest.bind(this);
        this.getBarStatuses = this.getBarStatuses.bind(this);
        this.resetBarStatuses = this.resetBarStatuses.bind(this);
        setInterval(this.getBarStatuses, 7000)
        this.state = {
            isLoading: false,
            isLoadingANX: false,
            isLoadingFB: false,
            isLoadingGADS: false,
            isLoadingOdoo: false,
            isLoadingOdooANX: false,
            isLoadingOdooFB: false,
            isLoadingOdooGADS: false,
            startDate: moment().startOf('month').format(DEFAULT_DATE_FORMAT),
            endDate: moment().endOf('month').format(DEFAULT_DATE_FORMAT),
            sheetId: DEFAULT_GOOGLE_SHEET_ID,
            sheetName: DEFAULT_SHEET_NAME,
            sheetOdooId: DEFAULT_ODOO_SHEET_ID,
            sheetOdooName: DEFAULT_ODOO_SHEET_NAME
        };
        this.getBarStatuses()
    }

    sendRequestToBudgetTransfer(crmType) {
        const statusButton = crmType=='odoo' ? 'isLoadingOdoo' : 'isLoading'
        this.setState({ [statusButton]: true }, () => {
            this.sendProcessingRequest(crmType)
        });
    }


    generateBarData(flagname, platform, crmType='podio') {
        this.setState({ [flagname]: true }, () => {
            this.sendProcessingRequest(crmType, platform).then(() => {
                if (crmType!=='odoo') {this.setState({ [flagname]: false })};
            });
        });
    }

    handleDateChange(entity, variable) {
        console.log(entity);
        if (moment(entity).isValid()) {
            this.setState({ [variable]: moment(entity).format(DEFAULT_DATE_FORMAT) });
        }
    }

    getClassNames() {
        let classes = 'content reporting-component';

        if (this.state.loadingData) {
            classes += ' clx-loading';
        }

        return classes;
    }

    getNotification(level, text) {
        return {
            title: <span data-notify="icon" className={level === 'success' ? 'pe-7s-server' : 'pe-7s-shield'} />,
            message: <div dangerouslySetInnerHTML={{ __html: text }} />,
            level: level,
            position: 'tr',
            autoDismiss: 10
        };
    }
    sendProcessingRequest(crmType, platform) {
        let { startDate, endDate, sheetId, sheetName, sheetOdooId, sheetOdooName } = this.state;
        let path = platform ? PATH_BAR_GENERATE : PATH_BUDGETS_TRANSFER;
        return axios
            .get(`${API_BASE_URL}${path}${crmType}`, {
                params: JSON.stringify({
                    startDate,
                    endDate,
                    sheetId: crmType=='odoo'?sheetOdooId:sheetId,
                    sheetName: platform ? platform :( crmType=='odoo'?sheetOdooName:sheetName),
                    platform,
                    crmType
                }),
                headers: authHeader()
            },{timeout:20*1000*60})
            .then(response => {
                // TODO notification with notification system
                this.refs.notificationSystem.addNotification(this.getNotification('success', response.data.message));
            })
            .catch(error => {
                this.refs.notificationSystem.addNotification(this.getNotification('error', error));
                console.error('Error downloading reporting data!', error);
            });
    }

    getBarStatuses() {
        let { isLoadingOdoo, isLoadingOdooFB, isLoadingOdooGADS} = this.state;
        return axios
            .get(`${API_BASE_URL}getgeneratebarodoostatus`, {
                headers: authHeader()
            })
            .then(response => {
                let prepearedStatus = {
                    'isLoadingOdooGADS':false,
                    'isLoadingOdooFB':false,
                    'isLoadingOdoo':false,
                }
                if (response && response.data){
                    Object.keys(response.data).forEach(key=>{
                        let value = response.data[key]
                        prepearedStatus[VAR_STATUS_MAPING[key]]= value=='false' ? false:value
                    })
                    this.setState(prepearedStatus)
                }
            })
            .catch(error => {
                // this.refs.notificationSystem.addNotification(this.getNotification('error', error));
                console.error('Error getting statuses!', error);
            });
    }

    resetBarStatuses() {
        return axios
            .get(`${API_BASE_URL}cleangeneratebarodoostatus`, {
                headers: authHeader()
            })
    }

    render() {
        return (
            <div>
                <NotificationSystem ref="notificationSystem" style={style} />
                <Grid fluid width={{width:'80%'}}>
                    <Row>
                        <Col mdOffset={1}>
                            <h3>Time range to get budget</h3>
                        </Col>
                        <Col md={4} mdOffset={1}>
                            <label htmlFor="startDate">
                                Start Date
                                <DayPickerInput
                                    inputProps={{ className: 'form-control' }}
                                    id="startDate"
                                    value={this.state.startDate}
                                    onDayChange={(val, instance) => {
                                        this.handleDateChange(val, 'startDate');
                                    }}
                                />
                            </label>
                        </Col><Col md={4}>
                            <label htmlFor="endDate">
                                End Date
                                <DayPickerInput
                                    inputProps={{ className: 'form-control' }}
                                    id="endDate"
                                    value={this.state.endDate}
                                    onDayChange={val => {
                                        this.handleDateChange(val, 'endDate');
                                    }}
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row className = "paltform-section">
                        <Col md={10}  mdOffset={1}>
                            <Row>
                                <Col>
                                    <h3>Odoo B<span onClick = {this.resetBarStatuses}>A</span>R</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <h4>Google sheet Odop ID</h4>

                                    <FormControl
                                        placeholder="sheetId"
                                        aria-label="sheetId"
                                        aria-describedby="basic-addon1"
                                        defaultValue={this.state.sheetOdooId}
                                        onChange={val => this.setState({ sheetOdooId: val.target.value })}
                                    />
                                </Col>
                                <Col md={4}>
                                    <h4>Google sheet name</h4>
                                    <FormControl
                                        placeholder="sheetName"
                                        aria-label="sheetName"
                                        aria-describedby="basic-addon1"
                                        defaultValue={this.state.sheetOdooName}
                                        onChange={val =>this.setState({ sheetOdooName: val.target.value })}
                                    />
                                </Col>
                            </Row>
                            <Row className="button-container">
                                <Button
                                    className="button-bar"
                                    variant="primary"
                                    disabled={this.state.isLoadingOdoo!==false}
                                    onClick={() => {this.sendRequestToBudgetTransfer('odoo')}}
                                    ref={this}
                                >
                                    {this.state.isLoadingOdoo ? (
                                        <div>'Working…'<ProgressBar now={this.state.isLoadingOdoo} /></div>
                                    ) : (
                                        <div>
                                            Transfer all budgets
                                            <br />
                                            to the <strong>{this.state.sheetOdooName}</strong> sheet
                                        </div>
                                    )}
                                </Button>
                                <Button
                                    className="button-bar"
                                    variant="primary"
                                    disabled={this.state.isLoadingOdooFB!==false}
                                    onClick={() => {
                                        this.generateBarData('isLoadingOdooFB', 'fb','odoo');
                                    }}
                                    ref={this}
                                >
                                    {this.state.isLoadingOdooFB
                                        ? <div>'Working…'<ProgressBar now={this.state.isLoadingOdooFB} /></div>
                                        : <div>
                                            Generate FB<br />(will be saved to <strong>fb</strong> sheet)
                                        </div>}
                                </Button>
                                <Button
                                    className="button-bar"
                                    variant="primary"
                                    disabled={this.state.isLoadingOdooGADS!==false}
                                    onClick={() => {
                                        this.generateBarData('isLoadingOdooGADS', 'google','odoo');
                                    }}
                                    ref={this}
                                >
                                    {this.state.isLoadingOdooGADS
                                        ? <div>'Working…'<ProgressBar now={this.state.isLoadingOdooGADS} /></div> 
                                        : <div>
                                            Generate Google ADS<br />(will be saved to <strong>gads</strong> sheet)
                                        </div>}
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Bar;
