import * as React from 'react';
import { Row, Col } from 'antd';
import DayTimePicker from './DayTimePicker';

const closedTitle = 'Closed';

export class Timetable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || {},
            viewMode: props.viewMode || false
        };
    }

    render() {
        const { value, viewMode } = this.state;

        return (
            <div className="timetable">
                <Row>
                    <Col span={8}>Monday - Friday</Col>
                    <Col span={8}>Saturday</Col>
                    <Col span={8}>Sunday</Col>
                </Row>
                <Row>
                    <Col span={8}>
                        {viewMode ? (
                            <span>{value.work_b_mon || closedTitle}</span>
                        ) : (
                            <DayTimePicker
                                id="work_b_mon"
                                label="Open"
                                onChangeDayTimePicker={this.onChangeDayTimePicker}
                                value={value !== undefined ? value.work_b_mon : undefined}
                                defaultValue="10:00am"
                                allowEmpty={true}
                            />
                        )}
                    </Col>
                    <Col span={8}>
                        {viewMode ? (
                            <span>{value.work_b_sat || closedTitle}</span>
                        ) : (
                            <DayTimePicker
                                id="work_b_sat"
                                label="Open"
                                onChangeDayTimePicker={this.onChangeDayTimePicker}
                                value={value !== undefined ? value.work_b_sat : undefined}
                                defaultValue="11:00am"
                                allowEmpty={true}
                            />
                        )}
                    </Col>
                    <Col span={8}>
                        {viewMode ? (
                            <span>{value.work_b_sun || closedTitle}</span>
                        ) : (
                            <DayTimePicker
                                id="work_b_sun"
                                label="Open"
                                onChangeDayTimePicker={this.onChangeDayTimePicker}
                                value={value !== undefined ? value.work_b_sun : undefined}
                                defaultValue=""
                                allowEmpty={true}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        {viewMode ? (
                            <span>{value.work_e_mon}</span>
                        ) : (
                            <DayTimePicker
                                id="work_e_mon"
                                label="Close"
                                onChangeDayTimePicker={this.onChangeDayTimePicker}
                                value={value !== undefined ? value.work_e_mon : undefined}
                                defaultValue="05:00pm"
                                allowEmpty={true}
                            />
                        )}
                    </Col>
                    <Col span={8}>
                        {viewMode ? (
                            <span>{value.work_e_sat}</span>
                        ) : (
                            <DayTimePicker
                                id="work_e_sat"
                                label="Close"
                                onChangeDayTimePicker={this.onChangeDayTimePicker}
                                value={value !== undefined ? value.work_e_sat : undefined}
                                defaultValue="05:00pm"
                                allowEmpty={true}
                            />
                        )}
                    </Col>
                    <Col span={8}>
                        {viewMode ? (
                            <span>{value.work_e_sun}</span>
                        ) : (
                            <DayTimePicker
                                id="work_e_sun"
                                label="Close"
                                onChangeDayTimePicker={this.onChangeDayTimePicker}
                                value={value !== undefined ? value.work_e_sun : undefined}
                                defaultValue=""
                                allowEmpty={true}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        );
    }

    onChangeDayTimePicker = (fieldName, fieldValue) => {
        const { value } = this.state;
        value[fieldName] = fieldValue;

        if (fieldValue === '') {
            const foundPairs = fieldName.split('_');
            const secondFieldName = `${foundPairs[0]}_${foundPairs[1] === 'b' ? 'e' : 'b'}_${foundPairs[2]}`;
            value[secondFieldName] = '';
        }

        this.setState({ value });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };
}

export default Timetable;
