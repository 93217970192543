import React, { Component } from 'react';

class DataTableRow extends Component {
    onLinkClick(e) {
        e.stopPropagation();
    }

    /**
     * Conditionally renders an action button based on props
     */
    getActionButtonCell() {
        if (this.props.appendActionBtn) {
            let clickHandler = () => {};
            if (typeof(this.props.actionBtnCallback)==='function') {
                clickHandler = this.props.actionBtnCallback;
            }
            if (typeof (this.props.actionBtnRawHtml) === 'string') {
                return (
                    <td
                        dangerouslySetInnerHTML={{__html: this.props.actionBtnRawHtml}}
                        onClick={() => {
                            clickHandler(this.props.rowIndex);
                        }}
                    >
                    </td>
                );
            }
            else if (typeof(this.props.actionBtnText)==='string') {
                return (
                    <td
                        onClick={() => {
                            clickHandler(this.props.rowIndex);
                        }}
                    >
                        <button>
                            {this.props.actionBtnText}
                        </button>
                    </td>
                );
            }
        }
        return null;
    }

    getContent(fieldValue, index) {
        if (this.props.getCellContent) {
            return this.props.getCellContent(this.props.data, this.props.fieldsMap, fieldValue, index);
        }

        return fieldValue;
    }

    getShouldSuppressCell(index, key) {
        if (this.props.isFieldHidden && this.props.isFieldHidden(this.props.fieldsMap, index, this.props.showingFields)){
            return true;
        }
        if (this.props.filterByFieldsMap && typeof (this.props.fieldsMap) === 'object') {
            // Suppress if NOT found in array
            return this.props.fieldsMap.map((fieldMap) => fieldMap.field).indexOf(key) === -1;
        }
        return false;
    }

    getCell(fieldValue, index, key) {
        if (this.getShouldSuppressCell(index, key)) {
            return;
        }

        return (
            <td
                key={index}
                onClick={() => {
                    this.props.onClick(fieldValue, this.props.rowIndex);
                }}
            >
                {this.getContent(fieldValue, index)}
            </td>
        );
    }

    render() {
        return (
            <tr key={this.props.rowIndex}>
                {Object.keys(this.props.data).map((key, index) => {
                    const value = this.props.data[key];
                    return this.getCell(value, index, key);
                })}
                {this.getActionButtonCell()}
            </tr>
        );
    }
}

export default DataTableRow;
