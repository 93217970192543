import React, { Component } from 'react';
import { NavItem, Nav } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { UserService } from '../../services/user.service';

class HeaderLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    onLogout = () => {
        UserService.logout();
        this.setState({
            loggedOut: true
        });
    };

    render() {
        if (this.state.loggedOut) {
            return <Redirect to={{ pathname: '/login' }} />;
        }

        return (
            <div>
                <Nav>
                    <NavItem eventKey={1} href="#">
                        <i className="fa fa-dashboard" />
                        <p className="hidden-lg hidden-md">Dashboard</p>
                    </NavItem>
                </Nav>
                <Nav pullRight>
                    {/* <NavItem eventKey={1} href="#">
                        Account
                    </NavItem> */}
                    <NavItem eventKey={3} onClick={this.onLogout} href="#">
                        Log out
                    </NavItem>
                </Nav>
            </div>
        );
    }
}

export default HeaderLinks;
