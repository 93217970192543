import React, { Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BASE_HEADLINES, ADDITIONAL_HEADLINES } from '../FormConstants';
import { Divider, Input, Collapse, Typography } from 'antd';

const { Panel } = Collapse;
const { Text } = Typography;

function Headlines(props) {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const parsedAddress = props.parsedAddress;
    const locationName = getFieldValue('account_name');

    const headlineItems = fields => {
        return fields.map((item, i) => {
            const isDisabled =
                (getFieldValue('floor_plans') && getFieldValue('floor_plans').length > 0) ||
                (getFieldValue('descriptive_ad_groups') && getFieldValue('descriptive_ad_groups').length > 0) ||
                (getFieldValue('neighborhood') && getFieldValue('neighborhood').length > 0)
                    ? true
                    : false;
            const isReservedHeadline = item.name.includes('12') || item.name.includes('13') || item.name.includes('14');
            const placeHolder = isReservedHeadline && isDisabled ? 'This is set in another Ad Group' : item.placeholder;

            return (
                <Form.Item label={item.label} key={item.name} extra={item.extra}>
                    {getFieldDecorator(`${item.name}`, {
                        rules: [
                            {
                                max: 30
                            }
                        ]
                    })(
                        <Input
                            disabled={isDisabled > 0 && isReservedHeadline}
                            placeholder={placeHolder}
                            suffix={`${getFieldValue(item.name) ? getFieldValue(item.name).length : '0'}/30`}
                        />
                    )}
                </Form.Item>
            );
        });
    };

    return (
        <Fragment>
            {headlineItems(BASE_HEADLINES)}
            <Collapse bordered={false} ghost>
                <Panel header="Additional Headlines">{headlineItems(ADDITIONAL_HEADLINES)}</Panel>
            </Collapse>
        </Fragment>
    );
}

export default Headlines;
