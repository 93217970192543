// @ts-check
import React from 'react';
import { DeleteOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Spin, Popconfirm, notification, Alert, Row, Col, Input } from 'antd';
import { UserRoleSelector } from './UserRoleSelector';
import { isEqual } from 'lodash';

export class UserEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isProcessing: false,
            password: ''
        };
    }
    get title() {
        if (this.props.userToEdit) {
            return `Edit "${this.props.userToEdit.first_name} ${this.props.userToEdit.last_name}"`;
        }
    }
    get shouldShow() {
        return this.props.show;
    }
    get showDeleteBtn() {
        return this.props.user && this.props.user.role.canDeleteUsers;
    }
    get showResetPasswordBtn() {
        return this.props.user && this.props.user.role.canResetPasswords;
    }
    get isProcessing() {
        return this.state.isProcessing || this.props.isProcessing;
    }
    get editorIsLowerThanToEdit() {
        return this.props.userToEdit.role.id > this.props.user.role.id;
    }
    get editorIsUserBeingEdited() {
        return this.props.userToEdit.id === this.props.user.id;
    }
    set isProcessing(isProcessingBool) {
        this.setState({
            isProcessing: isProcessingBool
        });
    }
    handleApiActionResponse(response) {
        this.isProcessing = false;
        if (response.success) {
            this.props.closeCallback();
            notification.success({
                message: 'Success!',
                description: response.msg
            });
        } else {
            notification.error({
                message: 'ERROR!',
                description: response.msg
            });
        }
    }
    handleUserEdit(key, value) {
        const user = this.props.userToEdit;
        user[key] = value;
        this.setState({
            userToEdit: user
        });
    }
    handleUserEditRole(updatedRoleId) {
        const user = this.props.userToEdit;
        user.role.id = updatedRoleId;
        this.setState({
            userToEdit: user
        });
    }
    validateUserObj(userObj) {
        let errors = 0;
        let msg = '';
        if (!errors && !userObj.first_name.length) {
            errors++;
            msg = 'Missing first name';
        } else if (!errors && userObj.last_name.length < 1) {
            errors++;
            msg = 'Missing last name';
        } else if (!errors && !/.+@.+/.test(userObj.username)) {
            errors++;
            msg = 'Invalid email';
        } else if (!errors && this.state.password.length < 5) {
            errors++;
            msg = 'Please make sure you have entered a valid passsword, > 5';
        }
        return {
            validated: errors < 1,
            errorMsg: msg
        };
    }
    render() {
        return (
            <React.Fragment>
                {this.props.userToEdit && (
                    <Modal
                        title={this.title}
                        open={this.shouldShow}
                        onCancel={this.props.closeCallback}
                        onOk={async () => {
                            // New user creation
                            if (this.props.isNewUser) {
                                const { validated, errorMsg } = this.validateUserObj(this.props.userToEdit);
                                if (!validated) {
                                    notification.warning({
                                        message: 'Failed Validation!',
                                        description: errorMsg
                                    });
                                    return false;
                                }

                                this.isProcessing = true;
                                const res = await this.props.editCallback(
                                    this.props.userToEdit,
                                    'create',
                                    this.state.password
                                );
                                this.handleApiActionResponse(res);
                            }

                            // User Edit
                            else {
                                if (!isEqual(this.props.userToEdit, this.props.userToEditOriginal)) {
                                    this.isProcessing = true;
                                    const res = await this.props.editCallback(
                                        this.props.userToEdit,
                                        'update',
                                        this.props.userToEditOriginal
                                    );
                                    this.handleApiActionResponse(res);
                                } else {
                                    this.props.closeCallback();
                                }
                            }
                        }}
                        okText="Save"
                    >
                        <Spin tip="Processing" spinning={this.isProcessing}>
                            <h4>User: {this.props.userToEdit.username}</h4>
                            <Form>
                                <Row gutter={20}>
                                    <Col span={12}>
                                        <Form.Item label="First Name">
                                            <Input
                                                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Joe"
                                                value={this.props.userToEdit.first_name}
                                                onChange={evt => {
                                                    this.handleUserEdit('first_name', evt.target.value);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Last Name">
                                            <Input
                                                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Smith"
                                                value={this.props.userToEdit.last_name}
                                                onChange={evt => {
                                                    this.handleUserEdit('last_name', evt.target.value);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="email">
                                            <Input
                                                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="user@example.com"
                                                type="email"
                                                value={this.props.userToEdit.username}
                                                onChange={evt => {
                                                    this.handleUserEdit('username', evt.target.value);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {this.props.isNewUser && (
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <Form.Item label="Password">
                                                <Input
                                                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    placeholder="Fjc(492cNCLf592*c"
                                                    type="password"
                                                    value={this.state.password}
                                                    onChange={evt => {
                                                        this.setState({
                                                            password: evt.target.value
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                                <Form.Item label="Assigned Role">
                                    <UserRoleSelector
                                        roleSchema={this.props.roleSchema}
                                        currRoleId={this.props.userToEdit.role.id}
                                        maxRoleId={this.props.user.role.id}
                                        disabled={this.editorIsLowerThanToEdit || this.editorIsUserBeingEdited}
                                        changeCb={value => {
                                            const roleId = parseInt(value, 10);
                                            this.handleUserEditRole(roleId);
                                        }}
                                    />
                                    {this.editorIsLowerThanToEdit && (
                                        <Alert
                                            message="Role Editor Disabled"
                                            description="You can't edit the role of someone at a higher level"
                                            type="info"
                                            showIcon
                                        />
                                    )}
                                    {this.editorIsUserBeingEdited && (
                                        <Alert
                                            message="Role Editor Disabled"
                                            description="You can't edit your own role"
                                            type="warning"
                                            showIcon
                                        />
                                    )}
                                </Form.Item>
                            </Form>
                            <Row gutter={16}>
                                <Col span={8}>
                                    {this.showDeleteBtn && !this.props.isNewUser && (
                                        <Popconfirm
                                            title="Are you sure you want to delete the user? This is NOT reversable."
                                            okText="Delete"
                                            onConfirm={async () => {
                                                this.isProcessing = true;
                                                const res = await this.props.editCallback(
                                                    this.props.userToEdit,
                                                    'delete'
                                                );
                                                this.handleApiActionResponse(res);
                                            }}
                                        >
                                            <Button icon={<DeleteOutlined />} type="danger">
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                    )}
                                </Col>
                                <Col span={8}>
                                    {this.showResetPasswordBtn && !this.props.isNewUser && (
                                        <Button
                                            style={{
                                                backgroundColor: '#A57D2D',
                                                color: 'white'
                                            }}
                                            onClick={async () => {
                                                this.isProcessing = true;
                                                const res = await this.props.editCallback(
                                                    this.props.userToEdit,
                                                    'resetPass'
                                                );
                                                this.handleApiActionResponse(res);
                                            }}
                                        >
                                            Reset Password
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Spin>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
