import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import ReactGA from 'react-ga';

import { Grid, Row, Col, Table } from 'react-bootstrap';
import Card from '../../components/Card/Card.jsx';
import Select from 'react-select';
import PlatformService from '../Reporting/services/platform.service';

import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';

import { FileAddOutlined, FolderAddOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
    Alert,
    Button,
    Checkbox,
    Collapse,
    Input,
    message,
    Tag,
    Tooltip,
    Upload,
    Typography,
} from 'antd';
import 'antd/dist/antd.css';
import './banner-upload.css';

const Dragger = Upload.Dragger;
const Panel = Collapse.Panel;
const { Text } = Typography;

const urlRegex = new RegExp(
    '^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
    'i'
);

class BannerUpload extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedAccount: null,
            selectedCampaigns: null,
            fileList: [],
            addToFolder: true,
            folderName: '',
            deletePrevious: true,
            unassignPrevious: true,
            uploading: false,
            loadingData: true,
            correctAccount: false,
            correctCampaigns: false,
            correctCreatives: false,
            correctUtmTags: false,
            reallyDelete: false,
            editingCampaign: null
        };
    }

    getAccounts() {
        return axios
            .get(`${API_BASE_URL}anx/accounts`, {
                headers: authHeader()
            })
            .then(response => {
                const accounts = response.data;

                this.setState({
                    accounts: accounts
                });
            })
            .catch(error => {
                console.error('Error getting list of accounts!', error);
            });
    }

    getCampaigns(accountId) {
        return axios
            .get(`${API_BASE_URL}anx/campaigns/${accountId}?fromAPI=true`, {
                headers: authHeader()
            })
            .then(response => {
                const campaigns = response.data;

                this.setState({
                    campaigns: campaigns
                });
            })
            .catch(error => {
                console.error('Error getting list of campaigns!', error);
            });
    }

    editCampaignURL(advertiserId, campaignId, newURL) {
        this.setState(state => {
            let editingCampaign = null;
            const selectedCampaigns = state.selectedCampaigns.map(selectedCampaign => {
                if (selectedCampaign.id === campaignId) {
                    selectedCampaign.click_url = newURL;
                    editingCampaign = campaignId;
                }
                return selectedCampaign;
            });

            return {
                selectedCampaigns,
                editingCampaign
            };
        });

        axios({
            method: 'put',
            url: `${API_BASE_URL}anx/accounts/${advertiserId}/campaigns/${campaignId}`,
            headers: authHeader(),
            data: {
                click_url: newURL
            }
        })
            .then(response => {
                this.setState({
                    editingCampaign: null
                });
            })
            .catch(error => {
                console.error('Error editing campaign!', error);
            });
    }

    handleAccountChange = selectedAccount => {
        this.setState({ selectedAccount });
        this.getCampaigns(selectedAccount.id);
        this.setState({ selectedCampaigns: null });
    };

    handleCampaignChange = selectedCampaigns => {
        this.setState({ selectedCampaigns }, () => {
            if (this.state.selectedCampaigns && this.state.selectedCampaigns.length === this.state.campaigns.length) {
                // console.log('all campaigns selected');
            }
        });
    };

    handleFolderToggle = e => {
        this.setState({
            addToFolder: e.target.checked
        });
    };

    getClassNames() {
        let classes = 'content reporting-component';

        if (this.state.loadingData) {
            classes += ' clx-loading';
        }

        return classes;
    }

    handleUpload = () => {
        const {
            fileList,
            selectedAccount,
            selectedCampaigns,
            folderName,
            addToFolder,
            unassignPrevious,
            deletePrevious,
            customUrl
        } = this.state;
        const formData = new FormData();

        fileList.forEach(file => {
            formData.append('files[]', file);
        });

        formData.append('accountId', selectedAccount.id);
        formData.append('selectedCampaigns', JSON.stringify(selectedCampaigns));

        if (addToFolder) {
            formData.append('folderName', folderName + ' ' + moment().format('MM-DD-YY'));
        }

        if (customUrl) {
            formData.append('customUrl', customUrl);
        }

        formData.append('unassignPrevious', JSON.stringify(unassignPrevious));
        formData.append('deletePrevious', JSON.stringify(deletePrevious));

        this.setState({
            uploading: true
        });

        axios({
            method: 'post',
            url: `${API_BASE_URL}anx/uploadcreative`,
            headers: authHeader(),
            processData: false,
            data: formData
        })
            .then(response => {
                if (!response || response.data !== 'OK') {
                    throw Error(response.data);
                }
                message.success(
                    `Successfully uploaded ${this.state.fileList.length} banners to ${this.state.selectedAccount.name}.`
                );

                ReactGA.event({
                    category: 'AdOps',
                    action: 'Upload Banners',
                    label: `Uploaded ${fileList.length} banners to ${selectedCampaigns.length} campaigns for account ${selectedAccount.name}`,
                    value: selectedCampaigns.length
                });

                this.setState({
                    fileList: [],
                    uploading: false,
                    folderName: '',
                    correctAccount: false,
                    correctCampaigns: false,
                    correctCreatives: false,
                    correctUtmTags: false,
                    reallyDelete: false
                });
            })
            .catch(error => {
                this.setState({
                    uploading: false
                });

                message.error('Upload failed.');
                console.error('Error getting list of campaigns!', error);
            });
    };

    componentDidMount() {
        this.getAccounts().then(result => {
            this.setState({
                loadingData: false
            });
        });
    }

    render() {
        const { selectedAccount, selectedCampaigns, uploading } = this.state;

        const draggerProps = {
            name: 'file',
            accept: 'image/*,application/zip',
            action: `${API_BASE_URL}anx/uploadcreative`,
            multiple: true,
            fileList: this.state.fileList,
            onProgress: (step, file) => {
                console.log('onProgress', Math.round(step.percent), file.name);
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                if (!this.state.folderName) {
                    let folderName = file.name.substring(0, file.name.lastIndexOf(' ')).trim();
                    this.setState({ folderName: folderName });
                }
                return false;
            }
        };

        const isUrlValid = urlRegex.test(this.state.customUrl);

        return (
            <div className={this.getClassNames()}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Alert
                                className="message-alert"
                                message="Note: The data in the Advertiser dropdown is currently delayed by one day."
                                type="info"
                                showIcon
                            />
                            <br />
                            <Card
                                title={'Xandr(AppNexus) Banner Upload'}
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Grid fluid>
                                        <div id="form">
                                            <label className="control-label">Advertiser</label>
                                            <Select
                                                value={selectedAccount}
                                                getOptionLabel={({ name }) => name}
                                                getOptionValue={({ id }) => id}
                                                onChange={this.handleAccountChange}
                                                options={this.state.accounts}
                                            />
                                            <br />
                                            <label className="control-label">Campaign/Line Item</label>
                                            <Select
                                                isDisabled={selectedAccount === null}
                                                value={selectedCampaigns}
                                                getOptionLabel={({ name }) => name}
                                                getOptionValue={({ id }) => id}
                                                onChange={this.handleCampaignChange}
                                                options={this.state.campaigns}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                closeMenuOnScroll={true}
                                            />
                                            <br />
                                            {selectedCampaigns && selectedCampaigns.length ? (
                                                <Table striped hover responsive>
                                                    <thead>
                                                        <tr>
                                                            {['Campaign/Line Item', 'Landing Page URL', 'Actions'].map(
                                                                (prop, key) => {
                                                                    return <th key={key}>{prop}</th>;
                                                                }
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.selectedCampaigns.map((campaign, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>
                                                                        {PlatformService.getPlatformDeepLink(
                                                                            (campaign => {
                                                                                let campaignObject = campaign;
                                                                                campaignObject.campaignId = campaign.id;
                                                                                return campaignObject;
                                                                            })(campaign),
                                                                            campaign.name,
                                                                            'ANX'
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {!campaign.click_url ? (
                                                                            'No URL set.'
                                                                        ) : (
                                                                            <div>
                                                                                <Text
                                                                                    editable={{
                                                                                        onChange: value => {
                                                                                            if (
                                                                                                value !==
                                                                                                campaign.click_url
                                                                                            ) {
                                                                                                this.editCampaignURL(
                                                                                                    this.state
                                                                                                        .selectedAccount
                                                                                                        .id,
                                                                                                    campaign.id,
                                                                                                    value
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {campaign.click_url}
                                                                                </Text>
                                                                                {this.state.editingCampaign ===
                                                                                campaign.id ? (
                                                                                    <LoadingOutlined className="editable-text-loading-icon" />
                                                                                ) : null}
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {!campaign.click_url ? (
                                                                                '-'
                                                                            ) : (
                                                                                <a
                                                                                    href={campaign.click_url}
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Open Landing Page URL
                                                                                </a>
                                                                            )}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </Table>
                                            ) : null}

                                            <label className="control-label">Creatives</label>
                                            <Dragger {...draggerProps}>
                                                <p className="ant-upload-drag-icon">
                                                    <FileAddOutlined />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Click or drag one or multiple files to this area to upload
                                                </p>
                                            </Dragger>
                                            <br />
                                            <label className="control-label">Creative Folder</label>
                                            <br />
                                            <Checkbox
                                                checked={this.state.addToFolder}
                                                // disabled={this.state.disabled}
                                                onChange={this.handleFolderToggle}
                                            >
                                                Add creatives to folder{' '}
                                                <Tag>
                                                    <FolderAddOutlined />{' '}
                                                    {this.state.folderName + ' ' + moment().format('MM-DD-YY')}
                                                </Tag>
                                            </Checkbox>
                                            <br />
                                            <Input
                                                className="input-affix"
                                                value={this.state.folderName}
                                                onChange={e => {
                                                    this.setState({ folderName: e.target.value });
                                                }}
                                                placeholder="Enter folder name"
                                                disabled={!this.state.addToFolder}
                                                suffix={
                                                    <Tooltip
                                                        placement="topRight"
                                                        title="Today's date is automatically appended to the folder name."
                                                    >
                                                        <InfoCircleOutlined className="field-tooltip-icon" />
                                                    </Tooltip>
                                                }
                                            />
                                            <br />
                                            <Collapse bordered={false}>
                                                <Panel header="Advanced settings" key="1">
                                                    <Checkbox
                                                        checked={this.state.unassignPrevious}
                                                        onChange={e => {
                                                            this.setState({ unassignPrevious: e.target.checked });
                                                        }}
                                                    >
                                                        Unassign previous set(s) of creatives from line item(s)
                                                    </Checkbox>
                                                    <br />
                                                    <Checkbox
                                                        checked={this.state.deletePrevious}
                                                        onChange={e => {
                                                            this.setState({ deletePrevious: e.target.checked });
                                                        }}
                                                    >
                                                        Delete previous set(s) of creatives (deletes all creatives
                                                        associated with the currently selected campaigns/line items)
                                                    </Checkbox>
                                                    <br />
                                                    <Form.Item
                                                        label="Landing URL"
                                                        hasFeedback={!!this.state.customUrl}
                                                        validateStatus={
                                                            !this.state.customUrl
                                                                ? ''
                                                                : isUrlValid
                                                                ? 'success'
                                                                : 'error'
                                                        }
                                                        help={
                                                            this.state.customUrl && !isUrlValid
                                                                ? 'Please enter a valid URL'
                                                                : null
                                                        }
                                                        extra="Enter a URL to use for the landing page for these creatives rather than setting on the line item. (optional)"
                                                    >
                                                        <Input
                                                            value={this.state.customUrl}
                                                            onChange={e => {
                                                                const { value } = e.target;
                                                                this.setState({ customUrl: value });
                                                            }}
                                                            placeholder="https://example.com"
                                                        />
                                                    </Form.Item>
                                                </Panel>
                                            </Collapse>
                                            <br />
                                            <Checkbox
                                                checked={this.state.correctAccount}
                                                onChange={e => {
                                                    this.setState({ correctAccount: e.target.checked });
                                                }}
                                            >
                                                Have you double-checked that the correct client is selected?
                                            </Checkbox>
                                            <br />
                                            <Checkbox
                                                checked={this.state.correctCampaigns}
                                                onChange={e => {
                                                    this.setState({ correctCampaigns: e.target.checked });
                                                }}
                                            >
                                                Have you selected only the appropriate line items?
                                            </Checkbox>
                                            <br />
                                            <Checkbox
                                                checked={this.state.correctCreatives}
                                                onChange={e => {
                                                    this.setState({ correctCreatives: e.target.checked });
                                                }}
                                            >
                                                Do you have the correct banners uploaded?
                                            </Checkbox>
                                            <br />
                                            <Checkbox
                                                checked={this.state.correctUtmTags}
                                                onChange={e => {
                                                    this.setState({ correctUtmTags: e.target.checked });
                                                }}
                                            >
                                                Have you confirmed the UTM tag stays on the landing page URL?
                                            </Checkbox>
                                            <br />

                                            {this.state.deletePrevious ? (
                                                <Checkbox
                                                    checked={this.state.reallyDelete}
                                                    onChange={e => {
                                                        this.setState({ reallyDelete: e.target.checked });
                                                    }}
                                                >
                                                    <span className="text-danger">
                                                        Do you really want to delete the current creatives assigned to
                                                        the selected line items?
                                                    </span>
                                                </Checkbox>
                                            ) : null}
                                            <br />

                                            <Button
                                                type="primary"
                                                onClick={this.handleUpload}
                                                disabled={
                                                    !this.state.fileList.length ||
                                                    !this.state.selectedAccount ||
                                                    !this.state.selectedCampaigns ||
                                                    !this.state.correctAccount ||
                                                    !this.state.correctCampaigns ||
                                                    !this.state.correctCreatives ||
                                                    !this.state.correctUtmTags ||
                                                    (!this.state.reallyDelete && this.state.deletePrevious) ||
                                                    (this.state.addToFolder && !this.state.folderName) ||
                                                    (this.state.customUrl && !isUrlValid)
                                                }
                                                loading={uploading}
                                                style={{ marginTop: 16 }}
                                                size="large"
                                            >
                                                {uploading ? 'Uploading' : 'Start Upload'}
                                            </Button>
                                            <br />
                                        </div>
                                    </Grid>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default BannerUpload;
