export const templateFieldsCsTicketCovid = [
    { key: 'name', widget: 'input', label: 'What is your name?', rules: ['required'] },
    { key: 'title', widget: 'input', label: 'Please give your request a title.' },
    {
        key: 'email',
        widget: 'input',
        label: 'What is your email address?',
        rules: ['emailValidator', 'required'],
        confirmationEmail: true
    },
    { key: 'phone', widget: 'input', label: 'What is your phone number?', rules: ['phoneValidator'] },
    {
        key: 'website_url',
        widget: 'input',
        label: 'What is your community or company’s website URL?',
        rules: ['required']
    },
    {
        key: 'label1',
        widget: 'label',
        text: 'What tour options are currently available? (Check all that apply)',
        formItemLayout: [0, 24],
        widgetProps: { style: { color: 'rgb(133, 133, 133)', fontSize: '18px' } }
    },
    {
        key: 'options_virtual_tour',
        widget: 'switch',
        label: 'Virtual Tour with a leasing agent',
        formItemLayout: [16, 8]
    },
    { key: 'options_self_guide', widget: 'switch', label: 'Self-Guided Tours', formItemLayout: [16, 8] },
    { key: 'options_in_person', widget: 'switch', label: 'In person by appointment', formItemLayout: [16, 8] },
    {
        key: 'options_walk_in',
        widget: 'switch',
        label: 'In person - walk-in or appointment',
        formItemLayout: [16, 8]
    },
    {
        key: 'label2',
        widget: 'label',
        text: 'For in-person and self-guided tours: (Check all that apply)',
        formItemLayout: [0, 24],
        widgetProps: { style: { color: 'rgb(133, 133, 133)', fontSize: '18px' } }
    },
    {
        key: 'requirements_masks',
        widget: 'switch',
        label: 'Are masks required to tour?',
        formItemLayout: [16, 8]
    },
    {
        key: 'requirements_mask_provided',
        widget: 'switch',
        label: 'Will masks be provided by the community?',
        formItemLayout: [16, 8]
    },
    {
        key: 'requirements_temperature_check',
        widget: 'switch',
        label: 'Will there be a temperature check of each visitor?',
        formItemLayout: [16, 8]
    },
    {
        key: 'requirements_asked_sick',
        widget: 'switch',
        label: 'Will prospects be asked if they have been sick or had a fever in the last 14 days?',
        formItemLayout: [16, 8]
    },
    {
        key: 'label3',
        widget: 'label',
        text: 'What verbiage is preferred on ads and Schedule Genie widget? - Choose One',
        formItemLayout: [0, 24],
        widgetProps: { style: { color: 'rgb(133, 133, 133)', fontSize: '18px', margin: '0' } }
    },
    {
        key: 'sg_verbiage',
        widget: 'radio-group',
        options: [
            { value: 'sg_verbiage_schedule', label: 'Schedule a Tour' },
            { value: 'sg_verbiage_schedule_guided', label: 'Schedule a Guided Tour' },
            { value: 'sg_verbiage_appointment', label: 'Schedule an Appointment' }
        ],
        buttonGroup: true,
        label: '',
        initialValue: 'sg_verbiage_schedule'
    },
    {
        key: 'label4',
        widget: 'label',
        text: 'Which platforms do you want your messaging updated on? (check all that apply)',
        formItemLayout: [0, 24],
        widgetProps: { style: { color: 'rgb(133, 133, 133)', fontSize: '18px' } }
    },
    { key: 'platform_social', widget: 'switch', label: 'Social (FB and IG)', formItemLayout: [16, 8] },
    { key: 'platform_display', widget: 'switch', label: 'Display', formItemLayout: [16, 8] },
    { key: 'platform_search', widget: 'switch', label: 'Search', formItemLayout: [16, 8] },
    { key: 'platform_cc', widget: 'switch', label: 'The Conversion Cloud', formItemLayout: [16, 8] },
    { key: 'platform_chat', widget: 'switch', label: 'Chat', formItemLayout: [16, 8] },
    {
        key: 'label5',
        widget: 'label',
        text: '',
        formItemLayout: [0, 24],
        widgetProps: { style: { color: 'rgb(133, 133, 133)', fontSize: '18px', margin: '0' } }
    },
    { key: 'general', widget: 'switch', label: 'Any additional information?', formItemLayout: [16, 8] },
    { key: 'general_text', widget: 'textarea', label: '', managed: 'general', fullline: true }
];
