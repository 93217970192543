import React, {useState, useEffect, useRef} from 'react'
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { responceProcess, onError } from '../../services/error.service';
import { API_BASE_URL } from '../../variables/Variables';
import FormTemplateSelector from './formTemplateSelector';
import { StopTwoTone } from '@ant-design/icons';
import { Row, Col, Spin, Button, List, Pagination, Tag, Input, Switch } from 'antd';
import {
    FORM_DATA_LIST_ENDPOINT,
    APPROVAL_URL,
    APPROVAL_LIST,
    STATUS_STYLE,
    DATA_PRESENTATION_FORMAT
} from './fieldsTemplates.jsx';

const HREF_PREFIX = '/forms/formenter/';

// export default class FormApprovalList extends Component {
const FormApprovalList = props => {

    const [newmode, setNewmode] = useState(false);
    const [submissionList, setSubmissionList] = useState([]);
    const [templateid, setTemplateid] = useState(1);
    const [listLoading, setListLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [companyNameFilter, setCompanyNameFilter] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    let divRef = useRef(null);

    useEffect(() => {
        getSubmissionsList();
    }, [showArchived,page,templateid,companyNameFilter]);

    const getSubmissionsList = (() => {
        setListLoading( true );
        axios
            .get(
                `${API_BASE_URL}${FORM_DATA_LIST_ENDPOINT}/${templateid}/${page}/${showArchived}/${companyNameFilter}`
            )
            .then(res => {
                return responceProcess(res);
            })
            .then(res => {
                if (!res.length) {
                    setSubmissionList(res);
                    setTotalPages(0);
                    setListLoading(false);
                }
                setTotalPages(res[0].counter);
                setSubmissionList(res);
                setListLoading(false);

            })
            .catch(error => {
                setListLoading(false);
                onError(error);
                console.log(error);
            });    
    });

    const onTemplateChange = (selected)=> {
        let templateid = selected;
        props.history.push(APPROVAL_LIST + templateid);
        setTemplateid(templateid);
    }

    const onPageChange = (page) => {
        setPage(page);
    }

    const handleNameFilter = evt => {
        let getSubmissionsList = undefined;

        if (!evt.target.value.length || evt.target.value.length >= 4) {
            getSubmissionsList = this.getSubmissionsList;
        }
        setCompanyNameFilter(evt.target.value);
    };

    const handleArchivedFilter = stat => {
        setShowArchived(stat)
    };

    const archiveSubmission = (submisionID,status) => {
        console.log(submisionID)
        axios.post(
            `${API_BASE_URL}${FORM_DATA_LIST_ENDPOINT}/archive/${submisionID}/${status}`
        ).then(res=>{
            return responceProcess(res);
        }).then(res=>{
            getSubmissionsList();
        }).catch(error=>{
            onError(error);
        })
    };

    return (props.match.params.templateid === ':templateid') ? <Redirect to={props.match.url.replace(':templateid', '1')}/> :
            <React.Fragment>
                <div ref={divRef} className="formbuilder-wrapper">
                    <Row align="left">
                        <Col offset={2} span={3}>
                            Form:
                        </Col>

                        <Col span={12}>
                            <FormTemplateSelector
                                disabled={newmode}
                                span={14}
                                onTemplateChange={onTemplateChange}
                                onError={onError}
                                responceProcess={responceProcess}
                                hrefPrefix={HREF_PREFIX}
                                listType="select"
                                templateId={templateid}
                            />
                        </Col>                        
                        <Col offset={1} span={4}>
                            Show archived: 
                            <Switch checked={showArchived} onChange={handleArchivedFilter} />
                        </Col>
                    </Row>
                    <Row align="left" className="filter-row">
                        <Col offset={2} span={3}>
                            Name Filter:
                        </Col>
                        <Col span={12}>
                            <Input onChange={handleNameFilter} value={companyNameFilter} />
                        </Col>
                    </Row>
                </div>
                <Spin size="large" spinning={listLoading}>
                    {submissionList.length ? (
                        <Row>
                            <Col offset={2} span={20}>
                                <List itemLayout="vertical">
                                    <List.Item key="Header">
                                        <Row span={18}>
                                            <Col span={12}>
                                                <h6>Company Name</h6>
                                            </Col>
                                            <Col span={5}>
                                                <h6>Status</h6>
                                            </Col>
                                            <Col span={4}>
                                                <h6>Date</h6>
                                            </Col>
                                            <Col span={3}>
                                                <h6>Operations</h6>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                    {submissionList.map(subm => {
                                        return (
                                            <List.Item key={subm.id}>
                                                <Row>
                                                    <Col span={12}>
                                                        <h6>{Object.values(subm.data)[0]}</h6>
                                                        {subm.name}
                                                    </Col>
                                                    <Col span={5}>
                                                        <Tag color={STATUS_STYLE[subm.status]}>{subm.status}</Tag>
                                                    </Col>
                                                    <Col span={4}>
                                                        Submitted:
                                                        {moment(subm.submited_at).format(DATA_PRESENTATION_FORMAT)}
                                                        <br />
                                                        Updated:
                                                        {moment(subm.updated_at).format(DATA_PRESENTATION_FORMAT)}
                                                    </Col>
                                                    <Col span={3}>
                                                        <a href={APPROVAL_URL + subm.id}>
                                                            <Button>Edit</Button>
                                                        </a>
                                                        {(subm.status!=='Archived')?(<Button onClick={()=>archiveSubmission(subm.id,'Archived')}>Archive</Button>):(<Button onClick={()=>archiveSubmission(subm.id,'Entered')}>Unarchive</Button>)}
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        );
                                    })}
                                </List>

                                <Pagination
                                    className={'pagination-list'}
                                    onChange={(page, pageSize) => {onPageChange(page)}}
                                    // onShowSizeChange={onShowSizeChange}
                                    defaultCurrent={page}
                                    total={totalPages}
                                    showSizeChanger={false}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <Row type="flex" justify="center" align="middle">
                            <Col span={4}>
                                <div className="icon-card">
                                    <StopTwoTone />
                                    <span>NO DATA</span>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Spin>
            </React.Fragment>
}
export default FormApprovalList