import React, {useState} from 'react'
import '@ant-design/compatible/assets/index.css';
import {Form, Row, Col, Button, Input } from 'antd';
import CLXForm from './clx-form';
import { fieldsTemplates } from './fieldsTemplates';
import { responceProcess, onError } from '../../services/error.service';
import FormTemplateSelector from './formTemplateSelector';
import './index.css';

const initFormTemplate = () => {
    return { fields: [], name: 'Form Name' };
};

const FormBuilder = () => {
    const [form] = Form.useForm();

    const [newMode, setNewMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [previewMeta, setPreviewMeta] = useState([]);
    const [templatedata, setTemplatedata] = useState(initFormTemplate());
    
    const onTemplateChange = (templateData) => {
        if (templateData) {
        }
        setTemplatedata({ templateData, editmode: true })
    }

    const startNew = ()=> {
        setEditMode(true);
        setNewMode(true);
        setPreviewMeta([]);
        setTemplatedata(initFormTemplate());
    }

    const stopEdit =()=> {
        setEditMode(false);
        setNewMode(false);
        setPreviewMeta([]);
        setTemplatedata(initFormTemplate());
    }

    const addField = (fieldDescription) => {
        let templatedataTemp = JSON.parse(JSON.stringify(templatedata));
        let lastID = templatedataTemp.fields.length;
        fieldDescription.options.forEach(fi => {
            templatedataTemp.fields.push({
                key: fieldDescription.widget + '_' + String(lastID) + '_' + fi,
                label: fi,
                colSpan: 2,
                defaulValue: ''
            });
        });

        setTemplatedata({templatedataTemp});
        
    }

    const renderFieldsAvailable =()=> {
        return fieldsTemplates.map(field => {
            return (
                <Button
                    onClick={() => {
                        addField(field);
                    }}
                >
                    Add {field.widget.toUpperCase()}
                </Button>
            );
        });
    }

    return (
         <div className="formbuilder-wrapper">
                <Row align="middle">
                    <FormTemplateSelector
                        disabled={newMode}
                        span={14}
                        offset={4}
                        onTemplateChange={onTemplateChange}
                        onError={onError}
                        responceProcess={responceProcess}
                        listType="select"
                    />
                    <Button span={14} onClick={startNew}>
                        New
                    </Button>
                </Row>
                {editMode
                    ? <React.Fragment>
                          <Form form={form} >
                              <Form.Item label="Form Name" name="name">
                                  <Input placeholder="input placeholder" />
                              </Form.Item>
                              {templatedata.fields.map(field => {
                                  return (
                                      <React.Fragment>
                                          <Input.Group compact>
                                              {field.map(subField => {
                                                  return <Form.Item {...subField} />;
                                              })}
                                          </Input.Group>
                                          <Button>X</Button>
                                      </React.Fragment>
                                  );
                              })}
                          </Form>
                          <Row align="middle">
                              <Col span={20}>
                                  {renderFieldsAvailable()}
                                  <Button onClick={stopEdit} align="left">
                                      Cancel
                                  </Button>
                              </Col>
                          </Row>
                          <CLXForm meta={previewMeta} csTeam = {true} />
                      </React.Fragment>
                    : ''}
            </div>
    )
}

export default FormBuilder