import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { responceProcess, onError, onSuccess } from '../../services/error.service';
import { API_BASE_URL } from '../../variables/Variables';
import { templateFields } from '../../constants/template-fields';
import CLXForm from './clx-form';
import { Row, Col, Spin, Button, Radio, Tag } from 'antd';
import {
    FORM_TEMPLATE_ENDPOINT,
    FORM_DATA_ENDPOINT,
    STATUS_STYLE,
    DATA_PRESENTATION_FORMAT
} from './fieldsTemplates.jsx';
import './form.css';

const DEFAULT_SPAN = 18;
const DEFAULT_OFFSET = 3;

const initFormTemplate = () => {
    return { fields: [] };
};

const formApprovalForm = props => {
    let submissionId = props.match.params.submissionId;

    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(true);
    const [templateLoading, setTemplateLoading] = useState(true);
    const [template, setTemplate] = useState(initFormTemplate());
    const [error, setError] = useState(null);

    useEffect(() => {
        getSubmissionData(submissionId);
    }, [submissionId]);

    const getTemplateData = templateid => {
        setTemplateLoading(true);
        axios
            .get(`${API_BASE_URL}${FORM_TEMPLATE_ENDPOINT}/${templateid}/`)
            .then(res => {
                return responceProcess(res);
            })
            .then(templateRaw => {
                let template = { ...templateRaw };
                for (let i = 0; template.fields.length > i; i++) {
                    if (!!template.fields[i].initialValue) {
                        delete template.fields[i].initialValue;
                    }
                }
                setTemplate(template);
                setTemplateLoading(false);
            })
            .catch(error => {
                setTemplateLoading(false);
                setError('Error getting form data');
                onError('Error getting form data');
            });
    };

    const getSubmissionData = submissionId => {
        setTemplateLoading(true);

        axios
            .get(`${API_BASE_URL}${FORM_DATA_ENDPOINT}/${submissionId}/`)
            .then(res => {
                return responceProcess(res);
            })
            .then(data => {
                setData(data);
                getTemplateData(data.templateid);
            })
            .catch(error => {
                setTemplateLoading(false);
                setError('Error getting form data');
                onError('Error getting form data');
            });
    };

    // id = 0 - new record, otherwise - edit
    const submitData = (data, templateid, id = 0, status = 'Entered') => {
        setTemplateLoading(true);
        axios
            .post(`${API_BASE_URL}${FORM_DATA_ENDPOINT}`, { data, status, templateid, id })
            .then(res => {
                return responceProcess(res);
            })
            .then(res => {
                onSuccess('Data updated, Thank you!!!');
                getSubmissionData(id);
            })
            .catch(error => {
                setTemplateLoading(false);
                setError(String(error));
                onError(String(error));
            });
    };

    const onValidationError = () => {
        console.log('Data is not valid');
    };

    const onChangeStatus = status => {
        let updateData = { ...data };
        updateData.status = status.target.value;

        submitData(updateData.data, updateData.templateid, updateData.id, updateData.status);
    };

    return !template || !template.id ? null : (
        <React.Fragment>
            <Row className={'holder-indent'}>
                <Col span={8} md={{ span: 4 }} offset={1}>
                    <Button
                        onClick={() => {
                            props.history.push(`/forms-approval-list/${template.id}`);
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        onClick={() => {
                            setEditMode(!editMode);
                        }}
                    >
                        Edit
                    </Button>
                </Col>
                <Col span={14} sm={{ span: 16 }} md={{ span: 12 }} offset={1}>
                    <Radio.Group onChange={onChangeStatus} value={data.status}>
                        {Object.keys(STATUS_STYLE).map((status, index) => {
                            return (
                                <Radio.Button key={index} value={status}>
                                    {status}
                                </Radio.Button>
                            );
                        })}
                    </Radio.Group>
                </Col>
                <Col span={0} md={{ span: 4 }} offset={1}>
                    <Tag color="volcano">Created: {moment(data.submited_at).format(DATA_PRESENTATION_FORMAT)}</Tag>
                    <Tag color="volcano">Updated {moment(data.updated_at).format(DATA_PRESENTATION_FORMAT)}</Tag>
                </Col>
            </Row>
            <Row className="preview-container">
                <Col
                    span={props.span ? props.span : DEFAULT_SPAN}
                    offset={props.offset ? props.offset : DEFAULT_OFFSET}
                >
                    <Spin size="large" spinning={templateLoading}>
                        <h4>{template.name}</h4>
                        <CLXForm
                            autocomplete={false}
                            meta={templateFields[template.id]}
                            initialValues={data.data}
                            onSubmit={formData => {
                                submitData(formData, data.templateid, data.id, data.status);
                                setEditMode(true);
                            }}
                            onValidationError={onValidationError}
                            viewMode={editMode}
                            csTeam = {true}
                        />
                    </Spin>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default formApprovalForm;
