import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import ReactGA from 'react-ga';
import addresser from 'addresser';
import zipcodes from 'zipcodes';

import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';

import AutofillClientDetailsModal from '../../components/AutofillClientDetailsModal';

import { QuestionCircleOutlined, ThunderboltOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
    Divider,
    Input,
    Tooltip,
    InputNumber,
    DatePicker,
    message,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    Radio,
    Switch
} from 'antd';
import 'antd/dist/antd.css';

import CitySelector from './CitySelector';
import BacklistSelector from './../Appnexus/BlacklistSelector';

const { Option } = Select;
const { RangePicker } = DatePicker;

const radioButtonStyle = { padding: '2px 12px 2px 12px' };
const PRODUCT_CONFIGURATIONS = {
    RT: {
        UTM_SUFFIX: '?utm_source=imps.conversionlogix.com&utm_medium=display&utm_content=clx&utm_campaign=Retargeting'
    },
    '3D': {
        UTM_SUFFIX:
            '?utm_source=imps.conversionlogix.com&utm_medium=display&utm_content=clx&utm_campaign=Targeted_Display'
    }
};

const CURRENCY_INPUT = (
    <InputNumber
        min={0}
        formatter={value => `$ ${value}`.replace(/(\.\d{2})(?:\d+)/g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/(\.\d{2})(?:\d+)/g, '$1').replace(/\$\s?|(,*)/g, '')}
    />
);

const CURRENCY_RULES = {
    type: 'number',
    message: 'Please enter a valid currency amount!',
    whitespace: true
};

class AccountBuilderForm extends Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: []
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.setState({ loading: true });
                try {
                    const {
                        launch_period,
                        prorate,
                        prorate_budget_io,
                        prorate_budget_rt,
                        monthly_budget_rt,
                        landing_page_url_rt,
                        prorate_budget_3d,
                        monthly_budget_3d,
                        landing_page_url_3d,
                        city_targets,
                        blacklist,
                        ...requestBody
                    } = values;

                    requestBody.products = {};
                    requestBody.blacklist = blacklist;
                    requestBody.start_date = launch_period[0].format('YYYY-MM-DD');
                    requestBody.end_date = launch_period[1].format('YYYY-MM-DD');

                    ['rt', '3d', 'io'].forEach(element => {
                        if (!prorate || !values['prorate_budget_' + element]) {
                            values['prorate_budget_' + element] =
                                values[element === 'io' ? 'monthly_budget' : 'monthly_budget_' + element];
                        }
                    });

                    requestBody.first_month_budget = values['prorate_budget_io'];

                    Object.keys(PRODUCT_CONFIGURATIONS).forEach(product => {
                        const lowercaseProduct = product.toLowerCase();

                        requestBody.products[lowercaseProduct] = {
                            first_month_budget: values['prorate_budget_' + lowercaseProduct],
                            monthly_budget: values['monthly_budget_' + lowercaseProduct],
                            landing_page_url:
                                values['landing_page_url_' + lowercaseProduct] +
                                PRODUCT_CONFIGURATIONS[product].UTM_SUFFIX
                        };
                    });

                    requestBody.city_targets =
                        city_targets &&
                        city_targets.map(city => {
                            return {
                                id: city.key
                            };
                        });

                    axios({
                        method: 'post',
                        url: `${API_BASE_URL}anx/accounts/`,
                        headers: authHeader(),
                        data: requestBody
                    })
                        .then(response => {
                            message.success(`Successfully created account.`);

                            this.props.onResponseChange(response.data);

                            ReactGA.event({
                                category: 'AdOps',
                                action: 'Build Account',
                                label: `Built account ${values.account_name}`,
                                value: 1
                            });
                        })
                        .catch(error => {
                            message.error(`Build account failed: ${error.response.data.message}`);
                            console.error('Error building account!', error);
                        })
                        .finally(() => {
                            this.setState({ loading: false });
                        });
                } catch (error) {
                    message.error('Build account failed!');
                    console.log(error);
                    this.setState({ loading: false });
                }
            } else {
                console.error(err);
            }
        });
    };

    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        const formItemLayoutWithoutLabel = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 18,
                    offset: 6
                }
            }
        };

        const verticalSelector = getFieldDecorator('vertical_id', {
            rules: [
                {
                    required: true,
                    message: 'Account vertical is required!'
                }
            ]
        })(
            <Select>
                <Option value="auto">Automotive (Auto)</Option>
                <Option value="local">Non-Auto (Local)</Option>
                <Option value="res">Hotel/Apartment/Multi-Family (RES)</Option>
                <Option value="srl">Senior Living (SRL)</Option>
                <Option value="agency">Non-Auto (Agency)</Option>
            </Select>
        );

        return (
            <React.Fragment>
                <AutofillClientDetailsModal
                    open={this.state.gmbAutofillClientModalVisible}
                    onCancel={() => {
                        this.setState({ gmbAutofillClientModalVisible: false });
                    }}
                    mapData={async client => {
                        let parsedAddress;
                        let city_targets;
                        let zip_targets;

                        try {
                            parsedAddress = addresser.parseAddress(client.location.toUpperCase());

                            zip_targets = zipcodes.radius(parsedAddress.zipCode, 15);
                            zip_targets = { preview: zip_targets.join(', '), value: zip_targets };

                            city_targets = await axios
                                .get(`${API_BASE_URL}anx/readonlyservice/city?q=${parsedAddress.placeName}`, {
                                    headers: authHeader()
                                })
                                .then(response => {
                                    let data = response.data;

                                    data = data.filter(
                                        item =>
                                            item.name === parsedAddress.placeName &&
                                            item.region_code === parsedAddress.stateAbbreviation
                                    );

                                    data = data.map(city => {
                                        const region = city.country_code === 'US' ? city.region_code : city.region_name;
                                        const country = city.country_code === 'US' ? 'US' : city.country_name;
                                        return { label: `${city.name}, ${region}, ${country}`, key: city.id + '' };
                                    });

                                    return { preview: data[0].label, value: [data[0]] };
                                })
                                .catch(error => {
                                    console.error('Error getting data!', error);
                                });
                        } catch (error) {
                            console.error(error);
                        }

                        return {
                            master_id: client.item_id,
                            account_name: client.company_name,
                            vertical_id: {
                                auto: { preview: 'Auto', value: 'auto' },
                                local: { preview: 'Local', value: 'local' },
                                res: { preview: 'RES', value: 'res' },
                                srl: { preview: 'SRL', value: 'srl' },
                                agency: { preview: 'Agency', value: 'agency' }
                            }[client.vertical_id],

                            landing_page_url: client.homepage_urls,
                            address: client.location,
                            city_targets,
                            zip_targets
                        };
                    }}
                    previewLabels={{
                        master_id: 'Master ID',
                        landing_page_url: 'Landing Page URL',
                        city_targets: 'City Targets',
                        zip_targets: 'Zip Code Targets'
                    }}
                    onOk={data => {
                        setFieldsValue(data);
                        this.setState({ gmbAutofillClientModalVisible: false });
                    }}
                />

                <Form {...formItemLayout} onSubmit={this.handleSubmit} autoComplete="off">
                    <Form.Item {...formItemLayoutWithoutLabel}>
                        <Button
                            icon={<ThunderboltOutlined />}
                            onClick={() => {
                                this.setState({ gmbAutofillClientModalVisible: true });
                            }}
                        >
                            Autofill Client Details...
                        </Button>
                    </Form.Item>

                    <Form.Item label="Master ID">
                        {getFieldDecorator('master_id', {})(<Input disabled={true} bordered={false} />)}
                    </Form.Item>

                    <Form.Item label="Account Name">
                        {getFieldDecorator('account_name', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Account name is required!'
                                }
                            ]
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Vertical">{verticalSelector}</Form.Item>

                    <Form.Item label="Landing Page URL">
                        {getFieldDecorator('landing_page_url', {
                            rules: [
                                {
                                    type: 'url',
                                    message: 'The input is not a valid URL!'
                                },
                                {
                                    required: true,
                                    message: 'Landing Page URL is required!'
                                }
                            ]
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Street Address">
                        {getFieldDecorator('address', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter a valid address!'
                                }
                            ]
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="Pixels Set" extra={'Enabling the client-specific conversion pixels'}>
                        {getFieldDecorator(
                            'pixelsSet',
                            {}
                        )(
                            <Radio.Group defaultValue="default" buttonStyle="solid">
                                <Radio.Button value="default" style={radioButtonStyle}>
                                    Default
                                </Radio.Button>
                                <Radio.Button value="greystar" style={radioButtonStyle}>
                                    Greystar
                                </Radio.Button>
                                <Radio.Button value="ave5" style={radioButtonStyle}>
                                    Avenue 5
                                </Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    <Divider orientation="left">Product Configuration</Divider>

                    <Form.Item {...formItemLayoutWithoutLabel}>
                        {getFieldDecorator('prorate', {
                            valuePropName: 'checked'
                        })(
                            <Checkbox>
                                Prorate the first month's budget{' '}
                                <Tooltip title="When selected, you can pick a budget to apply for the IOs and each line item through the last day of the first calendar month that the account is active.">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Checkbox>
                        )}
                    </Form.Item>

                    <Form.Item
                        label={`${'RT'} Landing Page URL`}
                        extra={
                            <span>
                                <Tooltip title="UTM tags will automatically be appended to the provided URL">
                                    <QuestionCircleOutlined />
                                </Tooltip>{' '}
                                {PRODUCT_CONFIGURATIONS.RT.UTM_SUFFIX}
                            </span>
                        }
                        style={{ marginBottom: 0 }}
                    >
                        {getFieldDecorator('landing_page_url_rt', {
                            rules: [
                                {
                                    type: 'url',
                                    message: 'The input is not a valid URL!'
                                },
                                {
                                    required: true,
                                    message: 'Landing Page URL is required!'
                                }
                            ]
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item
                        label={`${'RT'} Monthly Budget`}
                        style={{
                            marginBottom: 0
                        }}
                    >
                        {getFieldDecorator('monthly_budget_rt', {
                            rules: [
                                {
                                    required: true,
                                    ...CURRENCY_RULES
                                }
                            ]
                        })(CURRENCY_INPUT)}

                        <span style={{ marginLeft: 36, display: getFieldValue('prorate') ? null : 'none' }}>
                            <label title="RT Monthly Budget" style={{ marginRight: 12 }}>
                                RT First Month's Budget:
                            </label>

                            {getFieldDecorator('prorate_budget_rt', {
                                rules: [
                                    {
                                        ...CURRENCY_RULES
                                    }
                                ]
                            })(CURRENCY_INPUT)}
                        </span>
                    </Form.Item>

                    <Row>
                        <Col {...formItemLayoutWithoutLabel.wrapperCol}>
                            Note: Retargeting defaults to <strong>United States</strong> for Geography Targeting. Use
                            the console to select other regions.
                        </Col>
                    </Row>

                    <Divider />

                    <Form.Item
                        label={`${'3D'} Landing Page URL`}
                        extra={
                            <span>
                                <Tooltip title="UTM tags will automatically be appended to the provided URL">
                                    <QuestionCircleOutlined />
                                </Tooltip>{' '}
                                {PRODUCT_CONFIGURATIONS['3D'].UTM_SUFFIX}
                            </span>
                        }
                        style={{ marginBottom: 0 }}
                    >
                        {getFieldDecorator('landing_page_url_3d', {
                            rules: [
                                {
                                    type: 'url',
                                    message: 'The input is not a valid URL!'
                                },
                                {
                                    required: true,
                                    message: 'Landing Page URL is required!'
                                }
                            ]
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item label={`${'3D'} Monthly Budget`} style={{ marginBottom: 0 }}>
                        {getFieldDecorator('monthly_budget_3d', {
                            rules: [
                                {
                                    required: true,
                                    ...CURRENCY_RULES
                                }
                            ]
                        })(CURRENCY_INPUT)}

                        <span style={{ marginLeft: 36, display: getFieldValue('prorate') ? null : 'none' }}>
                            <label title="3D First Month's Budget" style={{ marginRight: 12 }}>
                                3D First Month's Budget:
                            </label>

                            {getFieldDecorator('prorate_budget_3d', {
                                rules: [
                                    {
                                        ...CURRENCY_RULES
                                    }
                                ]
                            })(CURRENCY_INPUT)}
                        </span>
                    </Form.Item>

                    <Form.Item label={<span>City Targets</span>}>
                        {getFieldDecorator('city_targets', {})(<CitySelector />)}
                    </Form.Item>

                    <Form.Item label={<span>Zip Code Targeting</span>}>
                        {getFieldDecorator(
                            'zip_targets',
                            {}
                        )(<Select mode="tags" tokenSeparators={[',']} dropdownStyle={{ display: 'none' }}></Select>)}
                    </Form.Item>

                    <Form.Item label={<span>BlackList (Inventory List)</span>}>
                        {getFieldDecorator('blacklist', {
                            rules: [
                                {
                                    required: false
                                }
                            ],

                            trigger: 'onBlacklistChange',
                            valuePropName: 'blacklist'
                        })(<BacklistSelector />)}
                    </Form.Item>

                    <div style={{ display: 'none' }}>
                        <Divider orientation="left">Segment Configuration</Divider>

                        <Form.Item label={<span>Keep User In Segment for</span>} colon={false}>
                            {getFieldDecorator('segment_expiration', {
                                initialValue: 50,
                                rules: [
                                    {
                                        required: true,
                                        type: 'number',
                                        message: 'Please enter a valid number of days!',
                                        whitespace: true
                                    }
                                ]
                            })(<InputNumber min={1} max={180} />)}
                            <span className="ant-form-text"> days</span>
                        </Form.Item>
                    </div>

                    <Divider orientation="left">Flights and Budgeting</Divider>

                    <Form.Item label="Launch Period">
                        {getFieldDecorator('launch_period', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter a valid date range!'
                                }
                            ]
                        })(
                            <RangePicker
                                format={'MM/DD/YYYY'}
                                disabledDate={current => current < moment().startOf('day')}
                                separator="to"
                                ranges={{
                                    'Tomorrow through next month': [
                                        moment().add(1, 'day'),
                                        moment().add(1, 'month').endOf('month')
                                    ],
                                    'Tomorrow through two months': [
                                        moment().add(1, 'day'),
                                        moment().add(2, 'months').endOf('month')
                                    ],
                                    'Tomorrow through three months': [
                                        moment().add(1, 'day'),
                                        moment().add(3, 'months').endOf('month')
                                    ]
                                }}
                            />
                        )}
                    </Form.Item>

                    <Form.Item label={<span>Monthly Budget</span>}>
                        {getFieldDecorator('monthly_budget', {
                            rules: [
                                {
                                    required: true,
                                    min: 1,
                                    ...CURRENCY_RULES,
                                    message: 'Please enter a valid currency amount above $1!'
                                }
                            ]
                        })(CURRENCY_INPUT)}

                        <span style={{ marginLeft: 36, display: getFieldValue('prorate') ? null : 'none' }}>
                            <label title="IO First Month's Budget" style={{ marginRight: 12 }}>
                                IO First Month's Budget:
                            </label>

                            {getFieldDecorator('prorate_budget_io', {
                                rules: [
                                    {
                                        min: 1,
                                        ...CURRENCY_RULES,
                                        message: 'Please enter a valid currency amount above $1!'
                                    }
                                ]
                            })(CURRENCY_INPUT)}
                        </span>
                    </Form.Item>

                    <Form.Item {...formItemLayoutWithoutLabel}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>
                            {this.state.loading ? 'Creating Account' : 'Create Account'}
                        </Button>
                    </Form.Item>
                </Form>
            </React.Fragment>
        );
    }
}

export default Form.create({ name: 'build-account' })(AccountBuilderForm);
