import {
    Row,
    Col,
    InputNumber,
    Modal,
    DatePicker,
    Typography,
    Select
} from 'antd';
import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Grid } from 'react-bootstrap';
import {DATE_FORMAT_SAVE,DATE_FORMAT} from './GADSconstants';

const { Text } = Typography;
const { Option } = Select;

export default props => {
    const [startDate, setStartDate] = useState(moment().startOf('month'));
    const [endDate, setEndDate] = useState(moment().endOf('month'));
    const [type, setType] = useState('manual');    
    const [budget, setBudget] = useState(0);
    const [campaign, setCampaign] = useState(false);
    const {initialManagement }= props;

    const handleCancel = () => {
        props.handleNewBudgetMode();
    }

    const handleBudgetAdd = () => {
        const budgets = initialManagement.budgets || [];
        const addingBudgets = { start_date:startDate.format(DATE_FORMAT_SAVE), end_date:endDate.format(DATE_FORMAT_SAVE), wholesale_price:budget, type , campaign_id:campaign};
        budgets.push(addingBudgets);
        props.handleBudgetsChange(budgets);
        props.handleNewBudgetMode();
    }

    const handleDate = (name, date) => {
        if (name === 'startDate') {
            setStartDate(date);
        } else {
            setEndDate(date);
        }
    }

    return (
        <Modal
            title="Budget adding"
            open={props.budgetAddMode} visible={props.budgetAddMode}
            onOk={handleBudgetAdd}
            onCancel={handleCancel}
            okText="Add budget to current account"
            width={1000}
        >
            <Grid fluid key="block-container">
                <Row key="dates-block" gutter={6} >
                    {initialManagement.campaigns && !!Object.values(initialManagement.campaigns)?
                    <Col span={12}>
                        <Text>Campaign</Text>
                        <Select defaultValue={'0'} onChange={(value)=>{setCampaign(value==="0"?false:value)}}>
                          <Option value={'0'}>All</Option>
                          {Object.values(initialManagement.campaigns).map(lc => {
                              return (
                                  <Option value={lc.id}>
                                      {lc.name}
                                  </Option>
                              );
                          })}
                        </Select>
                    </Col>:null
                    }
                    <Col span={4}>
                        <Text>Start date:</Text><br/>
                        <DatePicker
                            onChange={date => handleDate('startDate', date)}
                            format={DATE_FORMAT}
                            placeholder="Start Date"
                            defaultValue={moment(startDate, DATE_FORMAT)}
                        />
                    </Col>
                    <Col span={4}>
                        <Text>End date:</Text><br/>
                        <DatePicker
                            onChange={date => handleDate('endDate', date)}
                            placeholder="End Date"
                            format={DATE_FORMAT}
                            defaultValue={moment(endDate, DATE_FORMAT)}
                        />
                    </Col>
                    <Col span={4}>
                        <Text>Amount</Text><br/>
                        <InputNumber
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            defaultValue={budget}
                            onChange={budget => {
                                setBudget(budget );
                            }}
                            placeholder="Amount"
                        />
                    </Col>
                </Row>
            </Grid>
        </Modal>
    );
}
