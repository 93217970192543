import React, { useState } from 'react';
import { DatePicker, Checkbox } from 'antd';
import { Form } from '@ant-design/compatible';
import moment from 'moment';

const dateFormat = 'YYYY/MM/DD';

const CampaignDatePicker = props => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const [isDisabled, setIsDisabled] = useState(true);

    return (
        <div>
            <Form.Item label="Campaign Start Date">
                {getFieldDecorator('campaign_start_date', {
                    initialValue: new Date(),
                    rules: [
                        {
                            required: true,
                            message: 'Campaign Start Date is required!'
                        }
                    ]
                })(
                    <div>
                        <DatePicker
                            style={{ marginRight: '25px' }}
                            defaultValue={moment(new Date(), dateFormat)}
                            format={dateFormat}
                            disabled={isDisabled}
                            onChange={date => {
                                setFieldsValue({ campaign_start_date: date.toLocaleString() });
                            }}
                        />
                        <Checkbox
                            onChange={() => {
                                setIsDisabled(!isDisabled);
                            }}
                        >
                            Override Start Date
                        </Checkbox>
                    </div>
                )}
            </Form.Item>
        </div>
    );
};

export default CampaignDatePicker;
