export const templateFieldsCsTicket = [
    { key: 'name', widget: 'input', label: 'What is your name?', rules: ['required'] },
    { key: 'title', widget: 'input', label: 'Please give your request a title.' },
    {
        key: 'email',
        widget: 'input',
        label: 'What is your email address?',
        rules: ['emailValidator'],
        confirmationEmail: true
    },
    { key: 'phone', widget: 'input', label: 'What is your phone number?', rules: ['phoneValidator'] },
    {
        key: 'website_url',
        widget: 'input',
        label: 'What is your community or company’s website URL?',
        rules: ['urlValidator']
    },
    {
        key: 'label1',
        widget: 'label',
        text: 'Title/Description (please check the applicable boxes)',
        formItemLayout: [0, 24],
        widgetProps: { style: { color: 'rgb(133, 133, 133)', fontSize: '18px' } }
    },
    {
        key: 'general',
        widget: 'switch',
        label: 'General updates (ad copy or image requests)',
        formItemLayout: [16, 8],
        requiredAnyList: ['email_blast', 'optimization', 'general'],
        requiredAnyText: 'At least one of instructions should be checked.'
    },
    { key: 'general_text', widget: 'textarea', label: '', managed: 'general', fullline: true, rules: ['required'] },
    { key: 'email_blast', widget: 'switch', label: 'Email blast instructions', formItemLayout: [16, 8] },
    {
        key: 'email_blast_text',
        widget: 'textarea',
        label: '',
        managed: 'email_blast',
        fullline: true,
        rules: ['required']
    },
    {
        key: 'optimization',
        widget: 'switch',
        label: 'Tracking phone number, email address, or URL updates',
        formItemLayout: [16, 8]
    },
    {
        key: 'optimization_text',
        widget: 'textarea',
        label: '',
        managed: 'optimization',
        fullline: true,
        rules: ['required']
    }
];