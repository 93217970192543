import React from 'react';

import axios from 'axios';
import ReactSelect from 'react-select';
import { Descriptions, Modal, Spin } from 'antd';

import { API_BASE_URL } from '../variables/Variables';

export default class AutofillClientDetailsModal extends React.Component {
    constructor() {
        super();
        this.state = { loadingLocations: true, locations: [], selectedLocation: null };
    }

    componentDidMount() {
        axios.get(`${API_BASE_URL}companies/companyautofill`).then(res => {
            this.setState({ locations: res.data.clients, loadingLocations: false });
        });
    }

    render() {
        const { open, onCancel, onOk, confirmLoading } = this.props;

        return (
            <Modal
                open={open}
                title={'Autofill Client Details...'}
                okText="Autofill Client Details"
                onCancel={() => {
                    onCancel();
                }}
                onOk={() => {
                    if (onOk && typeof onOk === 'function') {
                        onOk(
                            Object.keys(this.state.selectedLocation).reduce((obj, key) => {
                                if (
                                    this.state.selectedLocation[key].preview &&
                                    this.state.selectedLocation[key].value
                                ) {
                                    obj[key] = this.state.selectedLocation[key].value;
                                } else {
                                    obj[key] = this.state.selectedLocation[key];
                                }

                                return obj;
                            }, {})
                        );
                    }
                }}
                okButtonProps={{ disabled: !this.state.selectedLocation }}
                centered
                confirmLoading={confirmLoading}
            >
                <Spin spinning={this.state.loadingLocations}>
                    <ReactSelect
                        autoFocus
                        formatOptionLabel={({ value, company_name, location }) => (
                            <div
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                <div></div>
                                {company_name} <small style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{location}</small>
                            </div>
                        )}
                        getOptionLabel={({ company_name }) => company_name}
                        getOptionValue={({ item_id }) => item_id}
                        onChange={async client => {
                            let mappedData = client;

                            if (this.props.mapData) {
                                this.setState({ loadingLocations: true });
                                mappedData = await this.props.mapData(client);
                                this.setState({ loadingLocations: false });
                            }

                            Object.keys(mappedData).forEach(
                                key =>
                                    (mappedData[key] === undefined || mappedData[key] === null) &&
                                    delete mappedData[key]
                            );

                            this.setState({
                                selectedLocation: mappedData
                            });
                        }}
                        options={this.state.locations}
                    />

                    {this.state.selectedLocation && (
                        <Descriptions title="Preview" bordered={true} column={1} style={{ marginTop: 16 }}>
                            {Object.keys(this.state.selectedLocation)
                                .filter(key => this.state.selectedLocation[key])
                                .map(key => (
                                    <Descriptions.Item
                                        label={
                                            this.state.selectedLocation[key].label ||
                                            {
                                                account_name: 'Account Name',
                                                address: 'Address',
                                                vertical_id: 'Vertical',
                                                landing_url: 'Main Landing Page URL',
                                                time_zone: 'Time Zone',
                                                ...this.props.previewLabels
                                            }[key]
                                        }
                                        key={key}
                                    >
                                        {this.state.selectedLocation[key].loading ? (
                                            <Spin size="small" />
                                        ) : (
                                            this.state.selectedLocation[key].preview || this.state.selectedLocation[key]
                                        )}
                                    </Descriptions.Item>
                                ))}
                        </Descriptions>
                    )}
                </Spin>
            </Modal>
        );
    }
}
