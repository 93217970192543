import React from 'react';

const PLATFORM_LINKS = {
    ANX: {
        account: 'https://invest.xandr.com/bmw/advertisers?advertiser_id={0}',
        campaign: 'https://invest.xandr.com/bmw/line-items/in-progress?line_item_id={1}'
    },
    FB: {
        account: 'https://business.facebook.com/adsmanager/manage/accounts?act={0}',
        campaign: 'https://www.facebook.com/adsmanager/manage/ads?act={0}&selected_adset_ids={1}'
    },
    GoogleAds: {
        account: 'https://adwords.google.com/aw/overview?__e={0}',
        campaign: 'https://adwords.google.com/aw/adgroups?campaignId={1}&__e={0}'
    },
    Groundtruth: {
        account: 'https://ads.groundtruth.com/organization/7215/account/{0}',
        campaign: 'https://ads.groundtruth.com/campaign/{1}'
    },
    TTD: {
        account: 'https://desk.thetradedesk.com/Advertisers/Detail/{0}',
        campaign: 'https://desk.thetradedesk.com/Campaigns/Detail/{1}?advertiser={0}'
    },
    DoubleClick: {
        account: 'https://desk.thetradedesk.com/Advertisers/Detail/{0}',
        campaign: 'https://www.google.com/dfa/trafficking/#/accounts/{0}/campaigns/{1}/explorer?statuses=0;2'
    }
};

class PlatformService {
    static getPlatformDeepLink(rowData, fieldValue, platform) {
        const platformLink = PLATFORM_LINKS[platform];
        if (!platformLink) {
            return fieldValue;
        }

        let platformUrl;

        const campaignId = rowData.campaignId;
        if (campaignId) {
            platformUrl = platformLink.campaign.replace('{0}', rowData.id).replace('{1}', rowData.campaignId);
        } else {
            platformUrl = platformLink.account.replace('{0}', rowData.id);
        }

        return (
            <a href={platformUrl} onClick={this.onLinkClick} target="_blank" rel="noreferrer">
                {fieldValue}
            </a>
        );
    }
}

export default PlatformService;
