import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { Helmet } from 'react-helmet';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';

import { style } from '../../variables/Variables.jsx';

import dashboardRoutes from '../../routes/dashboard.jsx';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleNotificationClick = this.handleNotificationClick.bind(this);
        this.state = {
            _notificationSystem: null
        };
    }

    getNotification(level, position) {
        return {
            title: <span data-notify="icon" className="pe-7s-gift" />,
            message: (
                <div>
                    Welcome to <b>the new Conversion Logix Dashboard</b>!.
                </div>
            ),
            level: level,
            position: position,
            autoDismiss: 10
        };
    }

    getRandomNotificationLevel() {
        const color = Math.floor(Math.random() * 4 + 1);
        let level;

        switch (color) {
            case 1:
                level = 'success';
                break;
            case 2:
                level = 'warning';
                break;
            case 3:
                level = 'error';
                break;
            case 4:
                level = 'info';
                break;
            default:
                break;
        }

        return level;
    }

    handleNotificationClick(position) {
        this.state._notificationSystem.addNotification(
            this.getNotification(this.getRandomNotificationLevel(), position)
        );
    }

    componentDidMount() {
        this.setState({ _notificationSystem: this.refs.notificationSystem });
    }

    componentDidUpdate(e) {
        if (
            window.innerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf('nav-open') !== -1
        ) {
            document.documentElement.classList.toggle('nav-open');
        }
        if (e.history.action === 'PUSH') {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
    }

    render() {
        return (
            <div className="wrapper">
                <Helmet>
                    <title>Conversion Logix - Dashboard</title>
                </Helmet>
                <NotificationSystem ref="notificationSystem" style={style} />
                <Sidebar {...this.props} />
                <div id="main-panel" className="main-panel" ref="mainPanel">
                    <Header {...this.props} />
                    <Switch>
                        {dashboardRoutes.map((prop, key) => {
                            if (prop.collapse) {
                                return prop.views.map((prop, key) => {
                                    if (prop.name === 'Notifications') {
                                        return (
                                            <Route
                                                path={prop.path}
                                                key={key}
                                                render={routeProps => (
                                                    <prop.component
                                                        {...routeProps}
                                                        handleClick={this.handleNotificationClick}
                                                    />
                                                )}
                                            />
                                        );
                                    } else {
                                        return <Route path={prop.path} component={prop.component} key={key} />;
                                    }
                                });
                            } else {
                                if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />;
                                else return <Route path={prop.path} component={prop.component} key={key} />;
                            }
                        })}
                    </Switch>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Dashboard;
