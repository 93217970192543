import React, { Component } from 'react';
import DataTableRow from './DataTableRow';
import DataTableSummaryRow from '../../views/Reporting/DataTableSummaryRow';
import DataTableRowDetail from '../../views/Reporting/DataTableRowDetail';
import isEqual from 'lodash/isEqual';

class DataTableBody extends Component {
    constructor() {
        super();
        this.state = {
            open: {}
        };

        this.rows = [];
    }

    showDetailView = (name, index, value) => {
        const open = this.state.open;
        const currentRow = open[index];

        let isOpen = true;
        if (currentRow) {
            isOpen = !currentRow.isOpen;
        }

        open[index] = {
            isOpen: isOpen
        };

        this.setState({ open });

        this.props.onRowClicked && this.props.onRowClicked(value);
    };

    componentDidUpdate(prevProps) {
        if (isEqual(this.props, prevProps)) {
            return;
        }

        const rowIndices = Object.keys(this.state.open);
        const open = this.state.open;

        rowIndices.forEach(index => {
            open[index] = false;
        });
    }

    render() {
        return (
            <tbody>
                <DataTableSummaryRow
                    loadingData={this.props.loadingData}
                    filterOptions={this.props.filterOptions}
                    fieldsMap={this.props.fieldsMap}
                    isFieldHidden={this.props.isFieldHidden}
                    showingFields={this.props.showingFields}
                    tableHeaders={this.props.tableHeaders}
                />
                {this.props.data.map((prop, key) => {
                    return (
                        <React.Fragment key={key}>
                            <DataTableRow
                                key={key}
                                rowIndex={key}
                                data={prop}
                                fieldsMap={this.props.fieldsMap}
                                filterByFieldsMap={this.props.filterByFieldsMap}
                                onClick={(name, index) => {
                                    this.showDetailView(name, index, prop);
                                }}
                                getCellContent={this.props.getCellContent}
                                isFieldHidden={this.props.isFieldHidden}
                                showingFields={this.props.showingFields}
                                appendActionBtn={this.props.appendActionBtn}
                                actionBtnRawHtml={this.props.actionBtnRawHtml}
                                actionBtnText={this.props.actionBtnText}
                                actionBtnCallback={this.props.actionBtnCallback}
                            />
                            <DataTableRowDetail
                                key={`${key}Detail`}
                                showDetailView={this.props.showDetailView}
                                rowIndex={key}
                                data={prop}
                                filterOptions={this.props.filterOptions}
                                graphableFields={this.props.graphableFields}
                                open={this.state.open}
                                loadingData={this.props.loadingData}
                                getCellContent={this.props.getCellContent}
                                isFieldHidden={this.props.isFieldHidden}
                                showingFields={this.props.showingFields}
                                tableHeaders={this.props.tableHeaders}
                            />
                        </React.Fragment>
                    );
                })}
            </tbody>
        );
    }
}

export default DataTableBody;
