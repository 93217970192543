import React, { Component } from 'react';

import { Grid, Row, Col } from 'react-bootstrap';
import Card from '../../components/Card/Card.jsx';

import AccountBuilderForm from './AccountBuilderForm';
import { CheckCircleFilled, CloseCircleFilled, PlusOutlined} from '@ant-design/icons';
import { Alert, Badge, Button, Descriptions, Typography, Input, Tooltip, Checkbox, message } from 'antd';
import './Result/index.css';
import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';

const { Text } = Typography;

const gtmTemplates = {
    'rtTag': {
        name: 'Xandr - Retargeting',
        firingTriggerId: ["2147479553"],       
        tagFiringOption: "oncePerEvent", 
        type:"html",
        parameter: [{
            key:'html',
            type:'template'
        }]
    }, // RT Audience Segment Pixel
    'goalTag': {
        name: 'Xandr - Post View Goals',                    
        firingTriggerId: ["2147479553"],       
        tagFiringOption: "oncePerEvent", 
        type:"html",
        parameter:[ {
            key:'html',
            type:'template'
        }]
    },
    'homepageTag': {
        name: 'Xandr - View Through Homepage',
        firingTriggerId: ["2147479553"],       
        tagFiringOption: "oncePerEvent",
        type:"html",
        parameter: [{
            key:'html',
            type:'template'
        }]
    }
}

const categoryList = {
    CM: 'ConcessionManager',
    SG: 'ScheduleGenie',
    BPN: 'BPN',
    CU: 'CallUs',
    WL: 'Web - Lead',
    WI: 'Web - Interaction',
    WS: 'Web - Social',
    IC: 'IncomeCalculator',
    TCC: 'TCC',
    CUSTOM: 'Custom',
    ALL: '*'
};

const actionList = {
    APPLICATIONCOMPLETE: 'Application Complete',
    CONTACTFORM: 'Contact Form',
    CLICKTOEMAIL: 'Click to Email',
    CLICKTOCALL: 'Click to Call',
    CALLSFROMWEBSITE: 'Click to Call',
    CLICKTOCHAT: 'Click to Chat',
    CLICKTOSHARE: 'Click to Share',
    APPLYNOW: 'Apply Now',
    CHECKAVILABILITY: 'Check Availability',
    OPENWIDGET: 'Open Widget',
    CLICKINCOMECALCULATOR: 'Click Income Calculator',
    CREATELEAD: 'Create Lead',
    FIRSTINTERACTION: 'First Interaction',
    SELECTDATE: 'Select Date',
    CONFIRMATION: 'Confirmation',
    SELECTTIMESLOT: 'Select Time Slot',
    RECEIVECALENDARDATA: 'Receive Calendar Data',
    CLICKOFFERCTA: 'Click Offer CTA',
    SELECTEDPARAMS: 'Selected Params',
    CHAT: 'Chat',
    PROMO: 'Promo',
    DIRECTIONS: 'Directions',
    BASIC: 'Basic',
    FINANCIAL: 'Financial',
    PAYMENT: 'Payment',
    SCROLL90:'Scroll 90',
    SCROLL90:'Scroll 50',
    NUDGE:'Nudge',
    FORMSUBMIT:'Form Submit',
    APPLICATIONSUBMIT:'Application Submit',
    FLOORPLANS:'Floorplans',
    TOURLEAD:'Ave5 Tour',
    VIRTUALTOUR:'Virtual Tour',
};

export default class BuildAccount extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = (props.location.state)?{ response: props.location.state }:{};
        this.state.accountGTM = '';

        this.handleFormResponse = this.handleFormResponse.bind(this);

        const createdElements = (props.location.state && props.location.state.created_elements) ? props.location.state.created_elements : null;
        this.state.existingFlag = !!createdElements && props.location.state && props.location.state.existing_flag
        // tagsCollecting
        this.state.pixelTagsList = []
        this.state.gtmUpdate = false

        this.state = {...this.state, ...this.initialStates(createdElements)}
    }

    initialStates = (createdElements) => {
        const state = {allTags:{},saveStatus:{},homepagePixel:false,rtPixel:false,goalTagsList:[]}
        if (createdElements) {
            if (createdElements.segment && createdElements.segment.segment) {
                state.allTags.rtTag = `<!-- Segment Pixel - RT Audience - DO NOT MODIFY -->
<script src="https://secure.adnxs.com/seg?t=1&add=`+createdElements.segment.segment.id+`" type="text/javascript"></script>
<!-- End of Segment Pixel -->`
                state.saveStatus.rtTag = false
                state.rtPixel = createdElements.segment.segment
            }

            createdElements.pixels.forEach((pixel, i) =>  {
                if (!pixel.pixel) {
                    return
                }
                if (pixel.pixel.name.includes('Homepage')){
                    state.allTags.homepageTag = `<!-- Conversion Pixel - Homepage - DO NOT MODIFY -->`+
                    `<script src="https://secure.adnxs.com/px?id=`+pixel.pixel.id+`&t=1" type="text/javascript"></script>`+
                    `<!-- End of Conversion Pixel -->`
                    state.saveStatus.homepageTag = false
                    state.homepagePixel = pixel.pixel
                }else{
                    const pixelSigns = this.generatePixelScript(pixel.pixel)
                    state.goalTagsList.push({pixelActive:true, pixelData:pixelSigns, pixel:pixel.pixel})
                }
            })

            if (state.goalTagsList.length > 0) {
                state.allTags.goalTag = this.generateGoalTAG(state.goalTagsList)
                state.saveStatus.goalTag = false
            }
        }  
        return state
    }
    

    standartizeText = function(inputText) {
        return inputText.replace(/[\s-]/gu, '').toUpperCase();
    };
    
    checkIfInList = function(inputText, listType) {
        let list = categoryList;
        switch (listType) {
            case 'category':
                list = categoryList;
                break;
            case 'action':
                list = actionList;
                break;
        }

        let modyfiedText = this.standartizeText(inputText);

        if (modyfiedText in list) {
            return list[modyfiedText];
        }

        let indexOfFoundValue = Object.values(list)
            .map(t => {
                return this.standartizeText(t);
            })
            .indexOf(modyfiedText);

        if (indexOfFoundValue > -1) {
            return inputText;
        }
        return false;
    };

    generatePixelScript = (pixelAnx) => {
        if (!pixelAnx) {
            return false
        }
        let goalsArray = pixelAnx.name.split('-').map(t => {
            return t.trim();
        });
        const id = pixelAnx.id;
        const paramList = ['category', 'action', 'label'];
        let categoryIndex = 0;
        let defaultPixel = {
            category: { value: '*', matchOn: { type: 'regex', value: '/(?:.|[\\r\\n])*/gim' }, isCustom: false },
            action: { value: '*', matchOn: { type: 'regex', value: '/(?:.|[\\r\\n])*/gim' }, isCustom: false },
            label: { value: '*', matchOn: { type: 'regex', value: '/(?:.|[\\r\\n])*/gim' }, isCustom: false },
            value: { value: '*', matchOn: { type: 'regex', value: '/(?:.|[\\r\\n])*/gim' }, isCustom: false },
            fire: { type: 'pixel', value: 'https://secure.adnxs.com/px?id=' + id + '&t=1' }
        };
        while (categoryIndex < goalsArray.length && !this.checkIfInList(goalsArray[categoryIndex], paramList[0])) {
            categoryIndex++;
        }
        if (categoryIndex === goalsArray.length) {
            categoryIndex = 0;
        }
        let paramIndex = 0;
        let value = '';
        while (categoryIndex < goalsArray.length && paramIndex < paramList.length) {
            value = this.checkIfInList(goalsArray[categoryIndex], paramList[paramIndex]) || goalsArray[categoryIndex];
            if (goalsArray.length === 1) {
                let valueC = 'Web - Lead';
                defaultPixel[paramList[paramIndex]].value = valueC;
                defaultPixel[paramList[paramIndex]].matchOn.value = '/^' + valueC + '$/';
                paramIndex++;
                value = this.checkIfInList(goalsArray[categoryIndex], paramList[paramIndex]) || goalsArray[categoryIndex];
            }
            defaultPixel[paramList[paramIndex]].value = value;
            if (value !== '*') {
                defaultPixel[paramList[paramIndex]].matchOn.value = '/^' + value + '$/';
            }
            categoryIndex++;
            paramIndex++;
        }
        return defaultPixel;
    }

    generateGoalTAG = (goalTagsList) => {
        const allPixelData = goalTagsList.filter(pixel => pixel.pixelActive).map(pixel => pixel.pixelData)
        const finalCodeString =
                '\t' +
                'window.aunclxEventDispatchHelper = (window.aunclxEventDispatchHelper || {});' +
                '\r\n' +
                '\t' +
                'window.aunclxEventDispatchHelper.ruleConfigArr = (window.aunclxEventDispatchHelper.ruleConfigArr || []);' +
                '\r\n' +
                '\t' +
                'var exportedConfigArr = ' +
                JSON.stringify(allPixelData) +
                ';' +
                '\r\n' +
                '\t' +
                'for (var x=0; x<exportedConfigArr.length; x++){' +
                '\r\n' +
                '\t' +
                '\t' +
                'window.aunclxEventDispatchHelper.ruleConfigArr.push(exportedConfigArr[x]);' +
                '\r\n' +
                '\t' +
                '}';

        const finalScriptTag = '<!-- aunclxEventDispatchHelper: Rule Config -->\r\n<script>' +
        '\r\n' +
        finalCodeString +
        '\r\n' +
        '</script>\r\n<!-- /Rule Config (aunclxEventDispatchHelper) -->' +
        //+ '\r\n' + mainCodeEscaped
        '';
        return finalScriptTag;
    } 

    handleFormResponse(response) {
        const created_elements = response.created_elements;
        this.setState({...{ response },...this.initialStates(created_elements)});
    }

    handleSendGtm(item) {
        const { accountGTM } = this.state;
        if (!(item in gtmTemplates)){
            message.error(`No data for ${item} template found`);
            return
        }

        const disassemble = /accounts\/(\d{9,12})\/containers\/(\d{8,10})\//.exec(accountGTM)
        if (!disassemble || disassemble.length < 3) {
            message.error(`The GTM should contain text in format: ...accounts/1111111111/containers/2222222222.....`);
            return
        }
        const accountId = disassemble[1]
        const containerId = disassemble[2]
        const tagData = gtmTemplates[item]
        tagData.parameter[0].value =this.state.allTags[item]
        this.setState({gtmUpdate:true})
        const requestObject ={
            method: 'POST',
            headers: {
                ...authHeader(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                accountId: accountId,
                containerId: containerId,
                tagData}
                ),
        }
        
        fetch(`${API_BASE_URL}gtm/createtag/`, requestObject)
        .then(async response => response.json())
        .then(data =>{
            if (data.error){
                message.error(`${data.error}`);
                return
            }
            message.success(`Tag ${item} created`);
        })
        .catch(error => {
            message.error(`${error}`);
            console.error('Error:', error)
        }).finally(()=>{
            this.setState({gtmUpdate:false})
        })

    }

    getClassNames() {
        let classes = 'content';

        if (this.state.loadingData) {
            classes += ' clx-loading';
        }

        return classes;
    }

    handleActiveCheckbox = (id) => {
        const goalTagsList = this.state.goalTagsList.map(pixel => {
            if (pixel.pixel.id === id) {
                pixel.pixelActive = !pixel.pixelActive
            }
            return pixel
        })
        const allTags = this.state.allTags
        allTags.goalTag = this.generateGoalTAG(this.state.goalTagsList)
        this.setState({goalTagsList,allTags})
    }

    validateGTMstring = () => {
        return /(accounts\/\d{9,12}\/containers\/\d{8,10})/.test(this.state.accountGTM);
    }
            
    render() {
        const { response, allTags, accountGTM, goalTagsList, existingFlag, gtmUpdate} = this.state;
    
        const labelStyle = {fontWeight:600};
        const contentStyle = {color:'blue',backgroundColor:'white'}
        const createdElements = response ? response.created_elements : null;

        let errors = [];

        const  accountGTMflag = this.validateGTMstring()
        if (createdElements) {
            errors = Object.keys(createdElements)
                .reduce((accumulator, currentValue) => {
                    if (createdElements[currentValue].length) {
                        accumulator.push(...createdElements[currentValue]);
                    } else {
                        accumulator.push(createdElements[currentValue]);
                    }
                    return accumulator;
                }, [])
                .filter(response => response.error);
        }

        const extra = response ? (
            <React.Fragment>
                {errors.length > 0 ? (
                    <React.Fragment>
                        <Alert
                            message="Some account elements were not created. Please add them manually in the console."
                            description={errors.map((error, i) => (
                                <div key={i}>
                                    <Badge status="error" />
                                    <strong>{error.service}:</strong> {error.error} ({error.error_code})
                                </div>
                            ))}
                            type="warning"
                            showIcon
                        />
                        <br />
                    </React.Fragment>
                ) : null}
                <Input
                    className="input-affix"
                    value={accountGTM}
                    onChange={e => {
                        this.setState({ accountGTM: e.target.value });
                    }} span={3}
                    placeholder="Enter GTM URL. Example: https://tagmanager.google.com/#/container/accounts/1338223604/containers/13313482/"
                    // disabled={!this.state.addToFolder}
                    suffix={( accountGTMflag || gtmUpdate)?<CheckCircleFilled style={{color:'green'}}/>:<CloseCircleFilled style={{color:'red'}}/>}
                />
                <Descriptions title="Account Info" bordered style={{ marginBottom: 16 }} contentStyle={contentStyle} labelStyle={labelStyle}>
                    <Descriptions.Item label="Account Name" span={2}>
                        {createdElements.advertiser.advertiser.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Advertiser ID">
                        <Text copyable>{createdElements.advertiser.advertiser.id}</Text>
                    </Descriptions.Item>
                </Descriptions>
                
                {this.state.rtPixel ? (
                    <Descriptions title="Segments" bordered style={{ marginBottom: 16 }} contentStyle={contentStyle} labelStyle={labelStyle} column={{sm:4,sm:8,md:16,lg:16}}>
                        <Descriptions.Item label="RT Audience Segment Pixel ID" span={2}>
                            <Text style={{textWrap:'nowrap'}} copyable>{this.state.rtPixel.id}</Text>
                        </Descriptions.Item>
                        <Descriptions.Item span={2}>
                            <Text copyable>{allTags.rtTag}</Text>
                        </Descriptions.Item>
                        <Descriptions.Item span={1}>
                        <Button 
                            disabled={!accountGTMflag} 
                            style={{textWrap:'nowrap'}} 
                            span={1} 
                            type="primary"
                            loading={gtmUpdate}
                            target="_blank" 
                            onClick={() => this.handleSendGtm('rtTag', allTags.rtTag)}>Create GTM Tag</Button>
                        </Descriptions.Item>
                    </Descriptions>
                ) : null}
                
                {this.state.homepagePixel ? (
                        <Descriptions title="Homepage" bordered style={{ marginBottom: 16 }} contentStyle={contentStyle} labelStyle={labelStyle} column={{sm:4,sm:8,md:16,lg:16}}>
                            <Descriptions.Item label={this.state.homepagePixel.name+` Pixel ID`} span={2} key={this.state.homepagePixel.id}>
                            <Text copyable style={{textWrap:'nowrap'}}>{this.state.homepagePixel.id}</Text>
                            </Descriptions.Item>
                            <Descriptions.Item span={2}>
                            <Text copyable>{allTags.homepageTag}</Text>
                            </Descriptions.Item>
                            <Descriptions.Item span={1}>
                            <Button 
                                disabled={!accountGTMflag}  
                                span={1} 
                                type="primary"
                                loading={gtmUpdate}
                                style={{textWrap:'nowrap'}}
                                target="_blank" 
                                onClick={() => this.handleSendGtm('homepageTag', allTags.homepageTag)}>Create GTM Tag</Button>
                            </Descriptions.Item>
                        </Descriptions>
                    ) : null
                }    
                <Descriptions title="Pixels" bordered style={{ marginBottom: 16 }} contentStyle={contentStyle} labelStyle={labelStyle}>
                    {goalTagsList.map((pixel, i) =>{
                            return <React.Fragment>
                                <Descriptions.Item label={`${pixel.pixel.name} Pixel ID`} span={1} key={pixel.pixel.id}>
                                    <Text style={{textWrap:'nowrap'}}>{pixel.pixelData.category.value} {pixel.pixelData.action.value}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item >
                                    <Text copyable span={2} style={{textWrap:'nowrap'}}>https://secure.adnxs.com/px?id={pixel.pixel.id}&t=1</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={1} >
                                    <Checkbox checked={pixel.pixelActive} onChange={(w)=>{this.handleActiveCheckbox(pixel.pixel.id)}}>Enabled</Checkbox>
                                </Descriptions.Item>                                
                            </React.Fragment>
                        }
                    )}
                    {'goalTag' in allTags ? (
                        <React.Fragment>
                            <Descriptions.Item span={2} >
                                <Text copyable>{this.state.allTags.goalTag}</Text>
                            </Descriptions.Item>
                            <Descriptions.Item span={1} >
                                <Button disabled={!accountGTMflag} style={{textWrap:'nowrap'}} span={1} type="primary" htmlType="submit" loading={gtmUpdate}
                                    target="_blank" onClick={() => this.handleSendGtm('goalTag',allTags.goalTag)}>Create GTM Post View Goals</Button>
                            </Descriptions.Item>
                        </React.Fragment>
                    ) : null}
                </Descriptions>
                
            </React.Fragment>
        ) : null;

        return (
            <div className={this.getClassNames()}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={(!existingFlag) ? 'Xandr(AppNexus) Account Builder'  : 'Xandr(AppNexus) Account GTAG Generator'}
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Grid fluid>
                                        {!this.state.response ? (
                                            <div id="form">
                                                <AccountBuilderForm onResponseChange={this.handleFormResponse} />
                                            </div>
                                        ) : (
                                            <div className="result">
                                                { (!existingFlag) ? 
                                                <React.Fragment>
                                                    <div className="icon">
                                                        {<CheckCircleFilled className="success" />}
                                                    </div>
                                                    <h2>{'Account Created'}</h2>
                                                    <div className="description">
                                                        {
                                                            'You have successfully created a new advertiser account on Xandr'
                                                        }
                                                    </div>
                                                </React.Fragment>
                                                : <h2>{'Account Selected'}</h2>
                                                }
                                                <div className="extra">{extra}</div> 
                                                <Button
                                                    type="link"
                                                    href={`https://invest.xandr.com/bmw/advertisers?advertiser_id=${this.state.response.created_elements.advertiser.advertiser.id}`}
                                                    target="_blank"
                                                >
                                                    View account on Xandr Console
                                                </Button>
                                                <br />
                                                <br />
                                                <Button
                                                    type="primary"
                                                    icon={<PlusOutlined />}
                                                    onClick={() => this.setState({ response: null })}
                                                >
                                                    Build Another Account
                                                </Button>
                                            </div>
                                        )}
                                    </Grid>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}
