import * as React from 'react';
import * as moment from 'moment';
import { range } from 'lodash';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';

// PROPS
//     id: string;
//     label: string;
//     allowEmpty?: boolean;
//     placeholder?: string;
//     step?: number;
//     startTime?: string;
//     endTime?: string;
//     hour24?: boolean;
//     onChangeDayTimePicker: (id: string, value: string) => void;
//      defaultValue: string

export class DayTimePicker extends React.Component {
    componentDidMount() {
        const value = this.props.value;
        if (value === undefined && this.props.defaultValue) {
            this.onChange(this.props.defaultValue);
        }
    }

    render() {
        const allowEmpty = !!this.props.allowEmpty;
        const defaultStep = 30;
        const minute = 60;
        const step = minute * (this.props.step || defaultStep);
        const start = this.props.startTime
            ? moment(this.props.startTime, 'hh:mm a').unix()
            : moment().startOf('day').unix();
        const end = this.props.endTime ? moment(this.props.endTime, 'hh:mm a').unix() : moment().endOf('day').unix();

        let value = this.props.value;
        if (value === undefined) {
            value = this.props.defaultValue;
        }

        return (
            <Form.Item
                label={this.props.label}
                name={this.props.id}
                rules={[{ required: false, message: 'Please select time!' }]}
            >
                <Select onChange={this.onChange} value={value}>
                    {allowEmpty && <Select.Option value="">{this.props.placeholder || 'Closed'}</Select.Option>}
                    {this.getOptions(start, end, step).map((time, index) => (
                        <Select.Option key={index} value={time}>
                            {time}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        );
    }

    getOptions = (start, end, step) => {
        const hour24 = !!this.props.hour24;

        return range(start, end, step).map(time =>
            hour24 ? moment(time, 'X').format('HH:mm') : moment(time, 'X').format('hh:mma')
        );
    };

    onChange = value => {
        if (this.props.onChangeDayTimePicker) {
            this.props.onChangeDayTimePicker(this.props.id, value);
        }
    };
}

export default DayTimePicker;
