import { validateEmail } from '../../util/email';

const BADWORDS = [];

export default {
    badwordsValidator: {
        validator: (rule, value) => {
            //Do async validation to check if username already exists
            //Use setTimeout to emulate api call
            setTimeout(() => {
                if (
                    BADWORDS.reduce((sum, word) => {
                        let sumR = sum + value.indexOf(word);
                        return sumR;
                    }, 0)
                ) {
                    return Promise.reject(`Username "${value}" already exists.`)
                } else {
                    return Promise.resolve()
                }
            }, 1000);
        }
    },
    companyNameValidator : {
        required: true,
        validator: (rule, value) => {
            if (!!value && value.trim() !== '') {
                return Promise.resolve();
            } 
            return Promise.reject('Company Name is required.')
        }
    },
    numberValidator: {
        validator: (rule, value) => {
            if (!value) {
                return Promise.resolve();
            }

            if (/^[\d\.]+$/.test(value) && value.toString().length < 16) {
                return Promise.resolve();
            }

            return Promise.reject('Number is not correct.')
        }
    },
    phoneValidator: {
        required: false,
        validator: (rule, value) => {
            //^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$   US-numbers only
            if (!value || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{2}[\s.-]?\d{2}$/.test(value)) {
                return Promise.resolve();
            } else {
                return Promise.reject(' Phone number is not correct.')
            }
        }
    },
    zipValidator: {
        validator: (rule, value) => {
            if (typeof value === 'undefined' || value.trim() === '') {
                return Promise.reject('ZIP code is required')
            }
            if (/^[0-9]{5}$/.test(value)) {
                return Promise.resolve();
            } else {
                return Promise.reject(' ZIP code in 5-digit format')
            }
        }
    },
    emailValidator: {
        required: true,
        validator: (rule, value) => {
            if (!value) {
                return Promise.reject('Please, add one or more emails.')
            }

            const emails = value.split(/[,; ]/).filter(s => !!s);
            const validResults = emails.map(s => validateEmail(s)).filter(s => s);

            if (emails.length !== validResults.length) {
                return Promise.reject('Email address is not correct.')
            } else {
                return Promise.resolve();
            }
        }
    },
    crmEmailValidator: {
        required: false,
        validator: (rule, value) => {
            if (!value) {
                return Promise.reject('Please, add one or more CRM emails.')
            }

            const emails = value.split(/[,; ]/).filter(s => !!s);
            const validResults = emails.map(s => validateEmail(s)).filter(s => s);

            if (emails.length !== validResults.length) {
                return Promise.reject('Email address is not correct.')
            } else {
                return Promise.resolve();
            }
        }
    },
    oneEmailValidator: {
        validator: (rule, value) => {
            if (!value) {
                return Promise.reject('Please, add an email.')
            }

            if (validateEmail(value)) {
                return Promise.resolve();
            } else {
                return Promise.reject('Email address is not correct. ReplyTo field can have one e-mail only.')
            }
        }
    },
    required: {
        required: true,
        validator: (rule, value) => {
            if (!!value && value.trim() !== '') {
                return Promise.resolve();
            } else {
                return Promise.reject('Field is required.')
            }
        }
    },
    stateValidator: {
        validator: (rule, value) => {
            if (typeof value === 'undefined') {
                return Promise.reject('Please, select state value.')
            }
           return Promise.resolve();   
        },
    },
    oneColorValidator: {
        validator: (rule, value) => {
            if (typeof value === 'undefined') {
                return Promise.reject('Please, choose a widget color.')
            }
           return Promise.resolve();   
        },
    },
    timeZoneValidator: {
        validator: (rule, value) => {
            if (typeof value === 'undefined') {
                return Promise.reject(`Please, select the time zone you're in.`)
            }
           return Promise.resolve();   
        },
    },

    salesRepValidator: {
        required: true,
        validator: (rule, value) => {
            if (typeof value === 'undefined') {
                return Promise.reject(`Please, select an account representative.`)
            }
           return Promise.resolve();   
        },
    },
    
    urlValidator: {
        validator: (rule, value) => {
            if (typeof value === 'undefined' || value.trim() === '') {
                return Promise.reject('Website URL required.')
            }
            if (value.slice(0, 7) !== 'http://' && value.slice(0, 8) !== 'https://') {
                return Promise.reject('URL should start with "http://" or "https://"')
            }
            return Promise.resolve();
        }
    },
    callToActionValidator: {
        validator: (rule, value) => {
            if (!value) {
                return Promise.resolve();
            }

            if (value.toString().length <= 25) {
                return Promise.resolve();
            }

            return Promise.reject('25 character limit')
        }
    },
    descriptionValidator: {
        validator: (rule, value) => {
            if (!value) {
                return Promise.resolve();
            }

            if (value.toString().length <= 70) {
                return Promise.resolve();
            }

            return Promise.reject('70 character limit')
        }
    },
    disclaimerValidator: {
        validator: (rule, value) => {
            if (!value) {
                return Promise.resolve();
            }

            if (value.toString().length <= 500) {
                return Promise.resolve();
            }

            return Promise.reject('500 character limit')
        }
    },
};