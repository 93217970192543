import axios from 'axios';
import { authHeader, axiosGetWithAuth, axiosPostWithAuth } from '../helpers/auth-header';
import { BASE_URL } from '../variables/Variables';

export const UserService = {
    login,
    logout,
    createUser,
    updateUser,
    deleteUser,
    updateUserRole,
    getAll,
    getInfoFromStorage,
    getRoleFromStorage,
    getRoleSchema,
    resetPassword,
    sendPassResetEmail,
    checkJwtValidity
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${BASE_URL}/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then((user) => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function resetPassword(userId, password, token) {
    return axiosUserResponseWrap(axios.post(`${BASE_URL}/users/resetPassword`, {
        token,
        userId,
        newPass: password
    }));
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('role');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${BASE_URL}/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function getInfoFromStorage() {
    try {
        return JSON.parse(localStorage.getItem('user'));
    } catch (e) {
        return false;
    }
}

function refreshRoleLocalStorage() {
    const url = `${BASE_URL}/users/whoami`;
    return new Promise((resolve, reject) => {
        axiosGetWithAuth(url)
            .then((response) => {
                localStorage.setItem('role', JSON.stringify(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

async function getRoleFromStorage() {
    let roleInfo = false;
    try {
        roleInfo = JSON.parse(localStorage.getItem('role'));
    } catch (error) {
        console.error('Error parsing from localStorage', error);
    }

    if (!roleInfo) {
        // Try to update
        try {
            roleInfo = await refreshRoleLocalStorage();
        } catch (error) {
            console.error('Error getting information about user!', error);
        }
    }

    return roleInfo;
}

/**
 * This is guarded on the backend
 * @param {number} userId - User to delete
 * @returns {Promise} - Backend response
 */
function deleteUser(userId) {
    const url = `${BASE_URL}/users/${userId}`;
    const axiosPromise = axios.delete(url, {
        headers: authHeader()
    });
    return axiosUserResponseWrap(axiosPromise);
}

function updateUserRole(userId, roleId) {
    const url = `${BASE_URL}/users/changeRole`;
    const payload = {
        userId,
        roleId
    };
    const axiosPromise = axios.post(url, payload, {
        headers: authHeader()
    });
    return axiosUserResponseWrap(axiosPromise);
}

/**
 * This gets role schema (as JSON) from backend, via endpoint
 * We could reduce an API call here if we share schema file between backend and frontend
 */
async function getRoleSchema() {
    const url = `${BASE_URL}/users/roles`;
    const result = await axios.get(url, {
        headers: authHeader()
    });
    return result.data;
}

async function axiosUserResponseWrap(axiosPromise) {
    try {
        const result = await axiosPromise;
        return result.data;
    } catch (error) {
        return {
            success: false,
            permIssue: false,
            msg: error.toString()
        };
    }
}

/**
 * This function will perform an ajax call to check the validity of the stored JWT
 * If invalid, the JWT is dropped from storage
 * This is necessary since the switch to sessions / revoking JWTs
 */
async function checkJwtValidity() {
    let success = false;
    try {
        const res = await axiosGetWithAuth(`${BASE_URL}/users/validateJwt`);
        if (res.data.success === true) {
            success = true;
        }
    }
    catch (error) {
        success = false;
    }

    if (!success) {
        logout();
        return false;
    }

    return true;
}

/**
 * Send a password reset email
 * @param {string} email - User email to send for
 */
async function sendPassResetEmail(email) {
    const resetEndpoint = `${BASE_URL}/users/sendPassResetEmail`;
    const response = await axios.post(resetEndpoint, {
        email
    });

    return !!response.data.success;
}

/**
 * Creates a new user. Guarded on the backend
 * @param {Object<string,any>} userObj - User to create
 */
async function createUser(userObj, password) {
    const endpoint = `${BASE_URL}/users/create`;
    return axiosUserResponseWrap(axiosPostWithAuth(endpoint, {
        user: userObj,
        password
    }));
}

async function updateUser(userObj) {
    const endpoint = `${BASE_URL}/users/update`;
    return axiosUserResponseWrap(axiosPostWithAuth(endpoint, {
        user: userObj
    }));
}
