// @ts-check
import React, { Component } from 'react';
import { Grid, Row, Col, Table } from 'react-bootstrap';
import axios from 'axios';

import Card from '../../components/Card/Card.jsx';
import DataTableBody from '../../components/Table/DataTableBody';
import DataTableFooter from '../../components/Table/DataTableFooter';
import DataTableHeader from '../../components/Table/DataTableHeader';
import moment from 'moment';
import { BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';
import ReportingFilterService from '../Reporting/services/reporting-filter.service';
import ReportingConstants from '../Reporting/constants/reporting.constants';
import TableConstants from '../../components/Table/constants/table.constants';
import UserManagementConstants from './constants/user-management.constants';
import { UserService } from '../../services/user.service';
import { UserEditModal } from './UserEditorModal';
import { Button } from 'antd';
import { cloneDeep } from 'lodash';

function getMockUser() {
    return {
        id: -1,
        first_name: '',
        last_name: '',
        role_name: 'User',
        username: '',
        role: {
            canCreateUsers: false,
            canDeleteUsers: false,
            canEditRoles: false,
            canResetPasswords: false,
            displayName: 'User',
            id: 1,
            name: 'user'
        }
    };
}

class UserManagement extends Component {
    constructor() {
        super();

        this.state = {
            usersDataTableHeaders: [],
            usersData: [],
            filterOptions: {
                account: '',
                dateRange: {
                    startDate: moment()
                        .startOf('month')
                        .format(ReportingConstants.DATE_STRING_FORMAT),
                    endDate: moment()
                        .endOf('month')
                        .format(ReportingConstants.DATE_STRING_FORMAT)
                },
                page: TableConstants.INITIAL_PAGE_INDEX,
                orderByAscending: true
            },
            editOptions: {
                canDelete: false,
                canEdit: false
            },
            userEditor: {
                selectedUser: getMockUser(),
                selectedUserOriginal: getMockUser(),
                isNewUser: false,
                show: false,
                isProcessing: false
            },
            self: UserService.getInfoFromStorage().user,
            roleSchema: {}
        };

        this.getData();
    }

    removeUserFromTable(userId) {
        const updatedData = this.state.usersData.filter((user) => {
            return user.id !== userId;
        });
        this.setState({
            usersData: updatedData
        });
    }

    updateUserInTable(user) {
        let found = false;
        // Shallow copy
        const tableData = this.state.usersData.slice();
        for (let x=0; x<tableData.length; x++) {
            const tableUser = tableData[x];
            if (tableUser.id === user.id) {
                tableData[x] = user;
                found = true;
                break;
            }
        }
        if (found) {
            this.setState({
                usersData: tableData
            });
            return true;
        }
        return false; // not found in table
    }

    closeUserEditor() {
        this.setState({
            userEditor: {
                selectedUser: getMockUser(),
                selectedUserOriginal: getMockUser(),
                isNewUser: false,
                show: false,
                isProcessing: false
            }
        });
    }

    openEditorForNewUser() {
        this.setState({
            userEditor: {
                selectedUser: getMockUser(),
                selectedUserOriginal: getMockUser(),
                isNewUser: true,
                show: true,
                isProcessing: false
            }
        });
    }

    async performUserAction(user, action, data) {
        if (action === 'delete') {
            const result = await UserService.deleteUser(user.id);
            if (result.success){
                this.removeUserFromTable(user.id);
            }
            return result;
        }
        if (action === 'update') {
            // In this case, data should be the ORIGINAL user (or we could just pick it off state)
            const result = await UserService.updateUser(user);
            if (result.success){
                // Server will return *true* user object that it performed the update with
                user = result.updatedUserObj;
                // Only special thing here is that user.role_name is not stored directly, needs to be looked up
                user.role_name = this.state.roleSchema[user.role.id].displayName;
                // Update user in table without page refresh!
                this.updateUserInTable(user);
                return {
                    success: true,
                    msg: 'User updated!'
                };
            }
            return {
                success: false,
                msg: result.msg
            }
        }
        if (action === 'create') {
            // In this case, data should be the password to use
            const result = await UserService.createUser(user, data);
            if (result.success){
                user.id = result.id;
                // Update user in table without page refresh!
                this.state.usersData.push(user);
                return {
                    success: true,
                    msg: 'User created!'
                };
            }
            return {
                success: false,
                msg: result.msg
            }
        }
        if (action === 'changeRole') {
            const roleId = data;
            const result = await UserService.updateUserRole(user.id, roleId);
            if (result.success){
                // Update user in data table without page refresh
                const updatedRole = this.state.roleSchema[roleId];
                user.role_id = roleId;
                user.role_name = updatedRole.displayName;
                user.role = updatedRole;
            }
            return result;
        }
        if (action === 'resetPass') {
            const result = await UserService.sendPassResetEmail(user.username);
            if (result){
                return {
                    success: true,
                    msg: 'Email sent!'
                }
            }
            return {
                success: false,
                msg: 'Something went wrong. Maybe an email has already been sent?'
            }
        }
        return {
            success: false,
            msg: 'unknown action'
        }
    }

    async getData() {
        const url = UserManagementConstants.GET_USERS_BASE_API;

        this.setState({
            loadingData: true
        });

        let showEditButton = false;
        const roleSchema = await UserService.getRoleSchema();
        const roleInfo = await UserService.getRoleFromStorage();
        if (roleInfo){
            showEditButton = roleInfo.role.canEditRoles || roleInfo.role.canResetPasswords || roleInfo.role.canDeleteUsers;
            this.setState({
                editOptions: {
                    canDelete: roleInfo.role.canDeleteUsers,
                    canEdit: showEditButton
                },
                roleSchema: roleSchema
            });
        }
        else {
            this.setState({
                roleSchema: roleSchema
            });
        }

        return axios
            .get(`${BASE_URL}${url}`, {
                params: JSON.stringify(this.state.filterOptions),
                headers: authHeader()
            })
            .then(response => {
                const tableHeaders = response.data[UserManagementConstants.DATA_TYPE_INDEX.DataTableHeaders];
                const users = response.data[UserManagementConstants.DATA_TYPE_INDEX.Data];
                if (showEditButton){
                    // Add column to end of table that will house the edit button
                    tableHeaders.push({
                        display: 'Edit User',
                        field: 'edit_button'
                    });
                }
                this.setState({
                    usersDataTableHeaders: tableHeaders,
                    usersData: users,
                    usersDataRecordCount: response.data[UserManagementConstants.DATA_TYPE_INDEX.Data].length,
                    loadingData: false
                });
            })
            .catch(error => {
                console.error('Error downloading reporting data!', error);
            });
    }

    getClassNames() {
        let classes = 'content reporting-component';

        if (this.state.loadingData) {
            classes += ' clx-loading';
        }

        return classes;
    }

    isFieldHidden(fieldsMap, index, showingFields) {
        return false;
    }

    render() {
        const userEditButtonHtml = `<button class="ant-btn btn-cl-blue">Edit User</button>`;
        return (
            <div className={this.getClassNames()}>
                <Grid fluid>
                    <Row style={{marginBottom:'12px'}}>
                        <Col md={6}>
                            {this.state.editOptions.canEdit &&
                                <Button
                                    className="btn-cl-red"
                                    onClick={evt => {
                                        this.openEditorForNewUser();
                                    }}
                                >
                                    + New User
                                </Button>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="User Management"
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <div>
                                        <Table striped hover>
                                            <DataTableHeader
                                                tableHeaders={this.state.usersDataTableHeaders}
                                                fieldsMap={this.state.usersDataTableHeaders}
                                                isFieldHidden={this.isFieldHidden}
                                                recordCount={this.state.usersDataRecordCount}
                                                orderBy={this.state.filterOptions.orderBy}
                                                orderByAscending={this.state.filterOptions.orderByAscending}
                                                onFilterSortBy={columnName => {
                                                    ReportingFilterService.sortBy(columnName, this);
                                                }}
                                            />
                                            <DataTableBody
                                                data={this.state.usersData}
                                                filterOptions={this.state.filterOptions}
                                                fieldsMap={this.state.usersDataTableHeaders}
                                                filterByFieldsMap={true}
                                                showDetailView={false}
                                                loadingData={this.state.loadingData}
                                                appendActionBtn={this.state.editOptions.canEdit}
                                                actionBtnRawHtml={userEditButtonHtml}
                                                actionBtnCallback={rowIndex => {
                                                    // do something
                                                    const user = this.state.usersData[rowIndex];
                                                    this.setState({
                                                        userEditor: {
                                                            selectedUser: cloneDeep(user),
                                                            selectedUserOriginal: user,
                                                            isNewUser: false,
                                                            show: true,
                                                            isProcessing: false
                                                        }
                                                    });
                                                }}
                                            />
                                            <DataTableFooter
                                                data={this.state.usersData}
                                                filterOptions={this.state.filterOptions}
                                                parentInstance={this}
                                            />
                                        </Table>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
                <UserEditModal
                    user={this.state.self}
                    userToEdit={this.state.userEditor.selectedUser}
                    isNewUser={this.state.userEditor.isNewUser}
                    userToEditOriginal={this.state.userEditor.selectedUserOriginal}
                    roleSchema={this.state.roleSchema}
                    show={this.state.userEditor.show}
                    isProcessing={this.state.userEditor.isProcessing}
                    editCallback={this.performUserAction.bind(this)}
                    closeCallback={this.closeUserEditor.bind(this)}
                />
            </div>
        );
    }
}

export default UserManagement;
