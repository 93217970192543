module.exports ={
 DATE_TIME_FORMAT : 'YYYY-MM-DD HH:mm',
 DATE_FORMAT : 'MM/DD/YYYY',
 DAY_FORMAT_GRAPH : 'MM/DD',
 DATE_FORMAT_SAVE : 'YYYY-MM-DD',
 DATE_FORMAT_SHORT : 'MM/DD',
 CAMPARSION_PERCENTATGE : 3.99,
 PACELIMIT : 40,
 PERCENTAGE_LIMIT : 0.038,
 GADS_MICRO_MULTIPLIER : 1000000
}