import React, { Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Checkbox, Divider } from 'antd';

function Neighborhoods(props) {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;

    const generateDefaultCopy = values => {
        let copy = 'Apartments for Rent';
        const neighborhood = values.label.split('-')[0];
        const proximity = getFieldValue(`${neighborhood}-proximity`) ? 'near' : 'in';

        // set value for headline 12
        if (values.name.includes('headline_12')) {
            copy = `Modern Apts ${proximity} ${neighborhood}`;
        }

        //set value for headling 13
        if (values.name.includes('headline_13')) {
            copy = `Apartments ${proximity} ${neighborhood}`;
        }

        if (copy.length > 30) {
            copy = copy.replace('Apartments', 'Apts');
        }

        if (copy.length > 30) {
            copy = copy.replace('Bedroom', 'Bed');
        }

        return copy;
    };

    const generateLabel = value => {
        const labels = [];

        labels.push(value + '-Headline 12');
        labels.push(value + '-Headline 13');

        return labels;
    };

    return (
        <Fragment>
            <Form.Item>
                {getFieldDecorator('neighborhood')(
                    <Select
                        placeholder="Add neighborhoods associated with the location..."
                        mode="tags"
                        style={{ width: '100%' }}
                        tokenSeparators={[',']}
                        dropdownStyle={{ display: 'none' }}
                    ></Select>
                )}
            </Form.Item>

            {getFieldValue('neighborhood') && getFieldValue('neighborhood').length > 0 && (
                <Fragment>
                    {getFieldValue('neighborhood').map((item, idx) => {
                        const label_12 = item + '-Headline 12';
                        const label_13 = item + '-Headline 13';

                        const item12 = {
                            label: label_12.split('|')[0],
                            name: label_12.toLowerCase().replace(/[\W_]/g, '_')
                        };
                        const item13 = {
                            label: label_13.split('|')[0],
                            name: label_13.toLowerCase().replace(/[\W_]/g, '_')
                        };

                        return (
                            <div key={item}>
                                <Form.Item label={item12.label} key={item12.name}>
                                    {getFieldDecorator(`${item12.name}`, {
                                        initialValue: generateDefaultCopy(item12),
                                        rules: [
                                            {
                                                max: 30
                                            }
                                        ]
                                    })(
                                        <Input
                                            //placeholder={item}
                                            suffix={`${
                                                getFieldValue(item12.name) ? getFieldValue(item12.name).length : '0'
                                            }/30`}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label={item13.label} key={item13.name}>
                                    {getFieldDecorator(`${label_13.toLowerCase().replace(/[\W_]/g, '_')}`, {
                                        initialValue: generateDefaultCopy(item13),
                                        rules: [
                                            {
                                                max: 30
                                            }
                                        ]
                                    })(
                                        <Input
                                            // placeholder={item}
                                            suffix={`${
                                                getFieldValue(item13.name) ? getFieldValue(item13.name).length : '0'
                                            }/30`}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label={`${item} Proximity`} key={`${item}-proximity`}>
                                    {getFieldDecorator(`${item}-proximity`, {
                                        rules: []
                                    })(<Checkbox checked={getFieldValue(`${item}-proximity`)} />)}
                                </Form.Item>
                                {idx + 1 !== getFieldValue('neighborhood').length && <Divider />}
                            </div>
                        );
                    })}
                </Fragment>
            )}
        </Fragment>
    );
}

export default Neighborhoods;
