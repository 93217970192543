import React, { Component } from 'react';

class DataTableHeader extends Component {
    getTableHeaderContent(columnInfo) {
        if (!this.props.orderBy || this.props.orderBy !== columnInfo.display) {
            return columnInfo.display;
        }

        const direction = this.props.orderByAscending ? 'up' : 'down';

        return (
            <div>
                <span>
                    {columnInfo.display}
                </span>
                <i className={`pe-7s-angle-${direction}`} />
            </div>
        );
    }

    getTableHeader(columnInfo) {
        return (
            <th key={columnInfo.display} onClick={() => this.props.onFilterSortBy(columnInfo.display)}>
                {this.getTableHeaderContent(columnInfo)}
            </th>
        );
    }

    render() {
        return (
            <thead>
                <tr>
                    <th colSpan={this.props.tableHeaders.length} className={'record-count'}>
                        <span className={'pull-right'}>
                            {this.props.recordCount} records returned
                        </span>
                    </th>
                </tr>
                <tr>
                    {this.props.tableHeaders.map(columnInfo => {
                        return this.props.isFieldHidden(
                            this.props.fieldsMap,
                            this.props.fieldsMap[columnInfo.display],
                            this.props.showingFields
                        )
                            ? <React.Fragment key={columnInfo.display} />
                            : this.getTableHeader(columnInfo);
                    })}
                </tr>
            </thead>
        );
    }
}

export default DataTableHeader;
