import React, { Component, Fragment, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { US_TIMEZONES, LOCATION_TARGETING, CAMPAIGN_TEMPLATES } from '../FormConstants';
import CampaignDatePicker from '../CampaignDatePicker';
import { Button, Divider, Input, Select, Collapse, Typography, Switch } from 'antd';
import addresser from 'addresser';
import GOOGLE_ADS_CURRENCIES from '../google_ads_currencies';

const { Option } = Select;
const SENIOR_LIVING = 'srl';

const { Panel } = Collapse;
const { Text } = Typography;

function LocationAndCampaign(props) {
    const [expanded, setIsExpanded] = useState(false);
    const [student, setStudent] = useState(false);
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;

    const verticalSelector = getFieldDecorator('vertical_id', {
        rules: [
            {
                required: true,
                message: 'Account vertical is required!'
            }
        ]
    })(
        <Select disabled={!getFieldValue('account_name')}>
            {[
                { name: 'auto', title: 'Automotive (Auto)' },
                { name: 'local', title: 'Non-Auto (Local)' },
                { name: 'res', title: 'Hotel/Apartment/Multi-Family (RES)' },
                { name: SENIOR_LIVING, title: 'Senior Living (SRL)' },
                { name: 'agency', title: 'Non-Auto (Agency)' }
            ].map(vertical => (
                <Option key={vertical.name} value={vertical.name}>
                    {vertical.title || vertical.name}
                </Option>
            ))}
        </Select>
    );

    const parentAccountSelector = getFieldDecorator('parent_account', {
        initialValue: '302-517-0280',
        rules: [
            {
                required: true,
                message: 'Parent Account is required!'
            }
        ]
    })(
        <Select
            disabled={!getFieldValue('account_name')}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {props.managerAccounts.map(item => (
                <Option key={item.id} value={item.id}>
                    {`${item.name} (${item.id})`}
                </Option>
            ))}
        </Select>
    );

    const timeZoneSelector = getFieldDecorator('time_zone', {
        rules: [
            {
                required: true,
                message: 'Time zone is required!'
            }
        ]
    })(
        <Select
            disabled={!getFieldValue('account_name')}
            showSearch
            placeholder="Select a timezone"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {US_TIMEZONES.map(item => (
                <Option key={item.value} value={item.value}>
                    {item.name}
                </Option>
            ))}
        </Select>
    );

    const currencySelector = getFieldDecorator('currency', {
        initialValue: 'USD',
        rules: [
            {
                required: true,
                message: 'Currency is required!'
            }
        ]
    })(
        <Select
            disabled={true}
            showSearch
            placeholder="Select a currency"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {GOOGLE_ADS_CURRENCIES.map(item => (
                <Option key={item.value} value={item.value}>
                    {item.name}
                </Option>
            ))}
        </Select>
    );

    const locationTargetingSelector = getFieldDecorator('location_targeting', {
        initialValue: 'radius_15',
        rules: [
            {
                required: true,
                message: 'Location targeting is required!'
            }
        ]
    })(
        <Select disabled={!getFieldValue('account_name')}>
            {LOCATION_TARGETING.map((item, idx) => (
                <Option key={item.value + idx} value={item.value}>
                    {item.name}
                </Option>
            ))}
        </Select>
    );

    const campaignTemplateSelector = getFieldDecorator('base_campaign_template', {
        initialValue: 'res',
        rules: [
            {
                required: true,
                message: 'Campaign Template is required!'
            }
        ]
    })(
        <Select
            disabled={!getFieldValue('account_name')}
            onSelect={value => {
                setFieldsValue({ base_campaign_template: value });
                setStudent(value === 'student');

                if (value === 'student') {
                    const locationName = getFieldValue('account_name').replace('Apartments', '');
                    setFieldsValue({
                        headline_1: `${locationName.trim()} Apartments`,
                        headline_2: 'Student Apartments For Rent'
                    });
                }
            }}
        >
            {CAMPAIGN_TEMPLATES.map((item, idx) => (
                <Option key={item.value + idx} value={item.value}>
                    {item.name}
                </Option>
            ))}
        </Select>
    );

    return (
        <Fragment>
            <Form.Item label="Parent Account">{parentAccountSelector}</Form.Item>
            <CampaignDatePicker form={props.form} />
            <Form.Item label="Vertical">{verticalSelector}</Form.Item>
            <Form.Item label="Campaign Template">{campaignTemplateSelector}</Form.Item>

            {getFieldValue('base_campaign_template') === 'res' && (
                <Form.Item label="Location Targeting">{locationTargetingSelector}</Form.Item>
            )}

            <Form.Item label="Account Name">
                {getFieldDecorator('account_name', {
                    rules: [
                        {
                            required: true,
                            message: 'Account name is required!'
                        }
                    ]
                })(<Input disabled={!getFieldValue('account_name')} />)}
            </Form.Item>

            {student && (
                <div>
                    <Form.Item label="School Name">
                        {getFieldDecorator('school', {
                            rules: [
                                {
                                    required: true,
                                    message: 'School name is required!'
                                }
                            ],
                            onChange: e => {
                                const shoolName = e.target.value;
                                setFieldsValue({
                                    headline_3: `Apts Near ${shoolName}`
                                });
                            }
                        })(<Input disabled={!getFieldValue('account_name')} />)}
                    </Form.Item>
                    <Form.Item label="School Abbreviation">
                        {getFieldDecorator('school_ab', {
                            rules: [
                                {
                                    required: true,
                                    message: 'School Abbreviation is required!'
                                }
                            ],
                            onChange: e => {
                                setFieldsValue({
                                    headline_4: `${e.target.value} Student Housing`
                                });
                            }
                        })(<Input disabled={!getFieldValue('account_name')} />)}
                    </Form.Item>
                </div>
            )}

            <Form.Item label="Street Address" hasFeedback>
                {getFieldDecorator('address', {
                    rules: [
                        {
                            required: true,
                            message: 'Please enter a valid address!'
                        },
                        {
                            validator: (rule, value, callback) => {
                                if (!value) {
                                    return;
                                }

                                value = value.trim();
                                let parsedAddress;
                                try {
                                    parsedAddress = addresser.parseAddress(value);
                                } catch (error) {
                                    console.error(error);
                                    return callback('There is an error in the address.');
                                }
                                return callback();
                            }
                        }
                    ]
                })(<Input disabled={!getFieldValue('account_name')} />)}
            </Form.Item>
            <Form.Item label="Time zone">{timeZoneSelector}</Form.Item>
            <Form.Item label="Phone Number" extra={'Please format like (800) 123-4567'}>
                {getFieldDecorator('phone', {
                    rules: [
                        {
                            required: true,
                            message: 'Phone number is required!'
                        }
                    ]
                })(
                    <Input
                        disabled={!getFieldValue('account_name')}
                        style={{ width: 150 }}
                        placeholder={'(800) 123-4567'}
                    />
                )}
            </Form.Item>
            <Form.Item label="Currency">{currencySelector}</Form.Item>
        </Fragment>
    );
}

export default LocationAndCampaign;
