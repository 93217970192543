import React, { Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Collapse, Typography, Checkbox, Col, Row } from 'antd';
import { FLOOR_PLANS, CARE_LEVELS, STUDENT_HOUSING, NON_BRAND_AD_COPY, CARE_LEVEL_COPY } from '../FormConstants';

const { Panel } = Collapse;
const { Text } = Typography;

const verticalAdGroupTypes = {
    res: FLOOR_PLANS,
    student: STUDENT_HOUSING,
    srl: CARE_LEVELS
};

const verticalAdGroupCopy = {
    res: NON_BRAND_AD_COPY,
    srl: CARE_LEVEL_COPY
};

function NonBrandAdGroups(props) {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const parsedAddress = props.parsedAddress;

    const generateDefaultCopy = values => {
        const copyList = verticalAdGroupCopy[getFieldValue('base_campaign_template')];
        let copy = copyList[values.name];

        if (!copy) {
            return 'Apartments for Rent';
        }

        if (parsedAddress && parsedAddress.placeName) {
            // set value for headline 12
            if (values.name.includes('headline_12') && getFieldValue('vertical_id') === 'res') {
                copy = copy + ` ${parsedAddress.placeName}`;
            }

            //set value for headling 13
            if (values.name.includes('headline_13')) {
                if (getFieldValue('vertical_id') === 'res') {
                    copy = `${parsedAddress.placeName} ` + copy;
                } else {
                    copy = copy + ` ${parsedAddress.placeName}`;
                }
            }
        }

        if (copy.length > 30) {
            copy = copy.replace('Apartments', 'Apts');
        }

        if (copy.length > 30) {
            copy = copy.replace('Bedroom', 'Bed');
        }

        return copy;
    };

    const generateLabel = value => {
        const labels = [];

        labels.push(value + '-Headline 12');
        labels.push(value + '-Headline 13');
        labels.push(value + '-Headline 14');

        if (value === '1 Bed') {
            labels.push('One Bed' + '-Headline 12');
            labels.push('One Bed' + '-Headline 13');
            labels.push('One Bed' + '-Headline 14');
        }
        if (value === '2 Bed') {
            labels.push('Two Bed' + '-Headline 12');
            labels.push('Two Bed' + '-Headline 13');
            labels.push('Two Bed' + '-Headline 14');
        }
        if (value === '3 Bed') {
            labels.push('Three Bed' + '-Headline 12');
            labels.push('Three Bed' + '-Headline 13');
            labels.push('Three Bed' + '-Headline 14');
        }
        if (value === '4 Bed') {
            labels.push('Four Bed' + '-Headline 12');
            labels.push('Four Bed' + '-Headline 13');
            labels.push('Four Bed' + '-Headline 14');
        }

        return labels;
    };

    const setHeadline2 = values => {
        const vert = getFieldValue('vertical_id');

        if (vert === 'srl') {
            return;
        }

        const adSet = new Set([]);
        const adGroups = vert === 'res' ? FLOOR_PLANS : vert === 'srl' ? CARE_LEVELS : STUDENT_HOUSING;
        let copy = 'Apartments for Rent';

        for (let fp of adGroups) {
            const v = fp.replace(' Bed', '');
            if (values.includes(fp)) {
                adSet.add(v);
            } else {
                adSet.delete(v);
            }
        }

        if (adSet.size === '0') {
            if (parsedAddress && parsedAddress.placeName) {
                copy = `Apartments in ${parsedAddress.placeName}`;
            }
        } else {
            const adgroup = Array.from(adSet).sort() || [];
            const last = adgroup.length > 1 ? adgroup[adgroup.length - 1] : undefined;

            let prefix = `${adgroup.length < 3 ? adgroup.join(' ') : adgroup.join(', ')} Bedroom `;

            if (last) {
                prefix = prefix.replace(last, '& ' + last);
            }

            if (parsedAddress && parsedAddress.placeName) {
                copy = `${adgroup.length ? prefix : ''}Apartments in ${parsedAddress.placeName}`;
            } else {
                copy = `${adgroup.length ? prefix : ''}Apartments for Rent`;
            }
        }

        if (copy.length > 30) {
            copy = copy.replace('Apartments', 'Apts');
        }

        if (copy.length > 30) {
            copy = copy.replace('Bedroom', 'Bed');
        }

        setFieldsValue({ headline_2: copy });
    };

    return (
        <Fragment>
            <Form.Item>
                <Text>Select an Ad Group...</Text>
                {getFieldDecorator('floor_plans')(
                    <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={values => {
                            generateDefaultCopy(values);
                            setHeadline2(values);
                        }}
                    >
                        <Row
                            style={{
                                width: '100%'
                            }}
                        >
                            {verticalAdGroupTypes[getFieldValue('base_campaign_template')].map(item => (
                                <Col
                                    key={item}
                                    span={6}
                                    style={{
                                        padding: '0px',
                                        marginTop: '6px',
                                        marginBottom: '6px'
                                    }}
                                >
                                    <Checkbox value={item}>{item}</Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                )}
            </Form.Item>

            {getFieldValue('floor_plans') && getFieldValue('floor_plans').length > 0 && (
                <React.Fragment>
                    {(getFieldValue('vertical_id') !== 'srl' ? FLOOR_PLANS : CARE_LEVELS)
                        .filter(item => {
                            return getFieldValue('floor_plans').includes(item);
                        })
                        .reduce((acc, cur) => {
                            const labels = generateLabel(cur);
                            acc.push(...labels);
                            return acc;
                        }, [])
                        .map(item => {
                            return {
                                label: item.split('|')[0],
                                name: item.toLowerCase().replace(/[\W_]/g, '_')
                            };
                        })
                        .map(item => (
                            <Form.Item label={item.label} key={item.name} extra={item.extra}>
                                {getFieldDecorator(`${item.name}`, {
                                    initialValue: generateDefaultCopy(item),
                                    rules: [
                                        {
                                            max: 30
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder={item.placeholder}
                                        suffix={`${
                                            getFieldValue(item.name) ? getFieldValue(item.name).length : '0'
                                        }/30`}
                                    />
                                )}
                            </Form.Item>
                        ))}
                </React.Fragment>
            )}
        </Fragment>
    );
}

export default NonBrandAdGroups;
