export const templateLabelsTccOnboarding = {
    address1: 'Address 1',
    address2: 'Address 2',
    ami_one: 'If your community is subsidized, what are the first AMI value you subsidize for?',
    ami_three: 'If your community is subsidized, what are the third AMI value you subsidize for?',
    ami_two: 'If your community is subsidized, what are the second AMI value you subsidize for?',
    appointment_gap_schedulegenie: 'How much time should there be between appointment start times?',
    appointment_how_soon_schedulegenie: 'How soon can a prospect schedule an appointment?',
    appointment_long_schedulegenie: 'How long should each appointment be?',
    appointment_quantity_schedulegenie: 'How many appointments are available for each time slot?',
    call_to_action_concessionmanager: 'Call To Action Button (limited to 25 characters)',
    city: 'City',
    color_option: 'Default application module color',
    company_name: 'Community name',
    concessionmanager: 'Concession Manager',
    spotlight: 'Spotlight',
    lead_nurturing_feature: 'Lead Nurturing',
    lead_nurturing_feature_label: 'Enable default Lead Nurturing drip schedules for leads generated by following applications',
    lead_nurturing_feature_spotlight: 'Spotlight',
    lead_nurturing_feature_schedulegenie: 'Schedule Genie',
    contacts_email: 'Contact Email',
    contacts_first: 'Contact First Name',
    contacts_last: 'Contact Last Name',
    crm_email: 'Who receives email notification of a new lead?',
    crm_email_setting: 'CRM, LMS, or PMS Property Website Email',
    crm_type: 'CRM, LMS, or PMS Type',
    custom_email_text_concessionmanager: 'Customer Email Notification Text (limited to 200 characters)',
    custom_email_text_schedulegenie: 'Custom email text to prospect:',
    description_concessionmanager: 'Description (limited to 65 characters)',
    disclaimer_incomecalculator: 'Disclaimer Content (if any):',
    entrata_property_id: 'Property ID',
    floorplan1_badrooms_incomecalculator: 'Bedrooms for Floorplan 1',
    floorplan1_bathrooms_incomecalculator: 'Bathrooms for Floorplan 1',
    floorplan1_market_rent_incomecalculator: 'Market rent for Floorplan 1',
    floorplan1_max_allowed_value_1_incomecalculator: 'Maximum Allowable Rent for AMI 1',
    floorplan1_max_allowed_value_2_incomecalculator: 'Maximum Allowable Rent for AMI 2',
    floorplan1_max_allowed_value_3_incomecalculator: 'Maximum Allowable Rent for AMI 3',
    floorplan1_name_incomecalculator: 'Floorplan 1 name',
    floorplan2_badrooms_incomecalculator: 'Bedrooms for Floorplan 2',
    floorplan2_bathrooms_incomecalculator: 'Bathrooms for Floorplan 2',
    floorplan2_market_rent_incomecalculator: 'Market rent for Floorplan 2',
    floorplan2_max_allowed_value_1_incomecalculator: 'Maximum Allowable Rent for AMI 1',
    floorplan2_max_allowed_value_2_incomecalculator: 'Maximum Allowable Rent for AMI 2',
    floorplan2_max_allowed_value_3_incomecalculator: 'Maximum Allowable Rent for AMI 3',
    floorplan2_name_incomecalculator: 'Floorplan 2 name',
    floorplan3_badrooms_incomecalculator: 'Bedrooms for Floorplan 3',
    floorplan3_bathrooms_incomecalculator: 'Bathrooms for Floorplan 3',
    floorplan3_market_rent_incomecalculator: 'Market rent for Floorplan 3',
    floorplan3_max_allowed_value_1_incomecalculator: 'Maximum Allowable Rent for AMI 1',
    floorplan3_max_allowed_value_2_incomecalculator: 'Maximum Allowable Rent for AMI 2',
    floorplan3_max_allowed_value_3_incomecalculator: 'Maximum Allowable Rent for AMI 3',
    floorplan3_name_incomecalculator: 'Floorplan 3 name',
    floorplan4_badrooms_incomecalculator: 'Bedrooms for Floorplan 4',
    floorplan4_bathrooms_incomecalculator: 'Bathrooms for Floorplan 4',
    floorplan4_market_rent_incomecalculator: 'Market rent for Floorplan 4',
    floorplan4_max_allowed_value_1_incomecalculator: 'Maximum Allowable Rent for AMI 1',
    floorplan4_max_allowed_value_2_incomecalculator: 'Maximum Allowable Rent for AMI 2',
    floorplan4_max_allowed_value_3_incomecalculator: 'Maximum Allowable Rent for AMI 3',
    floorplan4_name_incomecalculator: 'Floorplan 4 name',
    income_to_rent_incomecalculator: 'Income to Rent Variable',
    knock_source: 'Source Parameter',
    knock_username: 'Community Username',
    label1: 'Select Your CRM, LMS, or PMS:',
    label2: 'Lead Qualifying Questions:',
    label3: 'Floorplans & Current Market Rent:',
    label_app: 'Conversion Cloud Applications:',
    label_company: 'Company Information:',
    label_notify: 'Lead Notification:',
    label_office: 'Tour hours:',
    label_style: 'Behaviors & Styles:',
    lead_source_name_entrata: 'What is the exact property name as it appears within Yardi?',
    lead_source_name_realpage: 'What is the Lead Source Name we should submit to the CRM?',
    lead_source_name_yardi: 'What is the Lead Source Name?',
    lead_source_type_realpage: 'What is the Lead Source Type?',
    max_income_incomecalculator: 'Maximun Household Income',
    max_size_incomecalculator: 'Maximum Household Size',
    offer_concessionmanager: 'Offer (limited to 25 characters)',
    office_hours: 'Tour hours:',
    password_entrata: 'To complete the integration, please provide a password for the username above',
    phone: 'Phone Number',
    podio_id: 'Podio ID',
    property_source_name_yardi: 'What is the exact property name as it appears within Yardi?',
    prospect_question_four_schedulegenie: 'Prospect question four:',
    prospect_question_one_schedulegenie: 'Prospect question one:',
    prospect_question_three_schedulegenie: 'Prospect question three:',
    prospect_question_two_schedulegenie: 'Prospect question two:',
    prospection_four_incomecalculator: 'Prospect question four:',
    prospection_mandratory_concessionmanager: 'Make Questions Mandatory for Prospects?',
    prospection_mandratory_incomecalculator: 'Make Questions Mandatory for Prospects?',
    prospection_one_concessionmanager: 'Prospect question one:',
    prospection_one_incomecalculator: 'Prospect question one:',
    prospection_three_concessionmanager: 'Prospect question three:',
    prospection_three_incomecalculator: 'Prospect question three:',
    prospection_two_concessionmanager: 'Prospect question two:',
    prospection_two_incomecalculator: 'Prospect question two:',
    qualification_policy_incomecalculator: 'Qualification Policy (if any):',
    question1_label_incomecalculator: 'Module question one:',
    question1_max_incomecalculator: 'Number related to question one:',
    question2_label_incomecalculator: 'Module question two:',
    question3_label_incomecalculator: 'Module question three:',
    questions_mandatory_schedulegenie: 'Make Questions Mandatory for Prospects?',
    realpage_lead2lease_api_endpoint: 'API Endpoint/URL',
    realpage_lead2lease_api_key: 'API Key',
    realpage_lead2lease_property_id: 'Property ID',
    rent_manager_api_endpoint: 'API Endpoint/URL',
    rent_manager_api_password: 'API Password',
    rent_manager_api_username: 'API Username',
    rent_manager_location_id: 'Location ID',
    rent_manager_property_id: 'Property ID',
    rent_to_income_percent_incomecalculator: 'Rent to Income Percent',
    replyto_email: 'Reply-to Email Address',
    sales_rep: 'Account representative',
    schedulegenie: 'Schedule Genie',
    send_xml_attachment: 'Send XML in attachment?',
    send_xml_email: 'Send XML in email body?',
    sherpa_api_endpoint: 'API Endpoint/URL',
    sherpa_api_key: 'API Key',
    sherpa_community_id: 'Community ID',
    sherpa_company_id: 'Sherpa Company ID',
    spotlight_templates: 'Spotlight Templates',
    state: 'State',
    timezone: 'Timezone',
    username_entrata:
        "In order to allow The Conversion Cloud to integrate with your community's Entrata CRM, please provide a username we can access",
    website_url: 'Website URL',
    websites_domain_entrata: "Please verify your community website's domain",
    widget_label_concessionmanager: 'Module Label',
    widget_position: 'Application module position on desktop',
    widget_position_mobile: 'Application module position on mobile',
    ygl_api_endpoint: 'API Endpoint/URL',
    ygl_api_password: 'API Password',
    ygl_api_username: 'API Username',
    ygl_lead_source_id: 'Lead Source ID',
    ygl_property_id: 'Property ID',
    zipcode: 'Zip Code',
    feature_smsmessage: 'Enable SMS messaging?',
    topic_placeholder:'Select a Topic'
};
