import { timeZoneListOptions } from './timezone';
import { FORMS_SALES_REPRESENTATIVE,TOPICS_OPTIONS,CRM_TYPES } from './forms';
import { templateLabelsTccOnboarding } from './template-labels1';

export const templateFieldsTccOnboarding = [
    {
        key: 'label_company',
        widget: 'label',
        text: templateLabelsTccOnboarding.label_company,
        colSpan:3,
    },
    {
        key: 'company_name',
        widget: 'CLXautocomplete',
        label: templateLabelsTccOnboarding.company_name,
        dbSource: 'odoo_entity',
        dbfield: 'odoo_entity_id',
        fieldConnected: 'company_name',
        fieldsUpdate: ['item_id', 'company_name'],
        fieldsMapping: {
            website: 'website_url',
            entity_name: 'company_name',
            odoo_entity_id: 'item_id',
            street: 'address1',
            city: 'city',
            state: 'state',
            postal_code: 'zipcode'
        },
        rules: ['companyNameValidator'],
    },
    {
        key: 'address1',
        widget: 'input',
        label: templateLabelsTccOnboarding.address1,
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'address2',
        widget: 'input',
        label: templateLabelsTccOnboarding.address2,
        formItemProps:{
            hidden:true
        },
        
    },
    {
        key: 'city',
        widget: 'input',
        label: templateLabelsTccOnboarding.city,
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'state',
        widget: 'select',
        label: templateLabelsTccOnboarding.state,
        options: [
            { label: 'Alabama', value: 'AL' },
            { label: 'Alaska', value: 'AK' },
            { label: 'Arizona', value: 'AZ' },
            { label: 'Arkansas', value: 'AR' },
            { label: 'California', value: 'CA' },
            { label: 'Colorado', value: 'CO' },
            { label: 'Connecticut', value: 'CT' },
            { label: 'Delaware', value: 'DE' },
            { label: 'District of Columbia', value: 'DC' },
            { label: 'Florida', value: 'FL' },
            { label: 'Georgia', value: 'GA' },
            { label: 'Hawaii', value: 'HI' },
            { label: 'Idaho', value: 'ID' },
            { label: 'Illinois', value: 'IL' },
            { label: 'Indiana', value: 'IN' },
            { label: 'Iowa', value: 'IA' },
            { label: 'Kansas', value: 'KS' },
            { label: 'Kentucky', value: 'KY' },
            { label: 'Louisiana', value: 'LA' },
            { label: 'Maine', value: 'ME' },
            { label: 'Maryland', value: 'MD' },
            { label: 'Massachusetts', value: 'MA' },
            { label: 'Michigan', value: 'MI' },
            { label: 'Minnesota', value: 'MN' },
            { label: 'Mississippi', value: 'MS' },
            { label: 'Missouri', value: 'MO' },
            { label: 'Montana', value: 'MT' },
            { label: 'Nebraska', value: 'NE' },
            { label: 'Nevada', value: 'NV' },
            { label: 'New Hampshire', value: 'NH' },
            { label: 'New Jersey', value: 'NJ' },
            { label: 'New Mexico', value: 'NM' },
            { label: 'New York', value: 'NY' },
            { label: 'North Carolina', value: 'NC' },
            { label: 'North Dakota', value: 'ND' },
            { label: 'Ohio', value: 'OH' },
            { label: 'Oklahoma', value: 'OK' },
            { label: 'Oregon', value: 'OR' },
            { label: 'Pennsylvania', value: 'PA' },
            { label: 'Rhode Island', value: 'RI' },
            { label: 'South Carolina', value: 'SC' },
            { label: 'South Dakota', value: 'SD' },
            { label: 'Tennessee', value: 'TN' },
            { label: 'Texas', value: 'TX' },
            { label: 'Utah', value: 'UT' },
            { label: 'Vermont', value: 'VT' },
            { label: 'Virginia', value: 'VA' },
            { label: 'Washington', value: 'WA' },
            { label: 'West Virginia', value: 'WV' },
            { label: 'Wisconsin', value: 'WI' },
            { label: 'Wyoming', value: 'WY' }
        ],
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'zipcode',
        widget: 'input',
        label: templateLabelsTccOnboarding.zipcode,
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'sales_rep',
        widget: 'select',
        options: FORMS_SALES_REPRESENTATIVE,
        label: templateLabelsTccOnboarding.sales_rep,
        sentnotice: true,
    },
    {
        key: 'website_url',
        widget: 'input',
        label: templateLabelsTccOnboarding.website_url,
        placeholder: 'Please use a fully-qualified URL including "http://" or "https://".',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'timezone',
        widget: 'select',
        options: timeZoneListOptions(),
        label: templateLabelsTccOnboarding.timezone,
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'phone',
        widget: 'input',
        label: templateLabelsTccOnboarding.phone,
        rules: ['phoneValidator'],
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'label1',
        widget: 'label',
        text: templateLabelsTccOnboarding.label1
    },
    {
        key: 'crm_type',
        widget: 'select',
        options: CRM_TYPES,
        buttonGroup: true,
        label: templateLabelsTccOnboarding.crm_type,
        initialValue: ''
    },
    {
        key: 'crm_email_setting',
        widget: 'input',
        label: templateLabelsTccOnboarding.crm_email_setting,
        placeholder: 'Email ends in @realpage.com, @lead2lease.com, @aptleasing.info, etc',
        rules: ['crmEmailValidator'],
        managed: 'crm_type:!entrata'
    },
    {
        key: 'label_crm_contact',
        widget: 'label',
        text: 'Who in your current organization can provide CRM, LMS, or PMS integration details:',
        colSpan:6,
    },
    {
        key: 'crm_contact_name',
        widget: 'input',
        label: 'Name',
        required: true,
        formItemProps:{
            style:{ width:'33%',
            display: 'inline-block'}
        },
        formItemLayout:[24,24]
    },
    {
        key: 'crm_contact_email',
        widget: 'input',
        label: 'E-mail',
        required: true,
        formItemProps:{
            style:{ width:'33%',
            display: 'inline-block'}
        },
        formItemLayout:[24,24],
        rules: ['oneEmailValidator']
    },
    {
        key: 'crm_contact_phone',
        widget: 'input',
        label: 'Phone Number',
        formItemProps:{
            style:{ width:'33%',
            display: 'inline-block'}
        },
        formItemLayout:[24,24]
    },

    {
        key: 'label_notify',
        widget: 'label',
        text: templateLabelsTccOnboarding.label_notify
    },
    {
        key: 'crm_email',
        widget: 'input',
        label: templateLabelsTccOnboarding.crm_email,
        placeholder: 'Enter email addresses separated by a comma',
        rules: ['emailValidator']
    },
    {
        key: 'replyto_email',
        widget: 'input',
        label: templateLabelsTccOnboarding.replyto_email,
        rules: ['oneEmailValidator']
    },
    {
        key: 'color_option',
        widget: 'select',
        options: [
            {
                value: 'red',
                label: 'Red'
            },
            {
                value: 'blue',
                label: 'Blue'
            },
            {
                value: 'lightblue',
                label: 'Light Blue'
            },
            {
                value: 'gray',
                label: 'Gray'
            },
            {
                value: 'green',
                label: 'Green'
            },
            {
                value: 'teal',
                label: 'Teal'
            },
            {
                value: 'orange',
                label: 'Orange'
            },
            {
                value: 'purple',
                label: 'Purple'
            },
            {
                value: 'black',
                label: 'Black'
            }
        ],
        label: templateLabelsTccOnboarding.color_option,
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'widget_position',
        widget: 'select',
        options: [
            {
                value: 'bottom-right',
                label: 'Bottom Right'
            },
            {
                value: 'bottom-middle',
                label: 'Bottom Middle'
            },
            {
                value: 'bottom-left',
                label: 'Bottom Left'
            },
            {
                value: 'top-right',
                label: 'Top Right'
            },
            {
                value: 'top-left',
                label: 'Top Left'
            }
        ],
        label: templateLabelsTccOnboarding.widget_position,
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'widget_position_mobile',
        widget: 'select',
        options: [
            {
                value: 'bottom-right',
                label: 'Bottom Right'
            },
            {
                value: 'bottom-middle',
                label: 'Bottom Middle'
            },
            {
                value: 'bottom-left',
                label: 'Bottom Left'
            },
            {
                value: 'top-right',
                label: 'Top Right'
            },
            {
                value: 'top-left',
                label: 'Top Left'
            }
        ],
        label: templateLabelsTccOnboarding.widget_position_mobile,
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'label_app',
        widget: 'label',
        text: templateLabelsTccOnboarding.label_app
    },
    {
        key: 'schedulegenie',
        widget: 'switch',
        label: templateLabelsTccOnboarding.schedulegenie,
        initialValue: true
    },
    {
        key: 'label_office',
        widget: 'label',
        text: templateLabelsTccOnboarding.label_office,
        managed: 'schedulegenie',
    },
    {
        key: 'office_hours',
        widget: 'timetable',
        managed: 'schedulegenie',
    },
    {
        key: 'appointment_long_schedulegenie',
        widget: 'select',
        label: templateLabelsTccOnboarding.appointment_long_schedulegenie,
        initialValue: '45',
        options: [
            {
                value: '15',
                label: '15 minutes'
            },
            {
                value: '30',
                label: '30 minutes'
            },
            {
                value: '45',
                label: '45 minutes'
            },
            {
                value: '60',
                label: '1 hour'
            },
            {
                value: '75',
                label: '1 hour 15 minutes'
            },
            {
                value: '90',
                label: '1 hour 30 minutes'
            },
            {
                value: '105',
                label: '1 hour 45 minutes'
            },
            {
                value: '120',
                label: '2 hours'
            },
            {
                value: '135',
                label: '2 hours 15 minutes'
            },
            {
                value: '150',
                label: '2 hours 30 minutes'
            },
            {
                value: '165',
                label: '2 hours 45 minutes'
            },
            {
                value: '180',
                label: '3 hours'
            },
            {
                value: '195',
                label: '3 hours 15 minutes'
            },
            {
                value: '210',
                label: '3 hours 30 minutes'
            },
            {
                value: '225',
                label: '3 hours 45 minutes'
            },
            {
                value: '240',
                label: '4 hours'
            },
            {
                value: '255',
                label: '4 hours 15 minutes'
            },
            {
                value: '270',
                label: '4 hours 30 minutes'
            },
            {
                value: '285',
                label: '4 hours 45 minutes'
            },
            {
                value: '300',
                label: '5 hours'
            },
            {
                value: '315',
                label: '5 hours 15 minutes'
            },
            {
                value: '330',
                label: '5 hours 30 minutes'
            },
            {
                value: '345',
                label: '5 hours 45 minutes'
            },
            {
                value: '360',
                label: '6 hours'
            }
        ],
        managed: 'schedulegenie',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'appointment_gap_schedulegenie',
        widget: 'select',
        initialValue: '15',
        options: [
            {
                value: '0',
                label: '0 minutes'
            },
            {
                value: '15',
                label: '15 minutes'
            },
            {
                value: '30',
                label: '30 minutes'
            },
            {
                value: '45',
                label: '45 minutes'
            },
            {
                value: '60',
                label: '1 hour'
            },
            {
                value: '75',
                label: '1 hour 15 minutes'
            },
            {
                value: '90',
                label: '1 hour 30 minutes'
            },
            {
                value: '105',
                label: '1 hour 45 minutes'
            },
            {
                value: '120',
                label: '2 hours'
            },
            {
                value: '135',
                label: '2 hours 15 minutes'
            },
            {
                value: '150',
                label: '2 hours 30 minutes'
            },
            {
                value: '165',
                label: '2 hours 45 minutes'
            }
        ],
        label: templateLabelsTccOnboarding.appointment_gap_schedulegenie,
        managed: 'schedulegenie',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'appointment_quantity_schedulegenie',
        widget: 'number',
        label: templateLabelsTccOnboarding.appointment_quantity_schedulegenie,
        managed: 'schedulegenie',
        initialValue: '1',
        placeholder: '(1 - 9 appts)',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'appointment_how_soon_schedulegenie',
        widget: 'select',
        initialValue: '1440',
        options: [
            {
                value: '0',
                label: 'Same day'
            },
            {
                value: '30',
                label: '30 minutes'
            },
            {
                value: '60',
                label: '1 hour'
            },
            {
                value: '120',
                label: '2 hours'
            },
            {
                value: '180',
                label: '3 hours'
            },
            {
                value: '360',
                label: '6 hours'
            },
            {
                value: '1440',
                label: '1 working day'
            },
            {
                value: '2880',
                label: '2 working days'
            },
            {
                value: '4320',
                label: '3 working days'
            },
            {
                value: '5760',
                label: '4 working days'
            },
            {
                value: '7200',
                label: '5 working days'
            },
            {
                value: '8640',
                label: '6 working days'
            },
            {
                value: '10080',
                label: '7 working days'
            },
            {
                value: '11520',
                label: '8 working days'
            },
            {
                value: '12960',
                label: '9 working days'
            },
            {
                value: '14400',
                label: '10 working days'
            }
        ],
        label: templateLabelsTccOnboarding.appointment_how_soon_schedulegenie,
        managed: 'schedulegenie',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'label2',
        widget: 'label',
        text: templateLabelsTccOnboarding.label2,
        managed: 'schedulegenie',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'prospect_question_one_schedulegenie',
        widget: 'input',
        label: templateLabelsTccOnboarding.prospect_question_one_schedulegenie,
        managed: 'schedulegenie',
        initialValue: 'When would you like to move-in?',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'prospect_question_two_schedulegenie',
        widget: 'input',
        label: templateLabelsTccOnboarding.prospect_question_two_schedulegenie,
        managed: 'schedulegenie',
        initialValue: 'What floor plan are you interested in?',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'prospect_question_three_schedulegenie',
        widget: 'input',
        label: templateLabelsTccOnboarding.prospect_question_three_schedulegenie,
        managed: 'schedulegenie',
        initialValue: 'Do you have any pets?',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'prospect_question_four_schedulegenie',
        widget: 'input',
        label: templateLabelsTccOnboarding.prospect_question_four_schedulegenie,
        managed: 'schedulegenie',
        initialValue: 'What is your preferred method of contact?',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'questions_mandatory_schedulegenie',
        widget: 'radio-group',
        options: ['Yes', 'No'],
        label: templateLabelsTccOnboarding.questions_mandatory_schedulegenie,
        managed: 'schedulegenie'
    },
    {
        key: 'custom_email_text_schedulegenie',
        widget: 'input',
        managed: 'schedulegenie',
        label: templateLabelsTccOnboarding.custom_email_text_schedulegenie,
        initialValue: 'Please bring a government-issued photo ID with you. We look forward to meeting you soon!',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'claire_virtula_assistant',
        widget: 'switch',
        label: 'Claire (Virtual Assistant)',
        initialValue: true
    },
    {
        key: 'claire_virtula_assistant_callto_action',
        widget: 'input',
        label: 'What is the Call to Action?',
        placeholder:'Ex: Talk to Claire', 
        managed:'claire_virtula_assistant'
    },
    {
        key: 'claire_virtula_assistant_greeting',
        widget: 'input',
        label: 'What would you like your Greeting to be?',
        placeholder:'Ex: Hi, I’m Claire. How can I help you?', 
        managed:'claire_virtula_assistant'
    },
    {
        key: 'claire_virtula_assistant_templates',
        widget: 'label',
        label: 'Topic Selection (up to 12 can be active)',
        text: 'Topic Selection (up to 12 can be active)',
        tooltip: 'Some topics automatically link to other modules that are active in Conversion Cloud (like Schedule a Tour) or to pages on your website. Other topics require a text response and will populate additional fields to be completed',
        managed: 'claire_virtula_assistant',
    },
    {
        key: 'claire_virtula_assistant_Topic1',
        widget: 'select',
        label: 'Topic 1:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },

    },
    {
        key: 'claire_virtula_assistant_Topic2',
        widget: 'select',
        label: 'Topic 2:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },
    },
    {
        key: 'claire_virtula_assistant_Topic3',
        widget: 'select',
        label: 'Topic 3:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },
    },
    {
        key: 'claire_virtula_assistant_Topic4',
        widget: 'select',
        label: 'Topic 4:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },
    },
    {
        key: 'claire_virtula_assistant_Topic5',
        widget: 'select',
        label: 'Topic 5:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },
    },
    {
        key: 'claire_virtula_assistant_Topic6',
        widget: 'select',
        label: 'Topic 6:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },
    },
    {
        key: 'claire_virtula_assistant_Topic7',
        widget: 'select',
        label: 'Topic 7:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },
    },
    {
        key: 'claire_virtula_assistant_Topic8',
        widget: 'select',
        label: 'Topic 8:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },
    },
    {
        key: 'claire_virtula_assistant_Topic9',
        widget: 'select',
        label: 'Topic 9:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },
    },
    {
        key: 'claire_virtula_assistant_Topic10',
        widget: 'select',
        label: 'Topic 10:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },
    },
    {
        key: 'claire_virtula_assistant_Topic11',
        widget: 'select',
        label: 'Topic 11:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },
    },
    {
        key: 'claire_virtula_assistant_Topic12',
        widget: 'select',
        label: 'Topic 12:',
        options: TOPICS_OPTIONS,
        placeholder:templateLabelsTccOnboarding.topic_placeholder, 
        managed:'claire_virtula_assistant',
        sentnotice: true,
        formItemProps:{
            style:{ width:'50%'}
        },
    },
    {
        key: 'spotlight',
        widget: 'switch',
        label: templateLabelsTccOnboarding.spotlight,
        initialValue: true
    },
    {
        key: 'spotlight_templates',
        widget: 'label',
        text: templateLabelsTccOnboarding.spotlight_templates,
        managed: 'spotlight'
    },
    {
        key: 'spotlight_opportunities',
        widget: 'checkbox',
        label: 'Opportunities / Promotions',
        managed: 'spotlight'
    },
    {
        key: 'spotlight_opportunities_call_to _action',
        widget: 'input',
        label: 'Call to Action',
        placeholder: "Get a Special Offer!",
        managed:'spotlight_opportunities',
        rules:['callToActionValidator']
    },
    {
        key: 'spotlight_opportunities_description',
        widget: 'input',
        label: 'Description',
        placeholder: 'Take advantage of this special offer',
        managed: 'spotlight_opportunities',
        rules:['descriptionValidator']
    },
    {
        key: 'spotlight_opportunities_link',
        widget: 'input',
        label: 'Link',
        managed: 'spotlight_opportunities'
    },
    {
        key: 'spotlight_opportunities_disclaimer',
        widget: 'input',
        label: 'Disclaimer',
        managed: 'spotlight_opportunities',
        rules:['disclaimerValidator']
    },
    {
        key: 'spotlight_concessionmanager',
        widget: 'checkbox',
        label: 'Concession Manager',
        managed: 'spotlight'
    },
    {
        key: 'spotlight_concession_manager_call_to_action',
        widget: 'input',
        label: 'Call to Action',
        placeholder: "Get a Special Opportunity",
        managed:'spotlight_concessionmanager',
        rules:['callToActionValidator']
    },
    {
        key: 'spotlight_concession_manager_description',
        widget: 'input',
        label: 'Description',
        placeholder: 'Take advantage of this special opportunity',
        managed:'spotlight_concessionmanager',
        rules:['descriptionValidator']
    },
    {
        key: 'spotlight_concession_manager_link',
        widget: 'input',
        label: 'Link',
        managed:'spotlight_concessionmanager',

    },
    {
        key: 'spotlight_concession_manager_disclaimer',
        widget: 'input',
        label: 'Disclaimer',
        managed:'spotlight_concessionmanager',
        rules:['disclaimerValidator']
    },
    {
        key: 'spotlight_tournow',
        widget: 'checkbox',
        label: 'Tour Now',
        managed: 'spotlight'
    },
    {
        key: 'spotlight_tournow_call_to_action',
        widget: 'input',
        label: 'Call to Action',
        placeholder: "Take a Virtual Tour Now!",
        managed:'spotlight_tournow',
        rules:['callToActionValidator']
    },
    {
        key: 'spotlight_tournow_description',
        widget: 'input',
        label: 'Description',
        placeholder: 'Submit your email and we will send you a link to tour virtually',
        managed:'spotlight_tournow',
        rules:['descriptionValidator']
    },
    {
        key: 'spotlight_tournow_link',
        widget: 'input',
        label: 'Link',
        placeholder: "*Please paste virtual tour link here*",
        managed:'spotlight_tournow'

    },
    {
        key: 'spotlight_tournow_disclaimer',
        widget: 'input',
        label: 'Disclaimer',
        managed:'spotlight_tournow',
        rules:['disclaimerValidator']
    },
    {
        key: 'spotlight_floorplans',
        widget: 'checkbox',
        label: 'Floor Plans',
        managed: 'spotlight'
    },
    {
        key: 'spotlight_floorplans_call_to_action',
        widget: 'input',
        label: 'Call to Action',
        placeholder: "Featured Floorplan",
        managed:'spotlight_floorplans',
        rules:['callToActionValidator']
    },
    {
        key: 'spotlight_floorplans_description',
        widget: 'input',
        label: 'Description',
        placeholder: 'Submit your email to learn more!',
        managed:'spotlight_floorplans',
        rules:['descriptionValidator']
    },
    {
        key: 'spotlight_floorplans_link',
        widget: 'input',
        label: 'Link',
        placeholder: "*Please paste floorplan link here*",
        managed:'spotlight_floorplans'

    },
    {
        key: 'spotlight_floorplans_disclaimer',
        widget: 'input',
        label: 'Disclaimer',
        managed:'spotlight_floorplans',
        rules:['disclaimerValidator']
    },
    {
        key: 'spotlight_event_rsvp',
        widget: 'checkbox',
        label: 'Event RSVP',
        value: 'event_rsvp',
        managed: 'spotlight'
    },
    {
        key: 'spotlight_event_rsvp_call_to_action',
        widget: 'input',
        label: 'Call to Action',
        placeholder: "RSVP Today",
        managed:'spotlight_event_rsvp',
        rules:['callToActionValidator']
    },
    {
        key: 'spotlight_event_rsvp_description',
        widget: 'input',
        label: 'Description',
        placeholder: 'Join Us!',
        managed:'spotlight_event_rsvp',
        rules:['descriptionValidator']
    },
    {
        key: 'spotlight_event_rsvp_link',
        widget: 'input',
        label: 'Link',
        managed:'spotlight_event_rsvp'
    },
    {
        key: 'spotlight_event_rsvp_disclaimer',
        widget: 'input',
        label: 'Disclaimer',
        managed:'spotlight_event_rsvp',
        rules:['disclaimerValidator']
    },
    {
        key: 'spotlight_brochure',
        widget: 'checkbox',
        label: 'Brochure',
        managed: 'spotlight'
    },
    {
        key: 'spotlight_brochure_call_to_action',
        widget: 'input',
        label: 'Call to Action',
        placeholder: "Request an E-Brochure!",
        managed:'spotlight_brochure',
        rules:['callToActionValidator']
    },
    {
        key: 'spotlight_brochure_description',
        widget: 'input',
        label: 'Description',
        placeholder: 'Click below to receive a link to our E-Brochure',
        managed:'spotlight_brochure',
        rules:['descriptionValidator']
    },
    {
        key: 'spotlight_brochure_link',
        widget: 'input',
        label: 'Link',
        placeholder: "*Please paste E-brochure link here*",
        managed:'spotlight_brochure'
    },
    {
        key: 'spotlight_brochure_disclaimer',
        widget: 'input',
        label: 'Disclaimer',
        managed:'spotlight_brochure',
        rules:['disclaimerValidator']
    },
    {
        key: 'spotlight_stay_in_touch',
        widget: 'checkbox',
        label: 'Stay In Touch',
        managed: 'spotlight'
    },
    {
        key: 'spotlight_stay_in_touch_call_to_action',
        widget: 'input',
        label: 'Call to Action',
        placeholder: "Receive Updates!",
        managed:'spotlight_stay_in_touch',
        rules:['callToActionValidator']
    },
    {
        key: 'spotlight_stay_in_touch_description',
        widget: 'input',
        label: 'Description',
        placeholder: 'Join our email list to stay in the know.',
        managed:'spotlight_stay_in_touch',
        rules:['descriptionValidator']
    },
    {
        key: 'spotlight_stay_in_touch_link',
        widget: 'input',
        label: 'Link',
        managed:'spotlight_stay_in_touch'
    },
    {
        key: 'spotlight_stay_in_touch_disclaimer',
        widget: 'input',
        label: 'Disclaimer',
        managed:'spotlight_stay_in_touch',
        rules:['disclaimerValidator']
    },
    {
        key: 'spotlight_questions',
        widget: 'checkbox',
        label: 'Questions',
        managed: 'spotlight'
    },
    {
        key: 'spotlight_questions_call_to_action',
        widget: 'input',
        label: 'Call to Action',
        placeholder: "Contact Us",
        managed:'spotlight_questions',
        rules:['callToActionValidator']
    },
    {
        key: 'spotlight_questions_description',
        widget: 'input',
        label: 'Description',
        placeholder: 'Submit an inquiry today to connect with our leasing team.',
        managed:'spotlight_questions',
        rules:['descriptionValidator']
    },
    {
        key: 'spotlight_questions_link',
        widget: 'input',
        label: 'Link',
        managed:'spotlight_questions'
    },
    {
        key: 'spotlight_questions_disclaimer',
        widget: 'input',
        label: 'Disclaimer',
        managed:'spotlight_questions',
        rules:['disclaimerValidator']
    },
    {
        key: 'spotlight_job_openings',
        widget: 'checkbox',
        label: 'Job Openings',
        managed: 'spotlight'
    },
    {
        key: 'spotlight_job_openings_call_to_action',
        widget: 'input',
        label: 'Call to Action',
        placeholder: "Explore Job Openings",
        managed:'spotlight_job_openings',
        rules:['callToActionValidator']
    },
    {
        key: 'spotlight_job_openings_description',
        widget: 'input',
        label: 'Description',
        placeholder: 'Contact us to learn more about career opportunities',
        managed:'spotlight_job_openings',
        rules:['descriptionValidator']
    },
    {
        key: 'spotlight_job_openings_link',
        widget: 'input',
        label: 'Link',
        managed:'spotlight_job_openings'
    },
    {
        key: 'spotlight_job_openings_disclaimer',
        widget: 'input',
        label: 'Disclaimer',
        managed:'spotlight_job_openings',
        rules:['disclaimerValidator']
    },
    {
        key: 'spotlight_interview',
        widget: 'checkbox',
        label: 'Schedule Interview',
        managed: 'spotlight'
    },
    {
        key: 'spotlight_interview_call_to_action',
        widget: 'input',
        label: 'Call to Action',
        placeholder: "Schedule an Interview",
        managed:'spotlight_interview',
        rules:['callToActionValidator']
    },
    {
        key: 'spotlight_interview_description',
        widget: 'input',
        label: 'Description',
        placeholder: 'Visit with our team today to learn more about us',
        managed:'spotlight_interview',
        rules:['descriptionValidator']
    },
    {
        key: 'spotlight_interview_link',
        widget: 'input',
        label: 'Link',
        managed:'spotlight_interview'
    },
    {
        key: 'spotlight_interview_disclaimer',
        widget: 'input',
        label: 'Disclaimer',
        managed:'spotlight_interview',
        rules:['disclaimerValidator']
    },
    {
        key: 'spotlight_custom',
        widget: 'checkbox',
        label: 'Custom',
        managed: 'spotlight'
    },
    {
        key: 'spotlight_custom_call_to_action',
        widget: 'input',
        label: 'Call to Action',
        managed:'spotlight_custom',
        rules:['callToActionValidator']
    },
    {
        key: 'spotlight_custom_description',
        widget: 'input',
        label: 'Description',
        managed:'spotlight_custom',
        rules:['descriptionValidator']
    },
    {
        key: 'spotlight_custom_link',
        widget: 'input',
        label: 'Link',
        managed:'spotlight_custom'
    },
    {
        key: 'spotlight_custom_disclaimer',
        widget: 'input',
        label: 'Disclaimer',
        managed:'spotlight_custom',
        rules:['disclaimerValidator']
    },

    { key: 'lead_nurturing_feature', widget: 'switch', label: 'Lead Nurturing', initialValue: true},
    {
        key: 'lead_nurturing_feature_label',
        widget: 'label',
        tooltip: 'If enabled, prospects who schedule an appointment through Schedule Genie or claim a Spotlight offer will automatically begin receiving a default Lead Nurturing drip schedule for each application, including email/SMS appointment reminders and messages to promote community amenities, floor plans, and more.',
        managed: 'lead_nurturing_feature',
        text: templateLabelsTccOnboarding.lead_nurturing_feature_label,
        formItemLayout:[4,20],
        colon:false,
        underline:{
            text:"Click here to learn more about default Lead Nurturing drip schedules.",
            href:"https://conversioncloud.zohodesk.com/portal/en/kb/articles/lead-nurturing-launch-presets#Lead_Nurturing_delivers_automated_Email_and_SMS_distribution_when_new_leads_convert_through_Schedule_Genie_or_Spotlight",
            uStyle:{color:'blue'}
        },
        widgetProps:{
            style:{marginBottom:'0px'}
        }
    }, 

    { key: 'lead_nurturing_spotlight', widget: 'switch', label: 'Spotlight',managed: 'lead_nurturing_feature',formItemLayout:[9,15] },
    { key: 'lead_nurturing_schedule_genie', widget: 'switch', label: 'Schedule Genie',managed: 'lead_nurturing_feature',formItemLayout:[9,15] },

    {
        key: 'ami_one',
        widget: 'input',
        label: templateLabelsTccOnboarding.ami_one,
        managed: 'incomecalculator',
        placeholder: '(ex: 40%)',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'ami_two',
        widget: 'input',
        label: templateLabelsTccOnboarding.ami_two,
        managed: 'incomecalculator',
        placeholder: '(ex: 60%)',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'ami_three',
        widget: 'input',
        label: templateLabelsTccOnboarding.ami_three,
        managed: 'incomecalculator',
        placeholder: '(ex: 80%)',
        formItemProps:{
            hidden:true
        },
    },
    {
        key: 'income_to_rent_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.income_to_rent_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 2.5)'
    },
    {
        key: 'rent_to_income_percent_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.rent_to_income_percent_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 40%)'
    },
    {
        key: 'max_size_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.max_size_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 4)'
    },
    {
        key: 'max_income_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.max_income_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(Enter the 100% AMI value for a Household Size of 4) (ex: $108,000)'
    },
    {
        key: 'question1_label_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.question1_label_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: How many people will live in your apartment)'
    },
    {
        key: 'question1_max_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.question1_max_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'question2_label_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.question2_label_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'question3_label_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.question3_label_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'label3',
        widget: 'label',
        text: templateLabelsTccOnboarding.label3,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan1_name_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan1_name_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 1 bed / 1 bath)'
    },
    {
        key: 'floorplan1_badrooms_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan1_badrooms_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 2)'
    },
    {
        key: 'floorplan1_bathrooms_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan1_bathrooms_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 1)'
    },
    {
        key: 'floorplan1_market_rent_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan1_market_rent_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 800)'
    },
    {
        key: 'floorplan1_max_allowed_value_1_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan1_max_allowed_value_1_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan1_max_allowed_value_2_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan1_max_allowed_value_2_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan1_max_allowed_value_3_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan1_max_allowed_value_3_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan2_name_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan2_name_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 2 bed / 1 bath)'
    },
    {
        key: 'floorplan2_badrooms_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan2_badrooms_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 2)'
    },
    {
        key: 'floorplan2_bathrooms_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan2_bathrooms_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 1)'
    },
    {
        key: 'floorplan2_market_rent_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan2_market_rent_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 1000)'
    },
    {
        key: 'floorplan2_max_allowed_value_1_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan2_max_allowed_value_1_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan2_max_allowed_value_2_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan2_max_allowed_value_2_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan2_max_allowed_value_3_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan2_max_allowed_value_3_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan3_name_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan3_name_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 2 bed / 2 bath)'
    },
    {
        key: 'floorplan3_badrooms_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan3_badrooms_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 2)'
    },
    {
        key: 'floorplan3_bathrooms_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan3_bathrooms_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 1)'
    },
    {
        key: 'floorplan3_market_rent_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan3_market_rent_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 1800)'
    },
    {
        key: 'floorplan3_max_allowed_value_1_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan3_max_allowed_value_1_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan3_max_allowed_value_2_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan3_max_allowed_value_2_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan3_max_allowed_value_3_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan3_max_allowed_value_3_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan4_name_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan4_name_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 3 bed / 2 bath)'
    },
    {
        key: 'floorplan4_badrooms_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan4_badrooms_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 2)'
    },
    {
        key: 'floorplan4_bathrooms_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan4_bathrooms_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 1)'
    },
    {
        key: 'floorplan4_market_rent_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan4_market_rent_incomecalculator,
        managed: 'incomecalculator',
        placeholder: '(ex: 2800)'
    },
    {
        key: 'floorplan4_max_allowed_value_1_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan4_max_allowed_value_1_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan4_max_allowed_value_2_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan4_max_allowed_value_2_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'floorplan4_max_allowed_value_3_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.floorplan4_max_allowed_value_3_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'disclaimer_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.disclaimer_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'qualification_policy_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.qualification_policy_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'prospection_one_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.prospection_one_incomecalculator,
        managed: 'incomecalculator',
        initialValue: 'When would you like to move-in?'
    },
    {
        key: 'prospection_two_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.prospection_two_incomecalculator,
        managed: 'incomecalculator',
        initialValue: 'What floor plan are you interested in?'
    },
    {
        key: 'prospection_three_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.prospection_three_incomecalculator,
        managed: 'incomecalculator',
        initialValue: 'Do you have any pets?'
    },
    {
        key: 'prospection_four_incomecalculator',
        widget: 'input',
        label: templateLabelsTccOnboarding.prospection_four_incomecalculator,
        managed: 'incomecalculator',
        initialValue: 'What is your preferred method of contact?'
    },
    {
        key: 'prospection_mandratory_incomecalculator',
        widget: 'radio-group',
        options: ['Yes', 'No'],
        label: templateLabelsTccOnboarding.prospection_mandratory_incomecalculator,
        managed: 'incomecalculator'
    },
    {
        key: 'feature_smsmessage',
        widget: 'switch',
        label: templateLabelsTccOnboarding.feature_smsmessage,
        initialValue: true
    },
    {
        key: 'podio_id',
        widget: 'input',
        label: '',
        widgetProps: {
            style: {
                display: 'none'
            }
        }
    }
];

export const topicsKeys = ['claire_virtula_assistant_Topic1','claire_virtula_assistant_Topic2','claire_virtula_assistant_Topic3','claire_virtula_assistant_Topic4',
'claire_virtula_assistant_Topic5','claire_virtula_assistant_Topic6','claire_virtula_assistant_Topic7','claire_virtula_assistant_Topic8'
,'claire_virtula_assistant_Topic9','claire_virtula_assistant_Topic10','claire_virtula_assistant_Topic11','claire_virtula_assistant_Topic12']
