import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import NotificationSystem from 'react-notification-system';
import CampaignsConstants from './constants/campaigns.constants';
import { Helmet } from 'react-helmet';

import { ExportOutlined, SyncOutlined } from '@ant-design/icons';

import { Button, Dropdown, Menu, Table, Tag, notification, DatePicker } from 'antd';
import Card from '../../components/Card/Card.jsx';
import moment from 'moment';
import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';
import { style } from '../../variables/Variables.jsx';

const { MonthPicker } = DatePicker;

class ExpiringCampaigns extends Component {
    constructor() {
        super();

        this.columns = [
            {
                title: 'Line Item ID',
                dataIndex: 'line_item_id',
                key: 'line_item_id',
                width: 120
            },
            {
                title: 'Line Item',
                dataIndex: 'line_item_name',
                key: 'line_item_name',
                render: (text, record) => (
                    <a
                        href={`https://invest.xandr.com/bmw/line-items/in-progress?line_item_id=${record.line_item_id}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {text}
                    </a>
                ),
                sorter: (a, b) => a.line_item_name > b.line_item_name,
            },            
            {
                title: 'Billing Periods',
                dataIndex: 'line_item_budget_count',
                key: 'line_item_budget_count',
                render: text => <span title={text}>{text} </span>,
                sorter: (a, b) => a.line_item_budget_count - b.line_item_budget_count,
            },
            {
                title: 'Product',
                key: 'tags',
                filters: [
                    {
                        text: CampaignsConstants['3D_PRODUCT_NAME'],
                        value: CampaignsConstants['3D_PRODUCT_NAME']
                    },
                    {
                        text: CampaignsConstants.RT_PRODUCT_NAME,
                        value: CampaignsConstants.RT_PRODUCT_NAME
                    }
                ],
                onFilter: (value, record) => record.line_item_name.includes(value),
                render: (value, record) => (
                    <span>
                        {record.line_item_name.includes(CampaignsConstants.RT_PRODUCT_NAME) ? (
                            <Tag color="cyan" title={CampaignsConstants.RT_PRODUCT_NAME}>
                                {CampaignsConstants.RT_PRODUCT_NAME}
                            </Tag>
                        ) : record.line_item_name.includes(CampaignsConstants['3D_PRODUCT_NAME']) ? (
                            <Tag color="blue" title={CampaignsConstants['3D_PRODUCT_NAME']}>
                                {CampaignsConstants['3D_PRODUCT_NAME']}
                            </Tag>
                        ) : null}
                    </span>
                )
            },
            {
                title: 'Lifetime Budget',
                dataIndex: 'lifetime_budget',
                key: 'lifetime_budget',
                render: value =>
                    value ? value.toLocaleString(undefined, { style: 'currency', currency: 'USD' }) : value,
                align: 'right'
            },
            {
                title: 'End Date',
                dataIndex: 'end_date',
                key: 'end_date',
                render: text => <span title={text}>{moment(text).format(CampaignsConstants.DATE_STRING_FORMAT)} </span>
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Dropdown
                            overlay={
                                <Menu>
                                    {[1, 2, 3, 6].map((value, i) => (
                                        <Menu.Item
                                            onClick={() => {
                                                this.extendByNumberOfMonths(record, value);
                                            }}
                                            key={i}
                                        >
                                            for <strong>{value}</strong> month{value > 1 ? 's' : ''}{' '}
                                            <span className="text-muted">
                                                (until{' '}
                                                {moment()
                                                    .add(value, 'month')
                                                    .endOf('month')
                                                    .format(CampaignsConstants.DATE_STRING_FORMAT)}
                                                )
                                            </span>
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            }
                        >
                            <Button
                                type="link"
                                size="small"
                                icon={<ExportOutlined />}
                                disabled={this.state.loading.has(record.line_item_id)}
                                loading={this.state.loading.has(record.line_item_id)}
                            >
                                Extend...
                            </Button>
                        </Dropdown>
                    </span>
                )
            }
        ];

        this.state = {
            _notificationSystem: null,
            selectedRowKeys: [],
            reportingDataTableHeaders: [],
            data: [],
            loadingData: false,
            loading: new Set([])
        };
    }

    getData = () => {
        this.setState({
            loading: new Set(this.state.loading).add('data')
        });

        return axios
            .get(`${API_BASE_URL}anx/expiringlineitems/`, {
                headers: authHeader()
            })
            .then(response => {
                this.setState({
                    data: response.data
                });
            })
            .catch(error => {
                console.error('Error downloading line item data!', error);
                notification.error({ message: 'Error downloading line item data!' });
            })
            .finally(() => {
                let loadingState = new Set(this.state.loading);
                loadingState.delete('data');

                this.setState({
                    loading: loadingState
                });
            });
    };

    extendByNumberOfMonths(record, numberOfMonths = 1) {
        return this.handleExtend(moment().add(numberOfMonths, 'month').endOf('month'), [record.line_item_id]);
    }

    handleExtend(endDate = moment(), lineItemIds) {
        let loading = new Set(this.state.loading);
        lineItemIds.forEach(id => {
            loading.add(id);
        });
        this.setState({ loading });

        endDate = moment(endDate).format('YYYY-MM');
        let reqBody = {
            end_date: endDate,
            'line-items': lineItemIds
        };

        return axios({
            method: 'put',
            url: `${API_BASE_URL}anx/extendbudgetintervals/`,
            headers: authHeader(),
            data: reqBody
        })
            .then(response => {
                let loadingState = new Set(this.state.loading);
                lineItemIds.forEach(id => {
                    loadingState.delete(id);
                    let rowId = this.state.data.find(item => item.line_item_id === id).id;
                    this.removeRowFromView(rowId);
                });

                this.setState({ loading: loadingState });
            })
            .catch(error => {
                console.error('Error extending line item!', error);
            });
    }

    removeRowFromView(id) {
        this.setState({
            data: this.state.data.filter(row => row.id !== id),
            selectedRowKeys: this.state.selectedRowKeys.filter(key => key !== id)
        });
    }

    handleBulkExtend = () => {
        this.setState({ loading: new Set(this.state.loading).add('bulk') });

        let selectedRecords = this.state.data.filter(record => this.state.selectedRowKeys.includes(record.id));

        this.setState({ loading: new Set(['bulk', ...this.state.loading, ...this.state.selectedRowKeys]) }, () => {
            // Promise.all(
            //     selectedRecords.map(async record => {
            //         return await this.handleExtend(this.state.endDate, [record.line_item_id]);
            //     })
            // )
            this.handleExtend(
                this.state.endDate,
                selectedRecords.map(record => record.line_item_id)
            )
                .then(() => {
                    this.setState({
                        selectedRowKeys: []
                    });
                })
                .finally(() => {
                    let loadingState = new Set(this.state.loading);
                    loadingState.delete('bulk');

                    this.setState({
                        loading: loadingState
                    });
                });
        });
    };

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    getClassNames() {
        let classes = 'content';

        if (this.state.loadingData) {
            classes += ' clx-loading';
        }

        return classes;
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        if (this.state.goToAccountMapping) {
            return <Redirect to={{ pathname: '/account-mapping' }} />;
        }

        const { loading, selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: record => ({
                disabled: loading.has(record.id)
            })
        };
        const hasSelected = selectedRowKeys.length > 0;

        return (
            <div className={this.getClassNames()}>
                <Helmet>
                    <title>Expiring Campaigns - Conversion Logix</title>
                </Helmet>
                <NotificationSystem ref="notificationSystem" style={style} />
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Campaigns ending this month"
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <div>
                                        <div style={{ margin: 16 }}>
                                            <Button
                                                icon={<SyncOutlined />}
                                                onClick={this.getData}
                                                loading={loading.has('data')}
                                                style={{ marginRight: 16 }}
                                            >
                                                Reload
                                            </Button>
                                            <span style={{ marginRight: 8 }}>
                                                Extend
                                                {hasSelected
                                                    ? ` ${this.state.selectedRowKeys.length} selected items `
                                                    : ' '}
                                                through
                                            </span>
                                            <MonthPicker
                                                onChange={e => {
                                                    if (e) {
                                                        e = e.endOf('month');
                                                    }
                                                    this.setState({
                                                        endDate: e
                                                    });
                                                }}
                                                style={{ marginRight: 8 }}
                                                placeholder="Select month"
                                                format={CampaignsConstants.DATE_STRING_FORMAT}
                                                disabledDate={current =>
                                                    current < moment().add(1, 'month').startOf('month')
                                                }
                                            />
                                            <Button
                                                type="primary"
                                                onClick={this.handleBulkExtend}
                                                disabled={!hasSelected || !this.state.endDate}
                                                loading={loading.has('bulk')}
                                            >
                                                Apply
                                            </Button>
                                        </div>
                                        <Table
                                            className="ending-campaigns-holder"
                                            rowSelection={rowSelection}
                                            columns={this.columns}
                                            dataSource={this.state.data}
                                            rowKey="id"
                                            loading={loading.has('data')}
                                        />
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpiringCampaigns;
