import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import indexRoutes from './routes/index.jsx';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import FormSubmission from './views/FormBuilder/formSubmission';
import Login from './layouts/Login/Login';
import ReactGA from 'react-ga';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/sass/light-bootstrap-dashboard.css?v=1.2.0';
import './assets/css/demo.css';
import './assets/css/pe-icon-7-stroke.css';
import ResetPass from './layouts/Login/ResetPass.jsx';

ReactGA.initialize('UA-138601464-1');
console.log('ReactGA initialized');
console.log(window.location.pathname + window.location.search);

ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
    render() {
        return (
            <Fragment>
                <Router>
                    <Switch>
                        <Route path={'/login/resetPass'} name={'Reset Pass'} component={ResetPass} />
                        <Route path={'/login'} name={'Login'} component={Login} />
                        <Route
                            path={'/forms/formenter/:templateid'}
                            name={'Form Submission'}
                            component={FormSubmission}
                        />
                        <Route path={'/form/:formalias'} name={'Form Submission'} component={FormSubmission} />
                        {indexRoutes.map((prop, key) => {
                            return <PrivateRoute exact to={prop.path} component={prop.component} key={key} />;
                        })}
                    </Switch>
                </Router>
                {/* Because this React app is running in dev mode,
                    React Query Tools needs to be commented out when
                depolyed to the production server  */}
            </Fragment>
        );
    }
}

export default App;
