(function(root, factory) {
    if (typeof exports === 'object') {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like enviroments that support module.exports,
        // like Node.
        module.exports = factory(require('chartist'), require('jquery'));
    } else {
        root['Chartist.plugins.tooltip'] = factory(root.chartist, root.jquery);
    }
})(this, function(Chartist, $) {
    /**
     * This Chartist tooltip plugin is a modified version of
     * https://github.com/Globegitter/chartist-plugin-tooltip.
     *
     */

    var defaultOptions = {
        valueTransform: Chartist.noop,
        // Show name of series in tooltip.
        seriesName: true
    };

    Chartist.plugins = Chartist.plugins || {};

    Chartist.plugins.tooltip = function(options) {
        options = Chartist.extend({}, defaultOptions, options);

        return function tooltip(chart) {
            var tooltipSelector = '.ct-point';
            if (chart instanceof Chartist.Bar) {
                tooltipSelector = '.ct-bar';
            } else if (chart instanceof Chartist.Pie) {
                tooltipSelector = '[class^=ct-slice]';
            }

            var $chart = $(chart.container),
                $toolTip = $chart
                    .append('<div class="ct-tooltip"></div>')
                    .find('.ct-tooltip')
                    .hide();

            $chart.on('mouseenter', tooltipSelector, function() {
                var $point = $(this),
                    seriesName = $point.parent().attr('ct:series-name'),
                    tooltipText = '';

                if (options.seriesName && seriesName) {
                    tooltipText += seriesName + '<br>';
                }

                if ($point.attr('ct:meta')) {
                    tooltipText += $point.attr('ct:meta') + '<br>';
                }

                var value = $point.attr('ct:value') || '0';

                tooltipText += options.valueTransform(value);

                var bodyRect = document.body.getBoundingClientRect(),
                    containerRect = document.getElementsByClassName('table-full-width')[0].getBoundingClientRect(),
                    elemRect = $point[0].getBoundingClientRect(),
                    offsetY = elemRect.top - bodyRect.top - $toolTip.height() - 70,
                    offsetX = elemRect.left - containerRect.left - 35;

                $toolTip.css({
                    left: offsetX,
                    top: offsetY
                });

                $toolTip.html(tooltipText).show();
            });

            $chart.on('mouseleave', tooltipSelector, function() {
                $toolTip.hide();
            });
        };
    };

    return Chartist.plugins.tooltip;
});
