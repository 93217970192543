import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { Row, Col, Button, Input, Spin, Checkbox, Radio } from 'antd';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import { style, API_BASE_URL } from '../../variables/Variables';
import './index.css';

import { set } from 'lodash';
const { TextArea } = Input;
const ANX_SEGMENTS_UPDATE_ENDPOINT = 'anx/segments/update';

const Segments = () => {
    const [accounts, setAccounts] = useState('');
    const [segmentsToAdd, setSegmentsToAdd] = useState('');
    const [segmentsToRemove, setSegmentsToRemove] = useState('');
    const [processingFlag, setProcessingFlag] = useState(false);
    const [removeAllSegments, setRemoveAllSegments] = useState(false);
    const [operatorForAdd, setOperatorForAdd] = useState('or');
    const notificationSystem = useRef(null);
    const [requestDone, setRequestDone] = useState(false);
    const [successLI, setSuccessLI] = useState([]);
    const [failedLI, setFailedLI] = useState([]);

    const getNotification = (level, text) => {
        return {
            title: <span data-notify="icon" className={level === 'success' ? 'pe-7s-server' : 'pe-7s-shield'} />,
            message: <div dangerouslySetInnerHTML={{ __html: text }} />,
            level: level,
            position: 'tr',
            autoDismiss: 5
        };
    };

    const onSearchAccount = handle => {
        setSegmentsToAdd(handle.target.value);
    };

    const listsValidation = () => {
        const checkList = list => {
            if (!list) {
                return false;
            }
            if (list.split(',').length < 1) {
                return false;
            }
            return true;
        };
        if (!accounts) {
            notificationSystem.current.addNotification(getNotification('error', 'No accounts to process'));
            return false;
        }
        if (!segmentsToRemove && !segmentsToAdd) {
            notificationSystem.current.addNotification(getNotification('error', 'No segments to process'));
            return false;
        }

        if (!checkList(accounts)) {
            notificationSystem.current.addNotification(
                getNotification('error', 'Accounts list is not valid. It should be a list of IDs splitted with commas')
            );
            return false;
        }

        if (!checkList(segmentsToRemove) && !checkList(segmentsToAdd)) {
            notificationSystem.current.addNotification(
                getNotification('error', 'Segments list is not valid. It should be a list of IDs splitted with commas')
            );
            return false;
        }
        return true;
    };

    const onStartToProcess = () => {
        if (!listsValidation()) {
            return;
        }
        setProcessingFlag(true);
        console.log('Processing...');
        axios
            .post(`${API_BASE_URL}${ANX_SEGMENTS_UPDATE_ENDPOINT}/`, {
                accounts: accounts,
                segmentsToAdd: segmentsToAdd,
                segmentsToRemove: segmentsToRemove,
                removeAllSegments: removeAllSegments,
                operatorForAdd: operatorForAdd
            })
            .then(res => {
                if (res.status === 200 && res.data.successLI && res.data.failedLI) {
                    setRequestDone(true);
                    setSuccessLI(res.data.successLI);
                    setFailedLI(res.data.failedLI);
                    if (res.data.err) {
                        notificationSystem.current.addNotification(
                            getNotification(
                                'error',
                                'Some accounts were not updated because of an error' + res.data.err
                            )
                        );
                    } else {
                        notificationSystem.current.addNotification(getNotification('success', 'Process done'));
                    }
                } else {
                    notificationSystem.current.addNotification(getNotification('error', res));
                }
            })
            .catch(error => {
                notificationSystem.current.addNotification(getNotification('error', error));
                console.log(error);
            })
            .finally(() => {
                setProcessingFlag(false);
            });
    };

    const cleanLine = (e, setFunction) => {
        let line = e.target.value;
        line = line
            .replace(/\s\s/g, ' ')
            .replace(/\s/g, ',')
            .replace(/[^0-9,]/g, '');
        while (line.includes(',,')) {
            line = line.replace(',,', ',');
        }
        if (line.startsWith(',')) {
            line = line.slice(1);
        }
        setFunction(line);
    };

    const renderAccountSelect = () => {
        return (
            <React.Fragment>
                <Spin size="large" spinning={processingFlag}>
                    <Row className="full-width" gutter={[20, 3]} type="flex" align="start">
                        <Col span={24}>
                            <Row
                                className="full-width"
                                gutter={[3, 3]}
                                type="flex"
                                align="start"
                                style={{ background: '#feffe6', padding: '15px' }}
                            >
                                <h7>List of account IDs need to be updated</h7>
                                <TextArea
                                    placeholder="Enter the list of accounts (IDs splitted with commas)"
                                    onChange={e => cleanLine(e, setAccounts)}
                                    value={accounts}
                                />
                            </Row>
                            <hr></hr>
                        </Col>
                        <Col span={24}>
                            <Row
                                className="full-width"
                                gutter={[3, 3]}
                                type="flex"
                                align="start"
                                style={{ background: '#e6fffb', padding: '15px' }}
                            >
                                <Col>
                                    <h7>List of segment IDs need to be ADDED</h7>
                                </Col>
                                <Col span={24}>
                                    {' '}
                                    Operator for segments to be added:
                                    <Radio.Group
                                        onChange={value => setOperatorForAdd(value.target.value)}
                                        defaultValue={operatorForAdd}
                                    >
                                        <Radio.Button value="or">OR</Radio.Button>
                                        <Radio.Button value="and">AND</Radio.Button>
                                    </Radio.Group>
                                </Col>
                                <Col span={24}>
                                    <TextArea
                                        placeholder="Enter the list of the segments need to be added (IDs splitted with commas)"
                                        onChange={e => cleanLine(e, setSegmentsToAdd)}
                                        value={segmentsToAdd}
                                    />
                                </Col>
                            </Row>
                            <hr></hr>
                        </Col>
                        <Col span={24}>
                            <Row
                                className="full-width"
                                gutter={[3, 3]}
                                type="flex"
                                align="start"
                                style={{ background: '#fff2e8', padding: '15px' }}
                            >
                                <Col>
                                    <h7>List of segment IDs need to be REMOVED</h7>
                                </Col>
                                <Col span={24}>
                                    <Checkbox onChange={e => setRemoveAllSegments(e.target.checked)}>
                                        Remove all segment groups
                                    </Checkbox>
                                </Col>

                                <Col span={24}>
                                    <TextArea
                                        disabled={removeAllSegments}
                                        placeholder="Enter the list of the segments need to be removed (IDs splitted with commas)"
                                        onChange={e => cleanLine(e, setSegmentsToRemove)}
                                        value={segmentsToRemove}
                                    />
                                </Col>
                            </Row>
                            <hr></hr>
                        </Col>
                        <Col span={24}>
                            <Row className="full-width" gutter={[3, 3]} type="flex" align="start">
                                <Button onClick={() => onStartToProcess(false)}>Process</Button>
                            </Row>
                        </Col>
                    </Row>
                    {requestDone ? (
                        <Row>
                            <Col>
                                <h7>Accounts updated</h7>
                            </Col>
                            <Col span={24}>
                                <TextArea disabled="true" value={successLI.join(',')} />
                            </Col>

                            <Col>
                                <h7>Accounts not updated</h7>
                            </Col>
                            <Col span={24}>
                                <TextArea disabled="true" value={failedLI.join(',')} />
                            </Col>
                        </Row>
                    ) : null}
                </Spin>
            </React.Fragment>
        );
    };

    return (
        <div className="blacklist-wrapper">
            <NotificationSystem ref={notificationSystem} style={style} />
            {renderAccountSelect()}
        </div>
    );
};

export default Segments;
