import React, { Component } from 'react';
import ILoveYou3000 from './ILoveYou300';
import { Typography } from 'antd';

const { Title } = Typography;

class Dashboard extends Component {
    createLegend(json) {
        var legend = [];
        for (var i = 0; i < json['names'].length; i++) {
            var type = 'fa fa-circle text-' + json['types'][i];
            legend.push(<i className={type} key={i} />);
            legend.push(' ');
            legend.push(json['names'][i]);
        }
        return legend;
    }
    render() {
        return (
            <div style={{ height: '90vh', backgroundImage: 'linear-gradient(to bottom, white, #343a40)' }}>
                <div
                    style={{
                        height: '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '100px'
                    }}
                >
                    <Title style={{ fontSize: '48px', color: '#AD3433', marginBottom: '10px' }}>
                        Welcome to CLX Internal
                    </Title>
                    <Title style={{ color: '#892c2b' }} level={5}>
                        Please use the menu to select a CLX App or Builder Tool
                    </Title>
                    <ILoveYou3000 />
                </div>
            </div>
        );
    }
}

export default Dashboard;
