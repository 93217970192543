import React, { Component } from 'react';
import { Panel, Tab, Tabs, Table } from 'react-bootstrap';
import axios from 'axios';
import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';
import cloneDeep from 'lodash/cloneDeep';
import DataTableBody from '../../components/Table/DataTableBody';
import DataTableChart from './DataTableChart';
import DataTableHeader from '../../components/Table/DataTableHeader';
import moment from 'moment';
import FilterService from './services/reporting-filter.service';
import PlatformService from './services/platform.service';
import ReportingConstants from './constants/reporting.constants';
import TableConstants from '../../components/Table/constants/table.constants';
import isEqual from 'lodash/isEqual';

class DataTableRowDetail extends Component {
    constructor() {
        super();
        this.state = {
            fieldMapHack: {},
            reportingDataTableHeaders: [],
            reportingData: [],
            platformAccounts: [],
            filterOptions: {
                account: '',
                dateRange: {
                    startDate: moment().startOf('month').format(ReportingConstants.DATE_STRING_FORMAT),
                    endDate: moment().endOf('month').format(ReportingConstants.DATE_STRING_FORMAT)
                },
                aggregateData: true,
                page: TableConstants.INITIAL_PAGE_INDEX,
                orderByAscending: true
            },
            selectedCampaignListKeys: {}
        };

        this.platformAccountIdsByTab = {};
        this.cachedCampaignsDataByPlatformAccountId = {};
    }

    getPlatformsForAccountData() {
        let url = ReportingConstants.GET_PLATFORM_ACCOUNTS_DATA_FOR_BAR;

        return axios
            .get(`${API_BASE_URL}${url}`, {
                params: JSON.stringify(this.state.filterOptions),
                headers: authHeader()
            })
            .then(response => {
                this.setState({
                    platformAccounts: response.data
                });
            })
            .catch(error => {
                console.error('Error downloading reporting data!', error);
            });
    }

    setCampaignsState(campaignData) {
        let recordCount = this.state.reportingDataRecordCount;

        if (campaignData[ReportingConstants.DATA_TYPE_INDEX.DataRecordCount]) {
            recordCount = campaignData[ReportingConstants.DATA_TYPE_INDEX.DataRecordCount];
        }

        this.setState({
            reportingDataTableHeaders: campaignData[ReportingConstants.DATA_TYPE_INDEX.DataTableHeaders],
            reportingData: campaignData[ReportingConstants.DATA_TYPE_INDEX.Data],
            reportingDataRecordCount: recordCount,
            graphableFields: campaignData[ReportingConstants.DATA_TYPE_INDEX.GraphableFields],
            fieldsMap: campaignData[ReportingConstants.DATA_TYPE_INDEX.FieldsMap],
            loadingData: false
        });
    }

    getCampaignsData(platformAccountId) {
        if (this.cachedCampaignsDataByPlatformAccountId[platformAccountId]) {
            this.setCampaignsState(this.cachedCampaignsDataByPlatformAccountId[platformAccountId]);
            return;
        }

        let url = ReportingConstants.GET_REPORTING_DATA_FOR_BAR;
        this.setState({
            loadingCampaignDataForAccountId: platformAccountId,
            loadingData: true
        });

        return axios
            .get(`${API_BASE_URL}${url}`, {
                params: JSON.stringify(this.state.filterOptions),
                headers: authHeader()
            })
            .then(response => {
                this.cachedCampaignsDataByPlatformAccountId[platformAccountId] = response.data;
                this.setCampaignsState(response.data);
            })
            .catch(error => {
                console.error('Error downloading reporting data!', error);
            });
    }

    isOpen() {
        return this.props.open[this.props.rowIndex] && this.props.open[this.props.rowIndex].isOpen;
    }

    onDetailViewShown() {
        this.setState({
            showChart: true
        });

        this.updateStateFromProps(() => {
            this.getPlatformsForAccountData().then(() => {
                this.onTabSelect(1);
            });
        });
    }

    onDetailViewHidden() {
        this.setState({
            showChart: false
        });
    }

    onTabSelect = tabKey => {
        const filterOptions = this.state.filterOptions;
        filterOptions.platformAccountId = this.platformAccountIdsByTab[tabKey];

        this.setState(
            {
                key: tabKey,
                filterOptions
            },
            () => {
                if (this.campaignTabsByTabKey[tabKey]) {
                    this.getCampaignsData(filterOptions.platformAccountId);
                }
            }
        );
    };

    updateStateFromProps(callback) {
        const graphableFilterOptions = cloneDeep(this.props.filterOptions);
        const filterOptions = cloneDeep(this.props.filterOptions);
        filterOptions.accountId = this.props.data.id;
        filterOptions.getCampaigns = true;
        filterOptions.page = 1;

        const state = this.state;
        state.filterOptions = filterOptions;
        state.graphableFilterOptions = graphableFilterOptions;

        if (this.props.loadingData) {
            state.key = 1;
            state.showTabCampaignChart = false;
            state.selectedCampaignListKeys = {};
        }

        this.setState(state, callback);
    }

    componentDidUpdate(prevProps) {
        if (isEqual(this.props, prevProps)) {
            return;
        }

        this.updateStateFromProps();
    }

    isFieldHidden(fieldsMap, index, showingFields) {
        return false;
    }

    getPlatformAccountTabs() {
        if (!this.isOpen()) {
            return [<React.Fragment key={this.props.data.id} />];
        }

        const platformAccountTabs = [];
        this.campaignTabsByTabKey = {};
        let tabKey = 2;

        this.state.platformAccounts.forEach(platformAccount => {
            this.platformAccountIdsByTab[tabKey] = platformAccount.id;

            const keyName = `${platformAccount.platform} Insights`;
            platformAccountTabs.push(
                <Tab key={`${keyName}_${tabKey}`} eventKey={tabKey} title={keyName}>
                    <DataTableChart
                        filterOptions={this.state.filterOptions}
                        data={this.props.data}
                        showChart={this.state.showChart}
                        graphableFields={this.props.graphableFields}
                        isActiveChart={this.state.key === tabKey}
                        tableHeaders={this.props.tableHeaders}
                    />
                </Tab>
            );

            tabKey++;

            this.platformAccountIdsByTab[tabKey] = platformAccount.id;
            this.campaignTabsByTabKey[tabKey] = true;
            platformAccountTabs.push(
                <Tab key={`${keyName}_${tabKey}`} eventKey={tabKey} title={`${platformAccount.platform} Campaigns`}>
                    <span>Platform Account: </span>
                    {PlatformService.getPlatformDeepLink(
                        platformAccount,
                        platformAccount.name,
                        platformAccount.platform
                    )}
                    <Table striped hover>
                        <DataTableHeader
                            fieldsMap={this.state.fieldMapHack}
                            isFieldHidden={this.isFieldHidden}
                            tableHeaders={this.state.reportingDataTableHeaders}
                            recordCount={this.state.reportingDataRecordCount}
                            orderBy={this.state.filterOptions.orderBy}
                            orderByAscending={this.state.filterOptions.orderByAscending}
                            onFilterSortBy={columnName => {
                                FilterService.sortBy(columnName, this, 'getCampaignsData');
                            }}
                        />
                        <DataTableBody
                            className={'campaigns-insights'}
                            data={this.state.reportingData}
                            filterOptions={this.state.filterOptions}
                            graphableFields={this.state.graphableFields}
                            fieldsMap={this.state.fieldsMap}
                            showDetailView={false}
                            onRowClicked={this.onCampaignSelected}
                            getCellContent={(rowData, fieldsMap, fieldValue, index) => {
                                return this.props.getCellContent(
                                    rowData,
                                    fieldsMap,
                                    fieldValue,
                                    index,
                                    platformAccount.platform
                                );
                            }}
                            isFieldHidden={this.props.isFieldHidden}
                            loadingData={this.state.loadingData}
                        />
                    </Table>
                </Tab>
            );

            tabKey++;

            this.platformAccountIdsByTab[tabKey] = platformAccount.id;

            const selectedCampaign = this.state.selectedCampaignListKeys[tabKey];
            if (selectedCampaign) {
                platformAccountTabs.push(
                    <Tab
                        key={`${keyName}_${tabKey}`}
                        eventKey={tabKey}
                        onEntering={() => {
                            this.setState({ showChartCampaign: true });
                        }}
                        title={`${platformAccount.platform} - ${selectedCampaign.name}`}
                    >
                        <DataTableChart
                            filterOptions={this.state.filterOptions}
                            data={selectedCampaign}
                            showChart={this.state.showTabCampaignChart}
                            graphableFields={this.props.graphableFields}
                            isActiveChart={this.state.key === tabKey}
                            tableHeaders={this.props.tableHeaders}
                        />
                    </Tab>
                );

                tabKey++;
            }
        });

        return platformAccountTabs;
    }

    onCampaignSelected = rowData => {
        const campaignChartTabKey = this.state.key + 1;

        const selectedCampaignListKeys = this.state.selectedCampaignListKeys;
        selectedCampaignListKeys[campaignChartTabKey] = rowData;

        this.setState({
            showTabCampaignChart: true,
            selectedCampaignListKeys,
            // Automatically open the chart view for the selected campaign.
            key: campaignChartTabKey
        });
    };

    render() {
        if (!this.props.showDetailView) {
            return null;
        }

        return (
            <tr className={'data-table-row-detail'}>
                <td colSpan="9">
                    <Panel
                        id="collapsible-panel-example-1"
                        expanded={this.isOpen()}
                        onToggle={() => {
                            // This method is required to be here by the Bootstrap library.
                        }}
                    >
                        <Panel.Collapse
                            onEntered={() => {
                                this.onDetailViewShown(this.props.rowIndex);
                            }}
                            onExited={() => {
                                this.onDetailViewHidden(this.props.rowIndex);
                            }}
                        >
                            <Panel.Body>
                                <Tabs
                                    defaultActiveKey={1}
                                    activeKey={this.state.key}
                                    onSelect={this.onTabSelect}
                                    id="uncontrolled-tab-example"
                                >
                                    <Tab
                                        eventKey={1}
                                        onEntering={() => {
                                            this.setState({ showChart: true });
                                        }}
                                        title="Account Insights"
                                    >
                                        <DataTableChart
                                            filterOptions={this.state.filterOptions}
                                            data={this.props.data}
                                            showChart={this.state.showChart}
                                            graphableFields={this.props.graphableFields}
                                            isActiveChart={this.state.key === 1}
                                            tableHeaders={this.props.tableHeaders}
                                        />
                                    </Tab>
                                    {this.getPlatformAccountTabs().map((prop, key) => {
                                        return prop;
                                    })}
                                </Tabs>
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                </td>
            </tr>
        );
    }
}

export default DataTableRowDetail;
