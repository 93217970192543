import React, { Component } from 'react';
import ReportingConstants from './constants/reporting.constants';
import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';
import axios from 'axios';

class DataTableSummaryRow extends Component {
    constructor() {
        super();

        this.state = {
            summaryData: []
        };
    }

    getData() {
        if (this.loadingData) {
            return;
        }

        let url = ReportingConstants.GET_SUMMARY_DATA_FOR_BAR;
        this.loadingData = true;

        return axios
            .get(`${API_BASE_URL}${url}`, {
                params: JSON.stringify(this.props.filterOptions),
                headers: authHeader()
            })
            .then(response => {
                // The summary row component in the campaign tabs gets unmounted by design when
                // filters change.  We will likely be in the middle of a network request when that
                // happens.  If that is the case then just ignore the response.
                if (!this.componentIsMounted) {
                    return;
                }

                this.loadingData = false;
                this.setState({
                    summaryData: response.data
                });
            })
            .catch(error => {
                console.error('Error downloading summary data!', error);
            });
    }

    componentDidMount() {
        this.componentIsMounted = true;
    }

    componentDidUpdate(prevProps) {
        if (!this.props.loadingData) {
            return;
        }

        this.getData();
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    render() {
        if (!this.state.summaryData.length || !this.props.tableHeaders || !this.props.tableHeaders.length) {
            return null;
        }

        let mappedColumnNames = {};
        this.props.tableHeaders.forEach(prod => {
            mappedColumnNames[prod.field] = prod.display;
        });

        return this.state.summaryData.map((field, key) => {
            let component = this;
            return (
                <tr key={key}>
                    {Object.keys(field).map((prop, key) => {
                        return component.props.isFieldHidden(
                            component.props.fieldsMap,
                            component.props.fieldsMap[mappedColumnNames[prop]],
                            component.props.showingFields
                        )
                            ? <React.Fragment key={key} />
                            : <td key={key}>
                                  <strong>
                                      {field[prop]}
                                  </strong>
                              </td>;
                    })}
                </tr>
            );
        });
    }
}

export default DataTableSummaryRow;
