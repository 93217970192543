import React, { Fragment } from 'react';
import { HeartFilled } from '@ant-design/icons';
import { Typography } from 'antd';

const { Text } = Typography;
const { Title } = Typography;

const ILoveYou3000 = () => {
    return (
        <Fragment>
            <a href="https://conversionlogix.com/ily3000/" target="_blank">
                <div
                    style={{
                        marginTop: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '150px',
                        width: '150px',
                        borderRadius: '75px',
                        backgroundColor: '#469bd7',
                        textAlign: 'center',
                        color: 'white'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '148px',
                            width: '148px',
                            borderRadius: '74px',
                            backgroundColor: '#8cc0e1',
                            textAlign: 'center',
                            color: 'white'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '140px',
                                width: '140px',
                                borderRadius: '70px',
                                backgroundColor: '#469bd7',
                                textAlign: 'center',
                                color: 'white'
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '132px',
                                    width: '132px',
                                    borderRadius: '68px',
                                    backgroundColor: '#8cc0e1',
                                    textAlign: 'center',
                                    color: 'white'
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        zIndex: '10',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '120px',
                                        width: '120px',
                                        borderRadius: '60px',
                                        backgroundColor: '#469bd7',
                                        textAlign: 'center',
                                        color: 'white'
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: '32px',
                                                    fontWeight: '600',
                                                    color: 'white',
                                                    marginRight: '2px'
                                                }}
                                            >
                                                I
                                            </Text>
                                            <HeartFilled style={{ fontSize: '32px', color: '#c85649' }} />
                                            <Text
                                                style={{
                                                    fontSize: '32px',
                                                    fontWeight: '600',
                                                    color: 'white',
                                                    color: 'white',
                                                    marginLeft: '2px'
                                                }}
                                            >
                                                U
                                            </Text>
                                        </div>
                                        <Text
                                            style={{
                                                fontSize: '32px',
                                                fontWeight: '600',
                                                color: 'white',
                                                color: 'white'
                                            }}
                                        >
                                            3000
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            <Title style={{ color: '#fff' }} level={5}>
                Promise Delivered
            </Title>
        </Fragment>
    );
};

export default ILoveYou3000;
