import React, { Fragment, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Divider, Input, Collapse, Typography, Checkbox, Col, Row } from 'antd';
import { RES_DESC_CAMPAIGN_AD_GROUPS, STUDENT_DESC_CAMPAIGN_AD_GROUPS, DESCRIPTIVE_AD_COPY } from '../FormConstants';

const { Panel } = Collapse;
const { Text } = Typography;

function DescriptiveResAdGroups(props) {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const parsedAddress = props.parsedAddress;
    const [disabledGroup, setDisabledGroup] = useState([]);
    const [disabledGroupNatl, setDisabledGroupNatl] = useState([]);

    const generateDefaultCopy = values => {
        let name = values.name.replace('descriptive_', '');

        let copy = DESCRIPTIVE_AD_COPY[name];

        if (!copy) {
            return 'Apartments for Rent';
        }

        if (parsedAddress && parsedAddress.placeName) {
            // set value for headline 12
            if (values.name.includes('headline_12')) {
                copy = copy + ` ${parsedAddress.placeName}`;
            }

            //set value for headling 13
            if (values.name.includes('headline_13')) {
                copy = `${parsedAddress.placeName} ` + copy;
            }
        }

        if (copy.length > 30) {
            copy = copy.replace('Apartments', 'Apts');
        }

        return copy;
    };

    const generateLabel = value => {
        const labels = [];

        if (value === 'Descriptive') {
            labels.push(value + ' Luxury-Headline 12');
            labels.push(value + ' Luxury-Headline 13');
            labels.push(value + ' New-Headline 12');
            labels.push(value + ' New-Headline 13');
        } else {
            labels.push(value + '-Headline 12');
            labels.push(value + '-Headline 13');
        }

        return labels;
    };

    const handleChanged = checkbox => {
        if (checkbox.value === 'Luxury' && checkbox.checked) {
            setDisabledGroup(['Descriptive', 'New']);
        }
        if (checkbox.value === 'Luxury' && !checkbox.checked) {
            setDisabledGroup([]);
        }
        if (checkbox.value === 'New' && checkbox.checked) {
            setDisabledGroup(['Descriptive', 'Luxury']);
        }
        if (checkbox.value === 'New' && !checkbox.checked) {
            setDisabledGroup([]);
        }
        if (checkbox.value === 'Descriptive' && checkbox.checked) {
            setDisabledGroup(['Luxury', 'New']);
        }
        if (checkbox.value === 'Descriptive' && !checkbox.checked) {
            setDisabledGroup([]);
        }
        if (checkbox.value === 'NonBrand Natl' && checkbox.checked) {
            setDisabledGroupNatl(['NonBrand Natl State']);
        }
        if (checkbox.value === 'NonBrand Natl' && !checkbox.checked) {
            setDisabledGroupNatl([]);
        }
        if (checkbox.value === 'NonBrand Natl State' && checkbox.checked) {
            setDisabledGroupNatl(['NonBrand Natl']);
        }
        if (checkbox.value === 'NonBrand Natl State' && !checkbox.checked) {
            setDisabledGroupNatl([]);
        }
    };

    const checkBoxGroup = () => {
        return (
            <Checkbox.Group>
                <Row>
                    {RES_DESC_CAMPAIGN_AD_GROUPS.map(item => (
                        <Col key={item} span={8}>
                            <Checkbox
                                disabled={
                                    item.includes('Natl')
                                        ? disabledGroupNatl.includes(item)
                                        : disabledGroup.includes(item)
                                }
                                value={item}
                                onChange={e => {
                                    handleChanged(e.target);
                                }}
                            >
                                {item}
                            </Checkbox>
                        </Col>
                    ))}
                </Row>
            </Checkbox.Group>
        );
    };

    return (
        <Fragment>
            <Form.Item>
                <Text>Select a Campaign/Ad Group...</Text>
                {getFieldDecorator('descriptive_ad_groups')(
                    <Checkbox.Group>
                        <Row>
                            {RES_DESC_CAMPAIGN_AD_GROUPS.map(item => (
                                <Col key={item} span={8}>
                                    <Checkbox
                                        disabled={
                                            item.includes('Natl')
                                                ? disabledGroupNatl.includes(item)
                                                : disabledGroup.includes(item)
                                        }
                                        value={item}
                                        onChange={e => {
                                            handleChanged(e.target);
                                        }}
                                    >
                                        {item}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                )}
            </Form.Item>

            {getFieldValue('descriptive_ad_groups') && getFieldValue('descriptive_ad_groups').length > 0 && (
                <React.Fragment>
                    {RES_DESC_CAMPAIGN_AD_GROUPS.filter(item => {
                        return getFieldValue('descriptive_ad_groups').includes(item);
                    })
                        .reduce((acc, cur) => {
                            const labels = generateLabel(cur);
                            acc.push(...labels);
                            return acc;
                        }, [])
                        .map(item => {
                            return {
                                label: item.split('|')[0],
                                name: item.toLowerCase().replace(/[\W_]/g, '_')
                            };
                        })
                        .map(item => (
                            <Form.Item label={item.label} key={item.name} extra={item.extra}>
                                {getFieldDecorator(`${item.name}`, {
                                    initialValue: generateDefaultCopy(item),
                                    rules: [
                                        {
                                            max: 30
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder={item.placeholder}
                                        suffix={`${
                                            getFieldValue(item.name) ? getFieldValue(item.name).length : '0'
                                        }/30`}
                                    />
                                )}
                            </Form.Item>
                        ))}
                </React.Fragment>
            )}
        </Fragment>
    );
}

export default DescriptiveResAdGroups;
