import React, { Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DESCRIPTION_FIELDS } from '../FormConstants';
import { Divider, Input, Collapse, Typography } from 'antd';

const { Panel } = Collapse;
const { Text } = Typography;

function GadsDescriptions(props) {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const DescriptionItems = fields => {
        return fields.map((item, i) => {
            return (
                <Form.Item label={item.label} key={item.name} extra={item.extra}>
                    {getFieldDecorator(`${item.name}`, {
                        rules: [
                            {
                                required: true
                            },
                            {
                                max: 90
                            }
                        ]
                    })(
                        <Input
                            placeholder={item.placeholder}
                            suffix={`${getFieldValue(item.name) ? getFieldValue(item.name).length : '0'}/90`}
                        />
                    )}
                </Form.Item>
            );
        });
    };

    return (
        <Fragment>
            {DescriptionItems(DESCRIPTION_FIELDS)}
            <Divider />
        </Fragment>
    );
}

export default GadsDescriptions;
