import moment from 'moment';
import ReportingConstants from '../constants/reporting.constants';
import TableConstants from '../../../components/Table/constants/table.constants';

class ReportingFilterService {
    static updateFilter(strategy, instance, callback) {
        const filterOptions = instance.state.filterOptions;
        strategy(filterOptions);
        instance.setState(
            {
                filterOptions
            },
            callback ? instance[callback] : instance.getData
        );
    }

    static filterByAccount(accountId, instance) {
        ReportingFilterService.updateFilter(options => {
            options.accountId = accountId;
            options.page = TableConstants.INITIAL_PAGE_INDEX;
        }, instance);
    }

    static filterByDate = (dateType, date, instance) => {
        ReportingFilterService.updateFilter(options => {
            options.dateRange[dateType] = moment(date).format(ReportingConstants.DATE_STRING_FORMAT);
            options.page = TableConstants.INITIAL_PAGE_INDEX;
        }, instance);
    };

    static filterByPlatform = (platforms, instance) => {
        ReportingFilterService.updateFilter(options => {
            options.platforms = platforms;
            options.page = TableConstants.INITIAL_PAGE_INDEX;
        }, instance);
    };

    static toggleAggregateData = (aggregateData, instance) => {
        ReportingFilterService.updateFilter(options => {
            options.aggregateData = !aggregateData;
            options.page = TableConstants.INITIAL_PAGE_INDEX;
            options.orderBy = null;
        }, instance);
    };

    static toggleAggregatePlatforms = (aggregateData, instance) => {
        ReportingFilterService.updateFilter(options => {
            options.aggregatePlatforms = aggregateData;
            options.page = TableConstants.INITIAL_PAGE_INDEX;
            options.orderBy = null;
        }, instance);
    };

    static toggleShowByCampaign = (showByCampaign, instance) => {
        ReportingFilterService.updateFilter(options => {
            options.showByCampaign = showByCampaign;
            options.getCampaigns = showByCampaign;
            options.aggregatePlatforms = !showByCampaign;
            options.page = TableConstants.INITIAL_PAGE_INDEX;
            options.orderBy = null;
        }, instance);
    };

    static sortBy = (columnName, instance, callback) => {
        ReportingFilterService.updateFilter(
            options => {
                options.orderBy = columnName;
                options.orderByAscending = !options.orderByAscending;
                options.page = TableConstants.INITIAL_PAGE_INDEX;
            },
            instance,
            callback
        );
    };
}

export default ReportingFilterService;
