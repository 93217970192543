import React, { Component } from 'react';
import axios from 'axios';
import copy from 'copy-to-clipboard';

import { API_BASE_URL } from '../../variables/Variables';
import { LinkOutlined } from '@ant-design/icons';
import { Col, Select, Spin, List, Pagination } from 'antd';
import { FORM_TEMPLATES_ENDPOINT } from './fieldsTemplates.jsx';

const { Option } = Select;

export default class FormTemplateSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            template: this.props.templateId,
            templatesLoading: false,
            page: 1,
            totalPages: 1,
            animatedLinks: []
        };
        this.getTemplatesList = this.getTemplatesList.bind(this);
        this.getTemplateData = this.getTemplateData.bind(this);
    }

    componentDidMount() {
        this.getTemplatesList();
    }

    getTemplatesList() {
        this.setState({ templatesLoading: true });
        const onError = this.props.onError;
        axios
            .get(`${API_BASE_URL}${FORM_TEMPLATES_ENDPOINT}`)
            .then(res => {
                return this.props.responceProcess(res);
            })
            .then(res => {
                if (!res.length) {
                    throw Error('No Templates found');
                }
                this.setState({
                    templatesLoading: false,
                    templates: res,
                    totalPages: res.length
                });
            })
            .catch(error => {
                this.setState(
                    {
                        templatesLoading: false
                    },
                    onError(error)
                );
                console.log(error);
            });
    }

    getTemplateData(id) {
        this.setState({ templatesLoading: true });
        axios
            .get(`${API_BASE_URL}${FORM_TEMPLATES_ENDPOINT}?id=${id}`)
            .then(res => {
                return this.props.responceProcess(res);
            })
            .then(res => {
                this.props.onTemplateChange(res);
            })
            .catch(error => {
                this.setState(
                    {
                        templatesLoading: false
                    },
                    this.props.onError(error)
                );
            });
    }

    saveTemplateData() {
        this.setState({ templatesLoading: true });
        axios
            .get(`${API_BASE_URL}${FORM_TEMPLATES_ENDPOINT}`)
            .then(res => {
                if (!res.data.length) {
                    throw Error('No Blacklists found');
                }
                this.setState({
                    templatesLoading: false
                });
            })
            .catch(error => {
                this.setState(
                    {
                        templatesLoading: false
                    },
                    this.props.onError(error)
                );
            });
    }
    onShowSizeChange() {
        /*  TODO  change page size if needed*/
    }

    handleLinkClick(name, alias) {
        copy(`${window.location.host}/form/${alias}`);
        let { animatedLinks } = this.state;
        animatedLinks.push(name);
        this.setState({ animatedLinks });
        setTimeout(() => {
            let { animatedLinks } = this.state;
            delete animatedLinks[animatedLinks.indexOf(name)];
            this.setState({ animatedLinks });
        }, 2000);
    }

    render() {
        const { templates, templatesLoading, template } = this.state;

        const templatesThisPage = templates.slice((this.state.page - 1) * 10, this.state.page * 10);
        return (
            <Col span={this.props.span} offset={this.props.offset}>
                <Spin size="large" spinning={templatesLoading}>
                    {this.props.listType === 'select' ? (
                        <Select
                            span={20}
                            key="templateelection"
                            disabled={this.props.disabled}
                            label="Templates"
                            defaultValue={template ? template : ''}
                            onChange={this.props.onTemplateChange}
                            placeholder="Select form...."
                        >
                            {templates.map(bl => {
                                return (
                                    <Option key={bl.id} value={bl.id}>
                                        {bl.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    ) : (
                        <React.Fragment>
                            <List itemLayout="vertical">
                                {templatesThisPage.map(bl => {
                                    return (
                                        <List.Item key={bl.id}>
                                            <a href={this.props.hrefPrefix + bl.id}>{bl.name}</a>
                                            <span> </span>
                                            {bl.alias ? (
                                                <LinkOutlined
                                                    className={this.state.animatedLinks.includes(bl.name) ? 'fade' : ''}
                                                    onClick={() => this.handleLinkClick(bl.name, bl.alias)} />
                                            ) : (
                                                ''
                                            )}
                                        </List.Item>
                                    );
                                })}
                            </List>
                            <Pagination
                                onChange={(page, pageSize) => {
                                    this.setState({ page });
                                }}
                                onShowSizeChange={this.onShowSizeChange}
                                defaultCurrent={this.state.page}
                                total={templates.length}
                            />
                        </React.Fragment>
                    )}
                </Spin>
            </Col>
        );
    }
}
