import { env } from './env';
export const CRM_TYPES = [
    {value: '', label: 'None' },
    {value: 'adf',label: 'ADF'},
    {value: 'eldermark',label: 'Eldermark'},            
    {value: 'entrata',label: 'Entrata'},       
    {value: 'hospice_md',label: 'Hospice MD'},                  
    {value: 'knock',label: 'Knock'},
    {value: 'realpage_lead2lease',label: 'Lead2Lease by RealPage'},
    {value: 'meet_elise',label: 'Meet Elise'},
    {value: 'realpage_onesite',label: 'OneSite by RealPage'},
    {value: 'rentcafe',label: 'RENT Cafe'},              
    {value: 'rent_manager',label: 'Rent Manager'},
    {value: 'sherpa',label: 'Sherpa'},
    {value: 'welcome_home',label: 'WelcomeHome'},
    {value: 'yardi',label: 'Yardi'},
    {value: 'yardi_soap',label: 'Yardi Senior Housing'},
    {value: 'yardi_sipp',label: 'Yardi SIPP'},
    {value: 'ygl',label: "You've got leads"},
    {value: 'glennis_solutions',label: 'Glennis Solutions'},
    {value: 'rent_dynamics',label: "Rent Dynamics"}
];
export const FORMS_SALES_REPRESENTATIVE = [
    { label: 'Audrey Liou', value: 'audreyl@clxmedia.com' },
    { label: 'Brandy Meyers', value: 'brandym@clxmedia.com' },
    { label: 'Cheryl Bonhag', value: 'cherylb@clxmedia.com' },    
    { label: 'Chris Conway', value: 'chrisc@clxmedia.com' },    
    { label: 'Christine Carrozza', value: 'christinec@clxmedia.com' },
    { label: 'Darcy Brady', value: 'darcyb@clxmedia.com' },
    { label: 'DeAnna Wilson', value: 'deannaw@clxmedia.com' },
    { label: 'Holly Rodriguez', value: 'hollyr@clxmedia.com' },
    { label: 'Ilda Bitri', value: 'ildab@clxmedia.com' },    
    { label: 'Jason Nieves', value: 'jasonn@clxmedia.com' },
    { label: 'Jeff Gronemeyer', value: 'jeffg@clxmedia.com' },
    { label: 'Jen Lovely', value: 'jenl@clxmedia.com' },
    { label: 'Jennifer Leeder', value: 'jenniferl@clxmedia.com' },
    { label: 'Jessica Clark', value: 'jessicac@clxmedia.com' }, 
    { label: 'Julia Hendrickson', value: 'juliah@clxmedia.com' },    
    { label: 'Kasie McCrosson', value: 'kasiem@clxmedia.com' },
    { label: 'Kat Callender', value: 'katc@clxmedia.com' },
    { label: 'Meagan Collins', value: 'meaganc@clxmedia.com' },
    { label: 'Michelle Weaver', value: 'michellew@clxmedia.com' },
    { label: 'Nick Mack', value: 'nickm@clxmedia.com' },
    { label: 'Richard Francois', value: 'richardf@clxmedia.com' },
    { label: 'Sabrina Carrasco', value: 'sabrinac@clxmedia.com' },
    { label: 'Whitney Gregoire', value: 'whitneyg@clxmedia.com' },
    { label: 'Other', value: 'darcyb@clxmedia.com' },
];
export const TOPICS_OPTIONS = [
    { label: 'Availability', value: 'Availability' },
    { label: 'Specials', value: 'Specials' },
    { label: 'Pet Policy', value: 'Pet Policy' },
    { label: 'Tours', value: 'Tours' },
    { label: 'Amenities', value: 'Amenities' },
    { label: 'Contact Me', value: 'Contact Me' },
    { label: 'Schedule Appointment', value: 'Schedule Appointment' },
    { label: 'Do I Qualify', value: 'Do I Qualify' },
    { label: 'Neighborhood', value: 'Neighborhood' },
    { label: 'Pricing', value: 'Pricing' },
    { label: 'Schedule Tour', value: 'Schedule Tour' },
    { label: 'Virtual Tour', value: 'Virtual Tour' },
    { label: 'View Floor Plans', value: 'View Floor Plans' },
    { label: 'Job Inquiry', value: 'Job Inquiry' },
    { label: 'Custom', value: 'Custom' },
]
if (env.REACT_APP_TEST_EMAIL_SALES_REP) {
    FORMS_SALES_REPRESENTATIVE.push({
        label: 'Tester (developer)',
        value: env.REACT_APP_TEST_EMAIL_SALES_REP
    });
}
