import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { Row, Col, Button, Input, Spin } from 'antd';

import axios from 'axios';
import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import { style, API_BASE_URL } from '../../variables/Variables';

import './index.css';
import BacklistSelector from './BlacklistSelector';
const ANX_INVENTORIES_ENDPOINT = 'anx/inventorylist';
const ANX_COMPANIES_ENDPOINT = 'anx/accounts';

export default class Blacklist extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleFormResponse = this.handleFormResponse.bind(this);
        this.state = {
            blacklist: false,
            accounts: [],
            accountSearchPattern: '',
            accountsLoading: false,
            campaigns: [],
            campaignsLoading: false,
            processingFlag: false
        };
        this.onResetAccount = this.onResetAccount.bind(this);
        this.onSearchAccount = this.onSearchAccount.bind(this);
        this.onSelectAccount = this.onSelectAccount.bind(this);
        this.onBlacklistChange = this.onBlacklistChange.bind(this);
    }

    componentDidMount() {
        this.setState({ accountsLoading: true }, this.refreshAccounts());
    }

    getNotification(level, text) {
        return {
            title: <span data-notify="icon" className={level === 'success' ? 'pe-7s-server' : 'pe-7s-shield'} />,
            message: <div dangerouslySetInnerHTML={{ __html: text }} />,
            level: level,
            position: 'tr',
            autoDismiss: 10
        };
    }

    refreshAccounts() {
        axios
            .get(`${API_BASE_URL}${ANX_COMPANIES_ENDPOINT}`)
            .then(res => {
                this.setState({
                    accountsLoading: false,
                    accounts: res.data
                });
            })
            .catch(error => {
                this.refs.notificationSystem.addNotification(this.getNotification('error', error));
                console.log(error);
            });
    }

    handleFormResponse(response) {
        this.setState({ response });
    }

    onResetAccount() {
        this.setState({ account: false });
    }

    onSelectAccount(account) {
        let { accounts } = this.state;
        let selectedAcc = accounts.filter(acc => {
            return account.name === acc.name;
        })[0];
        selectedAcc.selected = !selectedAcc.selected;
        this.setState({ accounts });
    }

    onSelectBulkAccount(all) {
        let { accounts, accountSearchPattern } = this.state;
        accounts.forEach(acc => {
            if (acc.name.toUpperCase().indexOf(accountSearchPattern.toUpperCase()) > -1) acc.selected = all;
        });

        this.setState({ accounts });
    }

    onSearchAccount(handle) {
        this.setState({ accountSearchPattern: handle.target.value });
    }

    onBlackListSubmit(addProcedure = true) {
        let { blacklist, accounts } = this.state;

        if (!blacklist) {
            return;
        }
        const acclist = accounts
            .filter(acc => {
                return acc.selected;
            })
            .map(acc => {
                return acc.id;
            });
        if (blacklist === '0') {
            this.refs.notificationSystem.addNotification(this.getNotification('error', 'No blacklist selected'));
            return;
        }
        if (!blacklist || !acclist.length) {
            this.refs.notificationSystem.addNotification(this.getNotification('error', 'No account selected'));
            return;
        }
        this.setState({ processingFlag: true });
        const procedure = addProcedure ? 'link' : 'unlink';
        axios
            .put(`${API_BASE_URL}${ANX_INVENTORIES_ENDPOINT}/${procedure}/${blacklist}`, acclist)
            .then(res => {
                if (res.data === 'Ok') {
                    this.refs.notificationSystem.addNotification(this.getNotification('success', 'Process done'));
                } else {
                    this.refs.notificationSystem.addNotification(this.getNotification('error', res));
                }
            })
            .catch(error => {
                this.setState({
                    blacklistsLoading: false
                });
                this.refs.notificationSystem.addNotification(this.getNotification('error', error));
                console.log(error);
            })
            .finally(() => {
                this.setState({ processingFlag: false });
            });
    }
    onBlacklistChange(blacklist) {
        this.setState({ blacklist });
    }
    renderAccountSelect() {
        const { accounts, accountsLoading, blacklist, accountSearchPattern } = this.state;
        const accountsList = accounts.filter((account, index) => {
            return account.name.toUpperCase().indexOf(accountSearchPattern.toUpperCase()) > -1;
        });
        return (
            <React.Fragment>
                <Spin size="large" spinning={accountsLoading}>
                    <Row className="background-volcano full-width" gutter={[3, 3]} type="flex" align="start">
                        <Col>
                            <Input placeholder="Enter filter mask" onChange={this.onSearchAccount} />
                        </Col>
                        <Col>
                            <Button
                                onClick={() => {
                                    this.onSelectBulkAccount(true);
                                }}
                            >
                                Select all
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                onClick={() => {
                                    this.onSelectBulkAccount(false);
                                }}
                            >
                                Select none
                            </Button>
                        </Col>
                    </Row>

                    <div className="account-container">
                        <Row gutter={[3, 3]} align="top" type="flex" justify="center">
                            {accountsList.map(account => {
                                return (
                                    <Col>
                                        <Button
                                            type={account.selected ? 'primary' : 'outline'}
                                            onClick={() => {
                                                this.onSelectAccount(account);
                                            }}
                                        >
                                            {account.name}
                                        </Button>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </Spin>
                <Row gutter={[3, 3]} className="background-volcano" type="flex" justify="start">
                    <Col span={8}>
                        <BacklistSelector blacklist={blacklist} onBlacklistChange={this.onBlacklistChange} />
                    </Col>
                    <Col>
                        <Spin size="large" spinning={this.state.processingFlag}>
                            <Button
                                onClick={() => {
                                    this.onBlackListSubmit(true);
                                }}
                            >
                                <LoginOutlined />
                                Add Blacklist to selected
                            </Button>

                            <Button
                                onClick={() => {
                                    this.onBlackListSubmit(false);
                                }}
                            >
                                <LogoutOutlined />
                                Remove Blacklist from selected
                            </Button>
                        </Spin>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className="blacklist-wrapper">
                <NotificationSystem ref="notificationSystem" style={style} />
                {this.renderAccountSelect()}
            </div>
        );
    }
}
