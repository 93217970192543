import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import NotificationSystem from 'react-notification-system';

import { notification } from 'antd';
import Card from '../../components/Card/Card.jsx';
import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';
import { style } from '../../variables/Variables.jsx';

import SearchCampaignBuilderForm from './SearchCampaignForm';

class SearchCampaigns extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    getClassNames() {
        let classes = 'content';

        if (this.state.loadingData) {
            classes += ' clx-loading';
        }

        return classes;
    }

    getData = () => {
        this.setState({
            loading: new Set(this.state.loading).add('data')
        });

        return axios
            .get(`${API_BASE_URL}podio/search/builds`, {
                headers: authHeader()
            })
            .then(response => {
                console.log(response);
                console.log(response.data);
                console.log(response.data.items);

                console.log(response.data.items.map(item => item.fields));

                this.setState({
                    data: response.data.items
                });
            })
            .catch(error => {
                console.error('Error downloading data!', error);
                notification.error({ message: 'Error downloading data!' });
            })
            .finally(() => {
                let loadingState = new Set(this.state.loading);
                loadingState.delete('data');

                this.setState({
                    loading: loadingState
                });
            });
    };

    render() {
        return (
            <div className={this.getClassNames()}>
                <NotificationSystem ref="notificationSystem" style={style} />
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Search Campaign Builder"
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <div>
                                        <SearchCampaignBuilderForm />
                                        {/* <Table
                                            columns={[
                                                {
                                                    title: 'Title',
                                                    dataIndex: 'title',
                                                    key: 'title'
                                                    // width: 120
                                                },
                                                // {
                                                //     title: 'Client',
                                                //     dataIndex: 'line_item_id',
                                                //     key: 'line_item_id'
                                                //     // width: 120
                                                // },
                                                // {
                                                //     title: 'Vertical',
                                                //     dataIndex: 'line_item_id',
                                                //     key: 'line_item_id'
                                                //     // width: 120
                                                // },
                                                // {
                                                //     title: 'Status',
                                                //     dataIndex: 'line_item_id',
                                                //     key: 'line_item_id'
                                                //     // width: 120
                                                // },
                                                {
                                                    title: 'Action',
                                                    key: 'action'
                                                }
                                            ]}
                                            dataSource={this.state.data}
                                            rowKey={'i'}
                                        /> */}
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default SearchCampaigns;
