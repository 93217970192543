// @ts-check

/**
 * Wraps the error of a promise, or replaces it, and returns it
 * Useful for using Axios, where you could have network failure and might want to return JSON anyways
 * @param {Promise} promiseToWrap
 * @param {any} onFailVal
 * @param {string} [onFailProp]
 * @param {function} [optChecker]
 */
export async function promiseCheckWrapper(promiseToWrap, onFailVal, onFailProp, optChecker) {
    const checker = typeof (optChecker) === 'function' ? optChecker : () => true;
    let success = true;
    let result;
    try {
        result = await promiseToWrap;
    }
    catch (error){
        success = false;
        if (typeof (onFailProp) === 'string' && typeof (onFailVal) === 'object'){
            onFailVal[onFailProp] = error;
        }
    }
    if (success === false || checker(result) === false) {
        return onFailVal;
    }
    return result;
}

// https://davidwalsh.name/query-string-javascript
export function getUrlParameter(name) {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : window.decodeURIComponent(results[1].replace(/\+/g, ' '));
}
