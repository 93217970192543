import React, { Component } from 'react';
import TableConstants from './constants/table.constants';
import DataTablePagingService from './services/table-paging.service';

class DataTableFooter extends Component {
    onPageClick = (event, direction) => {
        event.preventDefault();

        this.props.filterService &&
            this.props.filterService.updateFilter(options => {
                if (direction === TableConstants.PAGING_DIRECTION.Previous) {
                    if (options.page === TableConstants.INITIAL_PAGE_INDEX) {
                        return;
                    }

                    options.page -= 1;
                } else if (direction === TableConstants.PAGING_DIRECTION.Next) {
                    options.page += 1;
                }
            }, this.props.parentInstance);
    };

    onPageSizeSelect = event => {
        this.props.filterService &&
            this.props.filterService.updateFilter(options => {
                options.pageSize = event.target.value;
                options.page = TableConstants.INITIAL_PAGE_INDEX;
            }, this.props.parentInstance);
    };

    render() {
        return (
            <tfoot className={'line-tr'}>
                <tr>
                    {DataTablePagingService.getPreviousLink(this.props.filterOptions.page, this.onPageClick)}
                    {DataTablePagingService.getPageSizeSelector(this.onPageSizeSelect)}
                    {DataTablePagingService.getNextLink(
                        this.props.data.length,
                        this.props.filterOptions.pageSize,
                        this.onPageClick
                    )}
                </tr>
            </tfoot>
        );
    }
}

export default DataTableFooter;
