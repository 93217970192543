import React, { Component } from 'react';
import axios from 'axios';

import { responceProcess, onError, onSuccess } from '../../services/error.service';
import { API_BASE_URL } from '../../variables/Variables';
import { templateFields } from '../../constants/template-fields';
import { FORM_DATA_ENDPOINT, FORM_TEMPLATE_ENDPOINT } from './fieldsTemplates.jsx';
import CLXForm from './clx-form';
import { Col, Spin } from 'antd';

const DEFAULT_SPAN = 18;
const DEFAULT_OFFSET = 3;
const initFormTemplate = () => {
    return { fields: [] };
};

export default class FormSubmission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            template: initFormTemplate(),
            templateLoading: false,
            submitted: false,
            companiesSuggestion: []
        };
        this.templateid = this.props.match.params.templateid ? this.props.match.params.templateid : false;
        this.formalias = this.props.match.params.formalias ? this.props.match.params.formalias : false;
        //this.props.history.push(path);
        this.submitData = this.submitData.bind(this);
        this.onValidationError = this.onValidationError.bind(this);
        this.getTemplateData = this.getTemplateData.bind(this);
    }

    getCompanyData() {}

    componentDidMount() {
        this.getTemplateData(!this.templateid ? this.formalias : this.templateid);
    }

    getTemplateData(templateid) {
        if (templateid === '0') {
            return;
        }
        this.setState({ templatesLoading: true });
        axios
            .get(`${API_BASE_URL}${FORM_TEMPLATE_ENDPOINT}/${templateid}/`)
            .then(res => {
                return responceProcess(res);
            })
            .then(template => {
                this.templateid = template.id;
                this.setState({ template });
            })
            .catch(error => {
                this.setState(
                    {
                        templatesLoading: false
                    },
                    onError('Error getting form data')
                );
            });
    }

    // id = 0 - new record, otherwize - edit
    async submitData (data, templateid, id = 0, status = 'Entered') {

        const { afterSubmitText, afterSubmit } = this.state.template;
        const { go, goBack } = this.props.history;

        this.setState({ templatesLoading: true });

        return axios
            .post(`${API_BASE_URL}${FORM_DATA_ENDPOINT}`, { data, status, templateid, id })
            .then(res => {
                return responceProcess(res);
            })
            .then(() => {
                onSuccess('Form Submitted.' + afterSubmitText);

                this.setState(
                    {
                        templatesLoading: false,
                        submitted: true
                    },
                    () => {
                        switch (afterSubmit) {
                            case 'clean':
                                setTimeout(() => go(0), 10000);
                                break;
                            case 'previous':
                                setTimeout(goBack, 10000);
                                break;
                            default:
                                break;
                        }
                    }
                );
                return true;
            })
            .catch(error => {
                this.setState(
                    {
                        templatesLoading: false,
                        submitted: false
                    },
                    onError(String(error), 9)
                );

                return false;
            });
    }

    onValidationError() {
        onError('Check if form filled correctly, please.');
    }

    render() {
        let { template, templateLoading } = this.state;

        return (
            <React.Fragment>
                <Col
                    span={this.props.span ? this.props.span : DEFAULT_SPAN}
                    offset={this.props.offset ? this.props.offset : DEFAULT_OFFSET}
                >
                    <Spin size="large" spinning={templateLoading}>
                        {template.id ? (
                            <div>
                                <h4
                                    dangerouslySetInnerHTML={{
                                        __html: template.header ? template.header : template.name
                                    }}
                                />
                                <CLXForm
                                    autocomplete={true}
                                    meta={templateFields[template.id]}
                                    onSubmit={data => this.submitData(data, this.templateid)}
                                    onValidationError={this.onValidationError}
                                    loading={templateLoading}
                                    csTeam = {false}
                                />
                            </div>
                        ) : null}
                    </Spin>
                </Col>
            </React.Fragment>
        );
    }
}