import React, { Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';

function SnippetsAndCallouts(props) {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;

    return (
        <Fragment>
            <React.Fragment>
                <Form.Item style={{ display: 'flex' }} label="Structured Snippets">
                    {getFieldDecorator('amenities', {
                        rules: [{ required: true, message: 'Please add 4 to 6 amenities.', type: 'array' }]
                    })(
                        <Select
                            mode="tags"
                            tokenSeparators={[',', '\n']}
                            placeholder="Fitness Center, Shared Terrace, Panoramic Views, Personal Training..."
                        />
                    )}
                </Form.Item>

                <Form.Item label="Callouts">
                    {getFieldDecorator('callouts', {
                        rules: [{ required: true, message: 'Please add 4 to 6 callouts.', type: 'array' }]
                    })(
                        <Select
                            mode="tags"
                            tokenSeparators={[',']}
                            placeholder="24/7 Front Desk Coverage, Dry-Cleaning Services, Easy Waterfront Access, Pet-Friendly Amenities..."
                        />
                    )}
                </Form.Item>
            </React.Fragment>
        </Fragment>
    );
}

export default SnippetsAndCallouts;
