const FIELDS_TEMPLATES = []; //for future Form Builder

const FORM_TEMPLATE_ENDPOINT = 'form';
const FORM_DATA_ENDPOINT = 'formdata';
const FORM_TEMPLATES_ENDPOINT = 'forms'; //list of forms
const FORM_DATA_LIST_ENDPOINT = 'formdatalist';
const APPROVAL_URL = '/forms-approval/';
const APPROVAL_LIST = '/forms-approval-list/';
const DATA_PRESENTATION_FORMAT = 'MM-DD-YYYY HH:mm';
const AUTOCOMPLETE_ENDPOINT = 'autocomplete/';

const STATUS_STYLE = {
    Entered: 'green',
    Claimed: 'cyan',
    'Work Needed': 'blue',
    Complete: 'purple'
};

module.exports = {
    FIELDS_TEMPLATES,
    FORM_DATA_ENDPOINT,
    FORM_TEMPLATE_ENDPOINT,
    FORM_TEMPLATES_ENDPOINT,
    FORM_DATA_LIST_ENDPOINT,
    APPROVAL_LIST,
    APPROVAL_URL,
    STATUS_STYLE,
    DATA_PRESENTATION_FORMAT,
    AUTOCOMPLETE_ENDPOINT
};
