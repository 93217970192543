module.exports = {
    GET_GRAPHABLE_DATA_FOR_BAR: 'getgraphabledataforbar',
    GET_REPORTING_DATA_FOR_BAR: 'getreportingdataforbar',
    GET_PLATFORM_ACCOUNTS_DATA_FOR_BAR: 'getplatformaccountsdataforbar',
    GET_SUMMARY_DATA_FOR_BAR: 'getsummarydataforbar',
    GET_CSV: 'getexportingfilecsv',
    DATE_STRING_FORMAT: 'YYYY-MM-DD',
    DATA_TYPE_INDEX: {
        DataTableHeaders: 0,
        Data: 1,
        GraphableFields: 2,
        FieldsMap: 3,
        DataRecordCount: 4
    }
};
